import Token from './Token';

class TokenJWT extends Token {
  token: string = '';
  refreshToken: string = '';
  expiresIn: number = 1000 * 60 * 5;
  tokenType: string = 'Bearer';
  isLoading: boolean = false;
  expiresOn: Date = new Date(0);

  constructor(
    token: string = '',
    refreshToken: string = '',
    expiresIn: number = 1000 * 60 * 5,
    tokenType: string = 'Bearer',
    isLoading: boolean = false
  ) {
    super();
    const currentTime = new Date().getTime();

    this.token = token;
    this.refreshToken = refreshToken;
    this.expiresIn = expiresIn;
    this.tokenType = tokenType;
    this.isLoading = isLoading;
    // new expires on is current time + (supplied expiresIn or 5 min) - 30 seconds
    this.expiresOn = new Date(currentTime + (this.expiresIn - 30000));
  }
}

export default TokenJWT;
