import React from 'react';
import { Box, TextHeading } from '@ally/metronome-ui';
import Login from '../../organisms/Login';
import DefaultTemplate from '../../templates/DefaultTemplate';

const LoginPage: React.FC<IProps> = (props) => {
  return (
    <DefaultTemplate footer={<></>}>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <TextHeading size='xs'>
          Do not use this login for QA, it does not provide a real user
        </TextHeading>
        <Box
          paddingTop='50px'
          width={['100%', '100%', 1 / 2, 1 / 2, 1 / 3]}
          maxWidth={['100%', '100%', '100%', '380px']}
        >
          <Login />
        </Box>
      </Box>
    </DefaultTemplate>
  );
};

interface IProps {}

export default LoginPage;
