import { MuiIconMap, MuiIconMetadata } from '@ally/metronome-icons/dist/cjs';

const muiProfile16: MuiIconMetadata = {
  paths: [
    {
      path:
        'M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,9.77706308 2.77255645,11.3736859 4.0001315,12.4723307 L4,11.637486 C4,10.5329165 4.8954305,9.63748605 6,9.63748605 L10,9.63748605 C11.1045695,9.63748605 12,10.5329165 12,11.637486 L12.0008689,12.4714352 C13.2278632,11.3728184 14,9.77658031 14,8 C14,4.6862915 11.3137085,2 8,2 Z',
      fill: '#0071C4', // Default fill
    },
    {
      path:
        'M5.71428571, 6.83539109a2.28571429, 2.24167597 0 1, 0 4.57142858, 0a2.28571429, 2.24167597 0 1, 0 -4.57142858, 0',
      fill: '#0071C4', // Default fill
    },
  ],
  title: 'Profile',
  width: 16,
  height: 16,
};

const muiProfile24: MuiIconMetadata = {
  paths: [
    {
      path:
        'M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,14.1531319 3.75609185,16.1296691 5.0172509,17.678587 C5.17770225,16.172584 6.45191529,15 8,15 L16,15 C17.5480847,15 18.8222977,16.172584 18.9829256,17.6780386 C20.2439081,16.1296691 21,14.1531319 21,12 C21,7.02943725 16.9705627,3 12,3 Z',
      fill: '#0071C4', // Default fill
    },
    {
      path: 'M8, 10a4, 4 0 1, 0 8, 0a4, 4 0 1, 0 -8, 0',
      fill: '#0071C4', // Default fill
    },
  ],
  title: 'Profile',
  width: 24,
  height: 24,
};

const muiProfile: MuiIconMap = {
  base: muiProfile16,
  smIcon: muiProfile16,
  lgIcon: muiProfile24,
};

export { muiProfile };
