import styled from 'styled-components';
import { Box, TextBody, Space } from '@ally/metronome-ui';
import { useAccountNumberMask, useWealthController } from '../../../hooks';
import { Account } from '../../../models/index';
import { Currency } from '../../atoms';
import { RouteValue } from '../../../types';
import { allyTmEvent, muiAllyTheme } from '../../../utils';
import { Link } from 'react-router-dom';

// TODO: need to replace with Meteronom Link MWT-359 after the bug fix for params and query search
const StyledLink = styled(Link)`
  white-space: pre-wrap;
  text-decoration: none;
  font-weight: bold;
  color: ${muiAllyTheme.colors.bluesuedeshoes};
  font-size: 1.0666rem;
`;

interface ItemProps {
  account: Account;
  gainLoss: number;
}

const AccountPerformance: React.FC<ItemProps> = (props) => {
  const { maskedAccountNumber } = useAccountNumberMask(
    props.account.number,
    false
  );

  return (
    <Box>
      <Box
        display='flex'
        flexDirection={['row', 'row', 'row', 'column', 'row']}
        justifyContent='space-between'
      >
        <Box
          display='flex'
          flexDirection={['column', 'column', 'row', 'row', 'column']}
          flexShrink={1}
          alignItems={['unset', 'unset', 'unset', 'center', 'unset']}
          flexWrap='wrap'
        >
          <StyledLink
            to={
              `/account-details/${props.account.id}?noLookup=true` as RouteValue
            }
            onClick={() => {
              allyTmEvent('clickEvent', {
                tagName: 'button',
                allytmln: `detailsWealthAccount`,
                href: '',
              });
            }}
          >
            {props.account.name}
          </StyledLink>
          <Space marginTop={[0, 0, '2px', 0]}>
            <TextBody size='md' tag='p' data-private>
              {maskedAccountNumber}
            </TextBody>
          </Space>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          alignItems={[
            'flex-end',
            'flex-end',
            'flex-end',
            'flex-start',
            'flex-end',
          ]}
          flexShrink={0}
        >
          <TextBody size='md' tag='p'>
            <Currency value={props.account.balance} />
          </TextBody>
          <TextBody size='sm' tag='p'>
            <Currency isSigned isColorCoded value={props.gainLoss} />
          </TextBody>
        </Box>
      </Box>
    </Box>
  );
};

const StyledBox = styled(Box)`
  & > :not(:last-child) {
    border-bottom: 1px solid ${muiAllyTheme.colors['grey-80']};
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
`;

interface IProps {}

const AccountsPerformanceList: React.FC<IProps> = (props) => {
  const { userAccounts, wealthOverview } = useWealthController();

  return (
    <StyledBox borderColor='grey-80'>
      {userAccounts.accounts.map((account: Account) => {
        return (
          <AccountPerformance
            account={account}
            gainLoss={
              wealthOverview.wealthAccounts.find(
                (x) => x.accountId === account.id
              )?.totalGainLoss || 0
            }
            key={account.id}
          />
        );
      })}
    </StyledBox>
  );
};

export default AccountsPerformanceList;
