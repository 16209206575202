import { useState } from 'react';
import { ValidationTypes } from '../../types';

interface ErrorMessages {
  min?: string;
  max?: string;
  pattern?: string;
  required?: string;
}

interface ValidationRules {
  [key: string]: {
    messages: ErrorMessages;
    pattern: RegExp;
    max?: number;
    min?: number;
  };
}

const validationTypes: ValidationRules = {
  phoneNumber: {
    messages: {
      pattern:
        'Try entering the phone number again in this format NNN-NNN-NNNN.',
      required: 'Enter your phone number to continue.',
    },
    pattern: /^\d{3}-\d{3}-\d{4}$/,
  },
  yourEmail: {
    max: 55,
    messages: {
      max: 'Email Address max is 55.',
      pattern: 'Email Address is invalid.',
      required: 'Enter your email to continue.',
    },
    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  //We need a better way of setting custom error messages for specific fields so we don't have to duplicate them like this
  email: {
    max: 55,
    messages: {
      max: 'Email Address max is 55.',
      pattern: 'Email Address is invalid.',
      required: 'Try entering the email again.',
    },
    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
};

const useInputWithValidation = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');

  const phoneNumberMasking = (value: string, evt: any) => {
    if (
      evt &&
      evt.nativeEvent &&
      evt.nativeEvent.inputType === 'deleteContentBackward'
    ) {
      return value;
    }
    const replaceChars = ['(', ')', ' '];
    value = replaceChars.reduce((prev, replace) => {
      return prev.replaceAll(replace, '');
    }, value);
    const maskedInput = value.split('');
    if (maskedInput.length > 2) {
      if (maskedInput[3] !== '-') {
        maskedInput.splice(3, 0, '-');
      }
      if (maskedInput.length > 6 && maskedInput[7] !== '-') {
        maskedInput.splice(7, 0, '-');
      }
    }
    const returnValue = maskedInput.join('');
    return returnValue;
  };

  const validate = (
    payload: string,
    type: ValidationTypes,
    required?: boolean
  ) => {
    const validationObject = validationTypes[type];
    if (payload && !validationObject.pattern.test(payload)) {
      return validationObject.messages.pattern;
    }
    if (payload && validationObject.max) {
      if (payload.length > validationObject.max) {
        return validationObject.messages.max;
      }
    }
    if (payload && validationObject.min) {
      if (payload.length < validationObject.min) {
        return validationObject.messages.min;
      }
    }
    if (!payload && required) {
      return validationObject.messages.required;
    }
    return '';
  };

  const updateInputAndValidate = (
    payload: string,
    type?: ValidationTypes,
    required?: boolean,
    event?: any
  ) => {
    if (type === 'phoneNumber') {
      payload = phoneNumberMasking(payload, event);
    }
    type && setErrorMessage(validate(payload, type, required) || '');
    setInputValue(payload);
    return payload;
  };
  return {
    updateInputAndValidate,
    validate,
    setErrorMessage,
    inputValue,
    errorMessage,
  };
};

export default useInputWithValidation;
