import { Component } from 'react';
import { TextBody } from '@ally/metronome-ui';

/**
 * Error boundary to display an error message if there is an error displaying the UI
 */
class DefaultErrorBoundary extends Component<{}, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <TextBody tag='strong' size='md' type='error' weight='bold'>
          Whoops!
        </TextBody>
      );
    }
    return <>{this.props.children}</>;
  }
}

export default DefaultErrorBoundary;
