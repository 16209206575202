import styled from 'styled-components';
import { TextBody, TextHeading, Box } from '@ally/metronome-ui';

const PaddedHeader = styled(TextHeading)`
  padding: 20px 0 5px 0;
`;

interface IProps {
  title: string;
  icon: React.ReactNode;
}

const InfoGraphic: React.FC<IProps> = (props) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      textAlign='center'
      padding='0 30px'
    >
      {props.icon}
      <PaddedHeader tag='h3' size='sm'>
        {props.title}
      </PaddedHeader>
      <TextBody tag='p' size='sm'>
        {props.children}
      </TextBody>
    </Box>
  );
};

export default InfoGraphic;
