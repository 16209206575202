import { ProspectAssessmentStatusType } from '../types';
import { Timeslot } from './Timeslot';

interface IProspectAssessment {
  advisorFocusTopics: string;
  assessmentId: number;
  assessmentStatus: ProspectAssessmentStatusType;
  createdDate: string;
  email: string;
  investableAssets: string;
  lastModifiedDate: string;
  phoneNumber: string;
  nameJoin: string;
  emailJoin: string;
  comments: string;
  textMessage: boolean;
  opportunityId: string;
  selectedAppointment: Timeslot;
  contactId: string;
  closedCaptioning: boolean;
}

class ProspectAssessment implements IProspectAssessment {
  advisorFocusTopics: string;
  assessmentId: number;
  assessmentStatus: ProspectAssessmentStatusType;
  createdDate: string;
  email: string;
  investableAssets: string;
  lastModifiedDate: string;
  phoneNumber: string;
  nameJoin: string;
  emailJoin: string;
  comments: string;
  textMessage: boolean;
  opportunityId: string;
  selectedAppointment: Timeslot;
  contactId: string;
  closedCaptioning: boolean;

  constructor(
    advisorFocusTopics: string = '',
    assessmentId: number = 0,
    assessmentStatus: ProspectAssessmentStatusType = '',
    createdDate: string = '',
    email: string = '',
    investableAssets: string = '',
    lastModifiedDate: string = '',
    phoneNumber: string = '',
    nameJoin: string = '',
    emailJoin: string = '',
    comments: string = '',
    textMessage: boolean = false,
    opportunityId: string = '',
    selectedAppointment: Timeslot = new Timeslot(),
    contactId: string = '',
    closedCaptioning: boolean = false
  ) {
    this.advisorFocusTopics = advisorFocusTopics;
    this.assessmentId = assessmentId;
    this.assessmentStatus = assessmentStatus;
    this.createdDate = createdDate;
    this.email = email;
    this.investableAssets = investableAssets;
    this.lastModifiedDate = lastModifiedDate;
    this.phoneNumber = phoneNumber;
    this.nameJoin = nameJoin;
    this.emailJoin = emailJoin;
    this.comments = comments;
    this.textMessage = textMessage;
    this.opportunityId = opportunityId;
    this.selectedAppointment = selectedAppointment;
    this.contactId = contactId;
    this.closedCaptioning = closedCaptioning;
  }
}

export { ProspectAssessment };
export type { IProspectAssessment };
