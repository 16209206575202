import { Box, TextBody, useAccordionItem } from '@ally/metronome-ui';
import { Currency, LoadingSpinner, Link } from '../../atoms';
import {
  useAccountNumberMask,
  useConfig,
  useAccounts,
  useAppCache,
} from '../../../hooks';
import { DepositAccount } from '../../../models';
import { AccountType } from '../../../types';
import { muiAllyTheme } from '../../../utils';
import styled from 'styled-components';

const StyledTextBody = styled(TextBody)`
  font-size: 0.688rem;
`;

const investAccounts: AccountType[] = ['INVA', 'INVS', 'INVW'];

const renderAccountValuePartial = (account: DepositAccount) => {
  return (
    <>
      <StyledTextBody tag='b' size='xs' weight='bold'>
        ACCOUNT VALUE
      </StyledTextBody>
      <TextBody tag='p' size='xs'>
        <Currency
          value={
            investAccounts.includes(account.type)
              ? account.value
              : account.balance
          }
        />
      </TextBody>
    </>
  );
};

interface IProps {
  closeMenu: Function;
  isSideNav?: boolean;
}

const AccountListNav: React.FC<IProps> = (props) => {
  const { maskAccountNumber } = useAccountNumberMask();
  const { investConfig } = useConfig();
  const { appCache } = useAppCache();
  const { accountColors, getAccountType, mergedAccounts } = useAccounts();
  const accordionContext = useAccordionItem();

  const formatAccountUrl = (account: DepositAccount): string => {
    if (account.type === 'INVW') {
      return `/account-details/${account.accountId}`;
    }
    if (account.type === 'INVA' || account.type === 'INVS') {
      return `${investConfig.investUrl}/accounts/holdings-balances/${account.accountId}/overview`;
    }
    return `${investConfig.bankUrl}`;
  };

  const formatNickname = (account: DepositAccount): string => {
    if (account.name) {
      return account.name.length < 18
        ? account.name
        : `${account.name.substring(0, 18)}...`;
    }
    return '';
  };

  return (
    <LoadingSpinner
      color='white'
      isLoading={!mergedAccounts || !mergedAccounts.length}
      isSlowMessage='Account list is still loading.'
    >
      <>
        {!appCache.investAccountList.isLoading &&
          !appCache.depositAccountList.isLoading &&
          mergedAccounts.map((account: DepositAccount, index: number) => (
            <Box
              width='100%'
              display='flex'
              border='1px solid'
              borderColor='slate-3'
              key={account.number}
            >
              <Box
                width='4px'
                marginRight='16px'
                backgroundColor={
                  accountColors[account.type] || muiAllyTheme.colors.inv
                }
              ></Box>
              <Box padding='10px 0'>
                <StyledTextBody tag='b' weight='bold' size='xs'>
                  {getAccountType(account.type)}
                </StyledTextBody>
                <TextBody tag='p' size='sm' data-private>
                  <Link
                    to={formatAccountUrl(account)}
                    onClick={() => props.closeMenu()}
                    text={formatNickname(account)}
                    transmitdestination={
                      account.type === 'INVW'
                        ? 'wealth'
                        : account.type === 'INVA' || account.type === 'INVS'
                        ? 'invest'
                        : 'bank_react'
                    }
                    ref={
                      index === 0 && props.isSideNav
                        ? accordionContext.handleFocus
                        : undefined
                    }
                  />{' '}
                  {props.isSideNav && <br />}
                  {(!props.isSideNav ||
                    !investAccounts.includes(account.type)) &&
                    maskAccountNumber(account.number, false, 2)}
                </TextBody>
                {props.isSideNav && (
                  <Box marginTop='4px'>
                    {renderAccountValuePartial(account)}
                  </Box>
                )}
              </Box>
              {!props.isSideNav && (
                <Box marginLeft='auto' textAlign='right' padding='10px 20px'>
                  {renderAccountValuePartial(account)}
                </Box>
              )}
            </Box>
          ))}
      </>
    </LoadingSpinner>
  );
};

export default AccountListNav;
