import styled from 'styled-components';
import { MuiIconMap } from '@ally/metronome-icons/dist/cjs';
import { Box, Icon } from '@ally/metronome-ui';

const StyledIconBox = styled(Box)`
  align-self: center;
  flex: 0 0 28px;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

interface IProps {
  icon?: MuiIconMap;
  paddingTop?: string;
  paddingBottom?: string;
}

const IconRow: React.FC<IProps> = (props) => {
  return (
    <StyledBox
      paddingTop={props.paddingTop ? props.paddingTop : '5px'}
      paddingBottom={props.paddingBottom ? props.paddingBottom : '0px'}
    >
      <StyledIconBox>
        {!!props.icon && <Icon icon={props.icon} fill='gray-900' />}
      </StyledIconBox>
      <Box display='flex' alignItems='center'>
        {props.children}
      </Box>
    </StyledBox>
  );
};

export default IconRow;
