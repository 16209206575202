import styled from 'styled-components';
import { Box, Icon, Space, TextBody } from '@ally/metronome-ui';
import { muiEqualHousing } from '@ally/metronome-icons/dist/cjs';
import { Row, Link } from '../../atoms';
import { LeavingAllyModal } from '../../organisms';

const StyledLink = styled(Link)`
  ${({ theme: { colors } }): string => `
    color: ${colors['sky-3']};
  `}
`;

interface IProps {}

// TODO: Fix white spacing on commit
const Footer: React.FC<IProps> = (props) => {
  return (
    <Row>
      <Box display='flex' justifyContent='center' marginBottom='xl'>
        <Space paddingX={['5px', '25px']}>
          <StyledLink
            to='https://www.ally.com/do-it-right/'
            text='Ally Community'
            target='_blank'
          />
          <StyledLink
            to='https://www.ally.com/security/'
            text='Security'
            target='_blank'
          />
          <StyledLink
            to='https://www.ally.com/privacy/'
            text='Privacy'
            target='_blank'
          />
          <StyledLink
            to='https://www.ally.com/legal/'
            text='Legal'
            target='_blank'
          />
        </Space>
      </Box>
      <Space marginBottom='13px'>
        <TextBody tag='p' size='sm'>
          Ally Financial Inc. (NYSE: ALLY) is a leading digital financial
          services company and a top 25 U.S. financial holding company offering
          financial products for consumers, businesses, automotive dealers and
          corporate clients. Ally Bank, the company's direct banking subsidiary,
          offers an array of banking products and services.
        </TextBody>
        <TextBody tag='p' size='sm'>
          Deposit products (“Bank Accounts” on Ally.com) are offered by Ally
          Bank, Member FDIC. In addition, mortgage products are offered by Ally
          Bank, Equal Housing Lender{' '}
          <Icon icon={muiEqualHousing} fill='slate-5' />, NMLS ID 181005.
        </TextBody>
        <TextBody tag='p' size='sm'>
          Credit and collateral are subject to approval and additional terms and
          conditions apply. Programs, rates and terms and conditions are subject
          to change at any time without notice.
        </TextBody>
        <TextBody tag='p' size='sm'>
          Options involve risk and are not suitable for all investors. Review
          the{' '}
          <StyledLink
            to='http://ally.com/docs/invest/risks-of-standardized-options'
            text=' Characteristics and Risks of Standardized Options brochure (PDF) '
            target='_blank'
          />{' '}
          before you begin trading options. Options investors may lose the
          entire amount of their investment in a relatively short period of
          time.
        </TextBody>
        <TextBody tag='p' size='sm'>
          Trading on margin involves risk. You can lose more funds than you
          deposit in a margin account. Please review{' '}
          <StyledLink
            to='https://www.ally.com/resources/pdf/invest/margin-agreement.pdf'
            text=' Margin Account Agreement and Disclosure (PDF) '
            target='_blank'
          />{' '}
          for more information regarding margin trading.
        </TextBody>
        <TextBody tag='p' size='sm'>
          Securities products and services are offered through Ally Invest
          Securities LLC, member{' '}
          <LeavingAllyModal
            variant='link'
            to='https://www.finra.org/'
            text='FINRA'
          />{' '}
          /{' '}
          <LeavingAllyModal
            variant='link'
            to='http://www.sipc.org/'
            text='SIPC'
          />{' '}
          You can find background on Ally Invest Securities at{' '}
          <LeavingAllyModal
            variant='link'
            to='https://brokercheck.finra.org/firm/summary/136131'
            text='FINRA’s BrokerCheck.'
          />{' '}
          <StyledLink
            to='https://www.ally.com/invest/disclosures/#securities'
            text='View Securities disclosures'
            target='_blank'
          />
          . Advisory services are offered through Ally Invest Advisors Inc., a
          registered investment adviser.{' '}
          <StyledLink
            to='https://www.ally.com/invest/disclosures/#advisors'
            text='View Advisory disclosures.'
            target='_blank'
          />{' '}
          Ally Invest Advisors and Ally Invest Securities are wholly owned
          subsidiaries of Ally Invest Group Inc. Ally Bank and Ally Invest Group
          are wholly owned subsidiaries of Ally Financial Inc. Securities
          products are{' '}
          <TextBody tag='span' size='sm' weight='bold'>
            NOT FDIC INSURED, NOT BANK GUARANTEED,{' '}
          </TextBody>
          and
          <TextBody tag='span' size='sm' weight='bold'>
            {' '}
            MAY LOSE VALUE.
          </TextBody>
        </TextBody>
        <TextBody tag='p' size='sm'>
          Ally Invest Securities provides 3rd party research for information
          purposes only and shouldn't be considered as investment advice.
          Recommendations aren't based on any client's particular objectives or
          individual circumstances.
        </TextBody>
      </Space>
    </Row>
  );
};

export default Footer;
