import { CookieType } from '../types';

export const getCookie = (
  key: CookieType,
  defaultValue: string = ''
): string => {
  const res = document.cookie.split(';').reduce((total, currentCookie) => {
    const [storedKey, storedValue] = currentCookie.split('=');
    // jwt is getting split with an extra space in front so need to trim it
    const trimmedKey = key.trim();
    return trimmedKey === storedKey ? decodeURIComponent(storedValue) : total;
  }, defaultValue);
  return res;
};

export const setCookie = (
  key: CookieType,
  value: string,
  numberOfDays: number = 1
) => {
  const now = new Date();
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
  document.cookie = `${key}=${value};expires=${now.toUTCString()};path=/`;
};

export const clearCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
};
