import React, { useEffect } from 'react';
import { Route, Redirect, useLocation, Switch } from 'react-router-dom';
import {
  WealthOverviewPage,
  LoginPage,
  NetWorthDetailsPage,
  ProspectAssessmentPage,
  ProspectAssessmentIntersititialPage,
  AuthenticatingPage,
  DocumentReviewPage,
  AccountDetailsPage,
  LogoutPage,
} from '../../pages';
import { AuthRoute } from '../../atoms';
import { useConfig, useSession } from '../../../hooks';
import { RouteValues, RouteValue } from '../../../types';

const skipRefreshUrls: RouteValue[] = [
  RouteValues.logout,
  RouteValues.authenticating,
];

interface IProps {}

const Routes: React.FC<IProps> = (props) => {
  const location = useLocation();
  const { environment } = useConfig();
  const { refreshSessionTimer } = useSession();

  useEffect(() => {
    const url = location.pathname.toLowerCase();
    if (!skipRefreshUrls.some((route) => url.includes(route))) {
      refreshSessionTimer();
    }
  }, [location]);

  return (
    <Switch>
      <AuthRoute path='/' exact component={WealthOverviewPage} />
      <AuthRoute path='/wealth-overview' exact component={WealthOverviewPage} />
      {environment !== 'prod' && environment !== 'qa' && (
        <Route path='/login' exact component={LoginPage} />
      )}
      <AuthRoute
        path='/prospect-assessment'
        exact
        component={ProspectAssessmentPage}
      />
      <AuthRoute
        path='/prospect-assessment-interstitial'
        exact
        component={ProspectAssessmentIntersititialPage}
      />
      <AuthRoute
        path='/account-details/:paramId'
        exact
        component={AccountDetailsPage}
      />
      <AuthRoute
        path='/net-worth-details'
        exact
        component={NetWorthDetailsPage}
      />
      <AuthRoute path='/document-review' exact component={DocumentReviewPage} />
      <Route path='/logout' component={LogoutPage} />
      <Route path='/authenticating' component={AuthenticatingPage} />
      <Route path='*' exact>
        <Redirect to='/wealth-overview' />
      </Route>
    </Switch>
  );
};

export default Routes;
