import { useEffect } from 'react';
import { TextBody, Box, Space, Media } from '@ally/metronome-ui';
import { Card, Popover, NextSteps } from '../../molecules';
import {
  Plaid,
  WealthViewDetailsDrawer,
  UpcomingMeetings,
} from '../../organisms';
import { Currency, Link, WealthAccountsUpToDate, DL } from '../../atoms';
import {
  useWealthController,
  useAppCache,
  usePlaid,
  useCustomersService,
} from '../../../hooks';
import { IWidgetProps } from '../../../interfaces';

const NetWorthLink = () => {
  return (
    <Space marginBottom={['20px', '20px', '20px', '0']} marginTop='20px'>
      <Link
        allytmln='viewNetWorthDetails'
        $internal
        to='/net-worth-details'
        text='View Net Worth Details'
        variant='outline'
        size='sm'
      />
    </Space>
  );
};

interface IProps extends IWidgetProps {}

const WealthOverviewWidget: React.FC<IProps> = (props) => {
  const {
    wealthOverview,
    setWealthOverviewIsLoading,
    getWealthOverview,
  } = useWealthController();
  const {
    appCache,
    isProspect,
    assetsUnderCare,
    assetsUnderCarePercentage,
    isFundedWealth,
  } = useAppCache();
  const { upcomingAppointment } = useCustomersService();
  const { startPolling, pollingStatus } = usePlaid();

  useEffect(() => {
    if (pollingStatus === 'polling') {
      setWealthOverviewIsLoading();
    } else if (pollingStatus === 'success') {
      getWealthOverview();
      props.refreshWidgets && props.refreshWidgets();
    } else if (pollingStatus === 'failed') {
      setWealthOverviewIsLoading(false);
    }
  }, [pollingStatus]);

  return (
    <>
      <Card
        isHeroCard
        title='Wealth Overview'
        drawer={
          (!!appCache.proposalList.proposals.length ||
            !!appCache.accountList.accounts.length) && (
            <WealthViewDetailsDrawer />
          )
        }
      >
        <Box
          display='flex'
          flexDirection={['column', 'column', 'row']}
          paddingTop='20px'
        >
          <Box display='flex' flexDirection='column' flexGrow={1}>
            <Box
              display='flex'
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent='flex-start'
              alignContent='stretch'
            >
              <Box
                flexGrow={0}
                flexShrink={1}
                flexBasis={['100%', '100%', '100%', '300px']}
                marginRight={['0', '0', '0', '10px']}
              >
                <DL>
                  <Popover
                    popoverAriaLabel='Net Worth'
                    triggerText='Net Worth'
                    body='The value of the assets you own minus the liabilities, or debt, you owe.'
                    triggerAriaLabel='Select to learn more about net worth'
                    heading='Net Worth'
                  />
                  <TextBody tag='dd' size='xl'>
                    <Currency
                      isLoading={wealthOverview.isLoading}
                      value={wealthOverview.netWorth}
                      hasError={!!wealthOverview.error}
                    />
                  </TextBody>
                </DL>
                <Box paddingBottom={['10px', '10px', '10px', 0]}>
                  <WealthAccountsUpToDate wealthOverview={wealthOverview} />
                  <Plaid
                    buttonText='Add new account'
                    onSuccess={startPolling}
                  />
                </Box>
                <Media.LgUp>
                  <NetWorthLink />
                </Media.LgUp>
              </Box>
              {!isProspect && isFundedWealth ? (
                <Box
                  flexGrow={0}
                  marginLeft={[
                    '0',
                    '0',
                    '0',
                    `${wealthOverview.isLoading ? '30px' : '10px'}`,
                  ]}
                  flexBasis={['100%', '100%', '100%', '250px']}
                >
                  <DL>
                    <Popover
                      popoverAriaLabel='Assets Under Care'
                      heading='Assets Under Care'
                      body='The investment assets your advisor directs on your behalf.'
                      triggerAriaLabel='Select to learn more about assets under care'
                      triggerText='Assets Under Care'
                    />
                    <TextBody tag='dd' size='xl'>
                      <Currency
                        isLoading={wealthOverview.isLoading}
                        value={assetsUnderCare}
                        hasError={!!wealthOverview.error}
                      />
                    </TextBody>
                  </DL>
                  {assetsUnderCarePercentage >= 0.01 && (
                    <TextBody tag='p' size='sm'>
                      Your assets under care (AUC) make up about{' '}
                      {assetsUnderCarePercentage}% of your total net worth.
                    </TextBody>
                  )}
                  <Media.LgDown>
                    <NetWorthLink />
                  </Media.LgDown>
                </Box>
              ) : (
                <Box
                  flex={1}
                  borderTop={['1px solid', '1px solid', '1px solid', 'none']}
                  borderLeft={['none', 'none', 'none', '1px solid']}
                  borderColor={['grey-80', 'grey-80', 'grey-80', 'grey-80']}
                  paddingLeft={['0', '0', '0', '30px']}
                  paddingY={['10px', '10px', '10px', '0']}
                  minWidth='250px'
                >
                  <NextSteps />
                </Box>
              )}
              {!!upcomingAppointment && (
                <Box
                  flexGrow={0}
                  flexShrink={1}
                  flexBasis={['100%', '100%', '100%', '340px']}
                  borderTop={['1px solid', '1px solid', '1px solid', 'none']}
                  borderLeft={['none', 'none', 'none', '1px solid']}
                  borderColor={['grey-80', 'grey-80', 'grey-80', 'grey-80']}
                  marginLeft={['0', '0', '0', '30px']}
                  paddingLeft={['0', '0', '0', '30px']}
                  paddingTop={['10px', '10px', '10px', '0']}
                >
                  <UpcomingMeetings />
                </Box>
              )}
            </Box>
          </Box>
          {/*
        // TODO this will be re-added as part of CASH-284
        <Box
          borderTop={['1px solid', '1px solid', 'none']}
          borderLeft={['none', 'none', '1px solid']}
          borderColor={['grey-80', 'grey-80', 'grey-80', 'grey-80']}
          paddingLeft={['0', '0', '30px']}
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          paddingTop={['20px', '20px', '0', '0']}
          minWidth='200px'
        >
          <AdvisorSummary />
        </Box> */}
        </Box>
      </Card>
    </>
  );
};

export default WealthOverviewWidget;
