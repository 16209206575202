import styled from 'styled-components';
import { Link as MetLink, LinkProps, Spinner } from '@ally/metronome-ui';
import { ButtonProps } from '@ally/metronome-ui/dist/cjs/Button';
import { RouteValue } from '../../../types';
import { muiAllyTheme } from '../../../utils';
import { forwardRef, Ref } from 'react';
import { useTransmit } from '../../../hooks';
import { Destination } from '@ally/transmitigator';

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 20px;
  left: 44px;
  ${({ theme: { media } }): string => `
  ${media.down('sm')} {
    top: 4px;
    left: 30px;
  }
`}
`;

const StyledSpan = styled.span`
  position: relative;
`;
const BaseLink = styled(MetLink)`
  white-space: break-spaces;
  ${(props: CustomProps): string | undefined =>
    props.color && `color: ${props.color};`}
  ${(props: CustomProps): string | undefined =>
    props.fontSize && `font-size: ${props.fontSize}`}
`;

const StyledDisabledLink = styled(BaseLink)`
  pointer-events: none;
  color: ${muiAllyTheme.colors['grey-80']};
`;

// interface IProps extends LinkProps {}

// This enforces props.to to be type RouteValue if props.$internal is present on the element, if not or props.$internal=false, props.to is type string
// $internal must contain $ to satisfy styled-components as it is a transient property
type InternalLinkProps =
  | { $internal: true; to: RouteValue }
  | { $internal?: false; to: string };

type CustomProps = {
  isLoading?: boolean;
  color?: string;
  transmitdestination?: Destination;
  fontSize?: string;
};

type Props = LinkProps & InternalLinkProps & ButtonProps & CustomProps;

const Link = forwardRef((props: Props, focusRef: Ref<HTMLAnchorElement>) => {
  const { leaveWithTicket } = useTransmit();

  const textProps = {
    ...props,
    text: props.isLoading ? '' : props.text,
    to: props.transmitdestination ? '#' : props.to,
    transmitdestination: props.transmitdestination,
  };

  return (
    <StyledSpan>
      {props.isLoading && <StyledSpinner size='small' themeColor='light' />}
      {props.disabled ? (
        <StyledDisabledLink {...textProps} ref={focusRef} />
      ) : (
        <BaseLink
          {...textProps}
          onClick={
            props.transmitdestination && props.transmitdestination !== 'wealth'
              ? (e) => leaveWithTicket(e, props.to, props.transmitdestination)
              : props.onClick
          }
          ref={focusRef}
        />
      )}
    </StyledSpan>
  );
});

export default Link;
