import { createContext, FC, useContext, useEffect } from 'react';
import { useConfig } from '../../hooks';
import { Action } from '../../store/actions';
import { makeGuid } from '../../utils';
import { storeContext } from '../../store/store';
export interface IBroadcastDispatch {
  broadcastRef: {
    broadcastDispatch: (action: Action, includeSelf: boolean) => void;
  };
}

const BroadcastDispatch = createContext<IBroadcastDispatch>(
  {} as IBroadcastDispatch
);

export const useBroadcastRef = (): IBroadcastDispatch =>
  useContext(BroadcastDispatch);

export const BroadcastDispatchProvider: FC = ({ children }) => {
  const channel = new BroadcastChannel('personal-advice-broadcast-dispatch');
  const { wealthConfig } = useConfig();
  const currentTabGuid = makeGuid();
  const { dispatch } = useContext(storeContext);

  useEffect(() => {
    channel.onmessage = (event) => {
      if (event.origin !== wealthConfig.baseUrl) {
        return;
      }

      const {
        action: { type, payload },
        currentTabGuid: selfGuid,
        includeSelf,
      } = event.data;

      if (!includeSelf && selfGuid === currentTabGuid) {
        return;
      }

      dispatch({ type, payload });
    };

    return () => {
      channel.close();
    };
  }, []);

  const broadcastDispatch = (action: Action, includeSelf: Boolean = false) => {
    channel.postMessage({ action, currentTabGuid, includeSelf });
  };

  const broadcastRef = {
    broadcastDispatch,
  };

  return (
    <BroadcastDispatch.Provider value={{ broadcastRef }}>
      {children}
    </BroadcastDispatch.Provider>
  );
};
