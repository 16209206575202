import { useEffect, useState } from 'react';
import { Box, TextBody } from '@ally/metronome-ui';
import { GoalIconSelector } from '../../icons';
import { Goal } from '../../../models';

interface IProps {
  goal: Goal;
  start: number;
  end: number;
}

const GoalTimelineItem: React.FC<IProps> = (props) => {
  // Left state and effect can be split out into a hook if we ever need a timeline
  // that consists of different items
  const [left, setLeft] = useState<number>(0);

  useEffect(() => {
    const left =
      ((props.goal.year - props.start) / (props.end - props.start)) * 100;
    setLeft(left);
  }, [props]);

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      width='fit-content'
      marginTop='-25px'
      position='absolute'
      left={left + '%'}
    >
      <Box
        flexShrink={0}
        width='50px'
        height='50px'
        bg='white'
        border='2px solid'
        borderColor={props.goal.onTrack ? 'success' : 'error'}
        borderRadius='50%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
      >
        <TextBody tag='strong' size='xs' weight='bold'>
          {props.goal.year}
        </TextBody>
        <GoalIconSelector type={props.goal.type} height='25px' width='25px' />
      </Box>
    </Box>
  );
};

export default GoalTimelineItem;
