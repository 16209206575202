import { APIData, IAPIData } from './APIData';

interface IACMContact {
  Contact?: {
    contactValue?: string;
  };
}

interface IACMCustomer extends IAPIData {
  payload?: {
    CustomerSearchResponse?: {
      searchResult?: [
        {
          personalEmailList?: IACMContact[] | [];
          secondaryEmailList?: IACMContact[] | [];
          homeTelephoneList?: IACMContact[] | [];
          alternateTelephoneList?: IACMContact[] | [];
          unknownPhoneList?: IACMContact[] | [];
        }
      ];
    };
  };
}

class ACMCustomer extends APIData {
  email: string = '';
  phone: string = '';

  setData(email: string, phone: string) {
    this.email = email;
    this.phone = phone;
    this.setLoaded();
  }
}

export type { IACMCustomer };
export { ACMCustomer };
