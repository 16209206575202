import { AxiosResponse } from 'axios';
import { useAxios, useSession } from '../index';
import { ACMCustomer, IACMCustomer } from '../../models';

const useACMService = () => {
  const { investInstance } = useAxios('/acm-service');
  const { session } = useSession();

  const getACMCustomer = async (): Promise<ACMCustomer> => {
    const acmCustomer = new ACMCustomer(true);

    if (!session.guid) {
      acmCustomer.setError();
      return acmCustomer;
    }

    const { data }: AxiosResponse<IACMCustomer> =
      ((await investInstance(
        'GET',
        `/customer/${session.guid}`
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    if (data) {
      const searchResult =
        data.payload?.CustomerSearchResponse?.searchResult?.[0] || {};

      const phone =
        searchResult.homeTelephoneList?.[0].Contact?.contactValue ||
        searchResult.alternateTelephoneList?.[0].Contact?.contactValue ||
        searchResult.unknownPhoneList?.[0].Contact?.contactValue ||
        '';

      const email =
        searchResult.personalEmailList?.[0].Contact?.contactValue ||
        searchResult.secondaryEmailList?.[0].Contact?.contactValue ||
        '';

      acmCustomer.setData(email, phone);
    } else {
      acmCustomer.setError();
    }
    return acmCustomer;
  };

  return { getACMCustomer };
};

export default useACMService;
