import { ModalContextProps } from '@ally/metronome-ui';
import { SortableColumn } from '@ally/metronome-ui/dist/cjs/Table/types';
import { ReactNode } from 'react';
import { muiAllyTheme } from '../../../utils';
import { Modal, Table } from '../../molecules';

interface IProps<T extends object> {
  context: ModalContextProps;
  data: T[];
  columns: SortableColumn<T>[];
  captionText?: string;
}

const TableViewModal = <T extends object>(
  props: IProps<T> & { children?: ReactNode }
) => {
  return (
    <Modal
      context={props.context}
      heading='Table View'
      headingProps={{
        color: muiAllyTheme.colors.plum,
        fontSize: '1.6rem',
        fontWeight: 'normal',
      }}
      content={
        <Table
          data={props.data}
          columns={props.columns}
          captionText={props.captionText || ''}
          isCaptionVisible={false}
        />
      }
    />
  );
};

export default TableViewModal;
