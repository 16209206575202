import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { HistoricalDetail } from './../models';

interface INetWorthPerformance extends IAPIData {
  historicalDetails: HistoricalDetail[];
  percentChangeThisYear: number;
}

class NetWorthPerformance extends APIData implements INetWorthPerformance {
  historicalDetails: HistoricalDetail[] = [];
  percentChangeThisYear: number = 0;
  dataName = 'NetWorth Performance';

  setData(netWorthPerformance: NetWorthPerformance) {
    this.historicalDetails = netWorthPerformance.historicalDetails;
    this.percentChangeThisYear = netWorthPerformance.percentChangeThisYear;
    this.setLoaded();
  }
}

export { NetWorthPerformance };
export type { INetWorthPerformance };
