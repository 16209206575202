import styled from 'styled-components';
import { TextBody, Box, TextHeading } from '@ally/metronome-ui';

const Wrapper = styled(Box)`
  ${({ theme: { colors } }): string => `
        background-color: ${colors.white};
        border: 1px ${colors['grey-80']} solid;
        box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.15);
    `}
`;

const HeaderWrapper = styled(Box)`
  ${({ theme: { colors } }): string => `
        border-bottom: 1px ${colors['grey-80']} solid;
    `}
  padding-left: 20px;
`;

interface IProps {
  title?: string;
  marginBottom?: string;
  drawer?: React.ReactNode;
  isHeroCard?: boolean;
}

const Card: React.FC<IProps> = (props) => {
  return (
    <Wrapper marginBottom={props.marginBottom ? props.marginBottom : '40px'}>
      {props.title && !props.isHeroCard && (
        <HeaderWrapper
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          height='45px'
        >
          <TextBody tag='span' size='md' weight='bold'>
            {props.title}
          </TextBody>
        </HeaderWrapper>
      )}
      <Box padding={['10px', '10px', '20px']}>
        {props.title && props.isHeroCard && (
          <TextHeading tag='h1' size='sm' tabIndex={-1}>
            {props.title}
          </TextHeading>
        )}
        {props.children}
      </Box>
      {props.drawer}
    </Wrapper>
  );
};

export default Card;
