import { Box } from '@ally/metronome-ui';
import React, { useEffect } from 'react';
import { LayoutProps } from 'styled-system';
declare global {
  interface Window {
    Highcharts: any;
  }
}

const Highchart: React.FC<IProps> = (props: IProps) => {
  const defaultOptions = {
    credits: {
      enabled: false,
    },
  };

  useEffect(() => {
    // TODO: @types/highchart has same name
    const Highcharts = window.Highcharts;

    const { lang, noData } = props.chartOptions;
    if (lang && noData) {
      Highcharts.setOptions({
        lang,
        noData,
      });
    }
    Highcharts.chart(props.id, { ...defaultOptions, ...props.chartOptions });
  }, [props.chartOptions]);

  return <Box id={props.id} width={props.width} height={props.height} />;
};

interface IProps extends LayoutProps {
  id: string;
  // TODO: will be replace with @types/highcharts
  chartOptions: any;
}

export default Highchart;
