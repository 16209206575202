import { useState, useMemo, useEffect } from 'react';
import { useOrionWidget } from '../index';

Element.prototype.remove = function () {
  if (this.parentElement) {
    this.parentElement.removeChild(this);
  }
};

const cachedScripts: string[] = [];

const useScript = (
  url: string,
  scriptElementId: string,
  widgetId = '',
  options = ''
) => {
  const { widgetStatus } = useOrionWidget(scriptElementId, widgetId, options);
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);

  return {
    widgetStatus,
    scriptLoaded,
    loadScript: useMemo(
      () => () => {
        if (cachedScripts.includes(url)) {
          setScriptLoaded(true);
          return;
        }
        // Don't remove me, just don't add me instead
        if (!document.getElementById(scriptElementId)) {
          const script = document.createElement('script');
          script.src = url;
          script.async = true;
          script.id = scriptElementId;
          document.body.appendChild(script);
          script.onload = () => {
            setScriptLoaded(true);
          };
          script.onerror = (err) => {
            // Added console log for production testing
            console.log(`script ${scriptElementId} error:`, err);
            // Remove from cachedScripts we can try loading again
            const index = cachedScripts.indexOf(url);
            if (index >= 0) {
              cachedScripts.splice(index, 1);
            }
            script.remove();
          };
        } else {
          setScriptLoaded(true);
        }
      },
      [scriptElementId, url]
    ),
  };
};

export default useScript;
