import { useRef } from 'react';
import { Link } from '../../atoms';
import styled from 'styled-components';
import { muiAllyTheme } from '../../../utils';
import { useConfig } from '../../../hooks';
import { Destination } from '@ally/transmitigator';

const StyledLink = styled(Link)`
  svg {
    width: 67px;
    height: 37px;
    path {
      fill: ${muiAllyTheme.colors.white};
      transform: scale(0.5);
      translate: -7px 0;
    }
  }
`;

interface IProps {
  transmitdestination?: Destination;
}

const AllyIcon: React.FC<IProps> = (props) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const { investConfig } = useConfig();

  return (
    <StyledLink
      to={`${investConfig.svcproviderUrl}/serviceprovider/ssoToAlly?relayState=${investConfig.bankUrl}/dashboard`}
      icon={{
        map: {
          base: {
            title: 'ally',
            width: 65,
            height: 37,
            paths: [
              {
                path:
                  'M22.32 0h5.619v25.049h-5.619v-25.049zM30.623 0h5.619v25.049h-5.619v-25.049zM57.152 6.951l-9.41 25.049h-5.958l2.979-7.21-6.973-17.839h5.991l3.757 10.899h0.068l3.588-10.899h5.958zM19.758 16.085c0-4.237-3.022-9.19-9.315-9.19s-9.315 4.953-9.315 9.19c0 4.032 2.738 8.714 8.428 9.156l4.811-5.211h-3.828c-2.191 0-4.047-1.662-4.047-3.945s1.856-3.945 4.047-3.945 4.047 1.662 4.047 3.945c0 2.12 0 6.941 0 8.845h5.171c0-2.346 0-6.814 0-8.845z',
                fill: '#fff',
              },
            ],
          },
        },
      }}
      ref={ref}
      aria-label={'https://secure.ally.com'}
      variant='link'
      text=''
      transmitdestination='bank_react'
    />
  );
};

export default AllyIcon;
