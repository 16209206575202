import { Currency, DL, Text } from '../../atoms';

interface IProps {
  value: number;
  isLoading?: boolean;
}

const WidgetMainValue: React.FC<IProps> = (props) => {
  return (
    <DL>
      <Text tag='dt' size='md' weight='bold'>
        {props.children}
      </Text>
      <Text tag='dd' size='xl' isLoading={props.isLoading}>
        <Currency value={props.value} />
      </Text>
    </DL>
  );
};

export default WidgetMainValue;
