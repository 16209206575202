import { FormText, TextBody } from '@ally/metronome-ui';
import {
  ContextualHelpProps,
  ContextualHelpVariant,
} from '@ally/metronome-ui/dist/cjs/FormFieldHelper';
import { ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { useInputWithValidation } from '../../../hooks';
import { ValidationTypes } from '../../../types';

const StyledTextBody = styled(TextBody)`
  text-transform: uppercase;
`;

interface IProps {
  passValue: Function;
  require?: boolean;
  id: string;
  label: string;
  validationType?: ValidationTypes;
  errorMessage?: string;
  value?: string;
  autoComplete?: string;
  dataPrivateField?: string;
  contextualHelp?: ContextualHelpProps & { variant: ContextualHelpVariant };
}

const LabeledInput: React.FC<IProps> = (
  props: React.PropsWithChildren<IProps>
) => {
  const {
    updateInputAndValidate,
    inputValue,
    errorMessage,
    setErrorMessage,
  } = useInputWithValidation();

  useEffect(() => {
    if (props.value) {
      changeInputValue(props.value);
    }
  }, []);

  const changeInputValue = (value: string, event?: any) => {
    props.passValue(
      props.id,
      updateInputAndValidate(value, props.validationType, props.require, event)
    );
  };

  useEffect(() => {
    if (props.errorMessage) {
      setErrorMessage(props.errorMessage);
    }
  }, [props.errorMessage]);

  return (
    <FormText
      allytmfn={props.id}
      labelContent={
        <StyledTextBody tag='span' size='xs'>
          {props.label}
        </StyledTextBody>
      }
      allytm-private={props.dataPrivateField}
      data-private={props.dataPrivateField}
      variant='input'
      inputType='text'
      value={inputValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        changeInputValue(e.target.value, e)
      }
      id={props.id}
      errorNotification={{ message: errorMessage }}
      autoComplete={props.autoComplete}
      contextualHelp={props.contextualHelp}
    />
  );
};

export default LabeledInput;
