import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../../../interfaces';
import { muiAllyTheme } from '../../../utils';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const VideoIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ? props.width : '24px'}
      height={props.height ? props.height : '24px'}
      viewBox='2 2 24 24'
      version='1.1'
      aria-hidden='true'
    >
      <g
        xmlns='http://www.w3.org/2000/svg'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g fill={props.fill ? props.fill : muiAllyTheme.colors['gray-900']}>
          <path d='M5.45124899,18 L13.0950846,18 C13.8734891,18 14.4766317,17.7897934 14.9045125,17.3693803 C15.3323932,16.9489672 15.5463336,16.3547995 15.5463336,15.5868773 L15.5463336,8.41312272 C15.5463336,7.64520049 15.3420629,7.05103281 14.9335214,6.63061968 C14.5249799,6.21020656 13.9315068,6 13.1531023,6 L5.45124899,6 C4.70185334,6 4.10596293,6.21020656 3.66357776,6.63061968 C3.22119259,7.05103281 3,7.64520049 3,8.41312272 L3,15.6233293 C3,16.3961118 3.21394037,16.9854192 3.64182111,17.3912515 C4.06970185,17.7970838 4.67284448,18 5.45124899,18 Z M16.6051571,14.3256379 L19.0999194,16.490887 C19.2449637,16.6172539 19.3948429,16.7193196 19.5495568,16.7970838 C19.7042707,16.8748481 19.8565673,16.9137303 20.0064464,16.9137303 C20.3013699,16.9137303 20.540693,16.8165249 20.7244158,16.6221142 C20.9081386,16.4277035 21,16.1701094 21,15.8493317 L21,8.17982989 C21,7.85905225 20.9081386,7.60267315 20.7244158,7.41069259 C20.540693,7.21871203 20.3013699,7.12272175 20.0064464,7.12272175 C19.8565673,7.12272175 19.7054795,7.16038882 19.5531829,7.23572296 C19.4008864,7.31105711 19.2497985,7.41433779 19.0999194,7.54556501 L16.6051571,9.69623329 L16.6051571,14.3256379 Z'></path>
        </g>
      </g>
    </StyledSVG>
  );
};

export default VideoIcon;
