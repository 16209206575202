import { Timeslot } from '../../models';

const useFormatter = () => {
  /**
   * Converts a sentence to camel case. Ex: Example Setence Here => exampleSentenceHere
   * @param sentence to convert to camel case
   * @returns The string converted to camel case
   */
  const camelCase = (sentence: string): string => {
    return sentence
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  };

  /**
   *
   * @param word Word to modify casing of
   * @returns A word to make the first letter uppercase and lowercase the rest
   */
  const capitalize = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  /**
   *
   * @param array Array of words
   * @returns Will return an array of strings as a single string with proper use of ',' and 'and'
   */
  const arrayToSentence = (array: string[]) => {
    if (array.length < 3) {
      return array.join(' and ');
    } else {
      return array.join(', ').replace(/(?=[^,]*$)/, ' and');
    }
  };

  /**
   *
   * @param date Date to turn into a string
   * @returns Returns a date in the Month dateOfMonth, year format ex: November 24, 2021
   */
  const dateToString = (date: Date) => {
    // Dates are not converted to "correct" date object when returned from api
    const convertedDate = new Date(date);
    const month = convertedDate.toLocaleString('default', { month: 'long' });
    const dateOfMonth = convertedDate.getDate();
    const year = convertedDate.getFullYear();

    return `${month} ${dateOfMonth}, ${year}`;
  };

  const defaultOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  };
  const numberFormatter = (
    value: number,
    options: Intl.NumberFormatOptions = defaultOptions,
    locales: string = 'en-US'
  ) => {
    const formatter = new Intl.NumberFormat(locales, {
      ...defaultOptions,
      ...options,
    });
    return formatter.format(value);
  };

  const defaultDateOptions: Intl.DateTimeFormatOptions = {
    dateStyle: 'long',
    timeStyle: 'short',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  /**
   *
   * @param value
   * @param todayCheck
   * @param options
   * @param locales
   * @returns Date formated, defaults to format: April 12, 2022 at 10:22 AM
   */
  const dateTimeFormatter = (
    value: string | Date,
    todayCheck: boolean = false,
    options: Intl.DateTimeFormatOptions = defaultDateOptions,
    locales: string = 'en-US'
  ) => {
    // Have to check if the date already has Z at the end
    const date =
      value.toString().slice(-1).toUpperCase() === 'Z'
        ? new Date(value)
        : new Date(new Date(value).toISOString());
    const today = new Date();
    const isToday: boolean =
      date.getDate() === today.getDate() &&
      date.getDay() === today.getDay() &&
      date.getFullYear() === today.getFullYear() &&
      todayCheck;

    const formatter = new Intl.DateTimeFormat(
      locales,
      isToday
        ? {
            timeStyle: options.timeStyle,
            timeZone: defaultDateOptions.timeZone,
          }
        : options
    );
    return isToday
      ? `today at ${formatter.format(date)}`
      : formatter.format(date);
  };

  const formatDateForInput = (date: Date | string): string => {
    date = new Date(date);
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${date.getFullYear()}-${month}-${day}`;
  };

  const scrubAccountNumber = (value: string) =>
    value.slice(0, value.indexOf(' XXXX'));

  const getTimezone = (value: string, locales: string = 'en-US'): string => {
    const date = new Date(`${value}Z`);
    const formatter = new Intl.DateTimeFormat(locales, {
      timeZoneName: 'short',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return (
      formatter.formatToParts(date).find((part) => part.type === 'timeZoneName')
        ?.value || ''
    );
  };

  const getQuarter = (value: string | Date): string => {
    const date = new Date(value);
    return `Q${Math.floor(date.getMonth() / 3 + 1)}`;
  };

  /**
   *
   * @returns Formatted string used to display a timeslot
   *  ex:Friday, October 28, 2022 (2:00 - 2:30 PM)
   *  ex:If no timeslot is passed in then returns 'No date selected'
   */
  const formatTimeslot = (timeslot?: Timeslot) => {
    return !!timeslot
      ? `${dateTimeFormatter(timeslot.startTime, false, {
          weekday: 'long',
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })} (${timeslot.startTime
          .toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
          .slice(0, -3)} - ${timeslot.endTime.toLocaleTimeString([], {
          hour: 'numeric',
          minute: '2-digit',
        })})`
      : 'No date selected';
  };

  /*
   * Get formatted date and time for Appoitment
   * @param value
   * @returns Date and time formatted e.g 26 Nov. | 04:00 p.m.
   */
  const getAppointmentDateTime = (value: string | Date): string => {
    const date = dateTimeFormatter(value, false, {
      month: 'short',
      day: 'numeric',
    })
      .split(' ')
      .join('. ');
    const [time, meridiem] = dateTimeFormatter(value, false, {
      hour: 'numeric',
      minute: '2-digit',
    }).split(' ');
    return ` | ${date} | ${time}\u00A0${
      (!!meridiem && `${meridiem.split('').join('.').toLowerCase()}.`) || ''
    }`;
  };

  return {
    camelCase,
    capitalize,
    arrayToSentence,
    dateToString,
    numberFormatter,
    dateTimeFormatter,
    scrubAccountNumber,
    getTimezone,
    getQuarter,
    formatDateForInput,
    formatTimeslot,
    getAppointmentDateTime,
  };
};

export default useFormatter;
