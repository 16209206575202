import { ErrorNotification, Box } from '@ally/metronome-ui';

interface IProps {
  errorMessage: string;
  errorId: string;
}

const CustomInputError: React.FC<IProps> = (props) => {
  return (
    <>
      {props.errorMessage && (
        <Box display='flex' flexDirection='row' justifyContent='flex-start'>
          <ErrorNotification
            id={props.errorId}
            errorMessage={props.errorMessage}
          />
        </Box>
      )}
    </>
  );
};
export default CustomInputError;
