enum InvestAccountTypes {
  'Individual' = 0,
  'Joint' = 1,
  'Roth IRA' = 2,
  'Traditional IRA' = 3,
  'Rollover IRA' = 4,
  'Custodial' = 5,
  'Investment' = 6,
}

export default InvestAccountTypes;
