import { useEffect, useState } from 'react';

/* Hook for creating key events. Custom key press events can be made, as well as events for preset keys */
const useKeyEvents = () => {
  const [listenerArray, setListenerArray] = useState<any[]>([]);

  useEffect(() => {
    return () => {
      listenerArray.forEach((listener) => {
        window.removeEventListener('keyup', listener);
      });
    };
  });
  /**
   * creates an event listener for a provided key
   * @param key string: the name of the key to set the listener to
   * @param callback function: the callback function to call when the key is pressed
   */
  const createKeyPressListener = (key: string, callback: Function) => {
    const keyboardEvent = (event: KeyboardEvent) => {
      if (event.key === key) {
        callback();
      }
    };
    window.addEventListener('keyup', keyboardEvent);
    setListenerArray([...listenerArray, keyboardEvent]);
  };
  /**
   * creates an event listener on the escape key
   * @param callback function: the function to call in response to the escape key being pressed
   * @returns the KeyboardEvent, so that the component using this can be cleaned up on dismount
   */
  const createEscKeyListener = (callback: Function) =>
    createKeyPressListener('Escape', callback);

  return { createKeyPressListener, createEscKeyListener };
};

export default useKeyEvents;
