import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../../../interfaces';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const DebtManagementIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ? props.width : '36px'}
      height={props.height ? props.height : '36px'}
      viewBox='0 0 36 36'
      version='1.1'
      aria-hidden={true}
    >
      <g
        xmlns='http://www.w3.org/2000/svg'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(-211.000000, -194.000000)'>
          <g transform='translate(211.000000, 194.000000)'>
            <g>
              <rect x='0' y='0' width='36' height='36' />
              <g transform='translate(6.538462, 1.384615)'>
                <path
                  d='M0.277815201,1.73588626 L8.86358615,4.49116188 M14.1917191,6.20102288 L23.37543,9.14818451'
                  stroke='#371447'
                  strokeWidth='1.27810651'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  fillRule='nonzero'
                  transform='translate(11.826623, 5.442035) rotate(-4.000000) translate(-11.826623, -5.442035) '
                />
                <rect
                  fill='#371447'
                  x='4.53846154'
                  y='30.4615385'
                  width='13.8461538'
                  height='1.38461538'
                  rx='0.692307692'
                />
                <circle
                  stroke='#371447'
                  strokeWidth='1.27810651'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  fillRule='nonzero'
                  cx='11.4615385'
                  cy='5.53846154'
                  r='2.76923077'
                />
                <line
                  x1='11.4615385'
                  y1='9'
                  x2='11.4615385'
                  y2='31.1538462'
                  stroke='#371447'
                  strokeWidth='1.38461538'
                  strokeLinecap='square'
                />
              </g>
            </g>
            <g transform='translate(1.384615, 5.538462)'>
              <text
                fontFamily='Lato-Black, Lato'
                fontSize='13.8461538'
                fontWeight='700'
                fill='#371447'
              >
                <tspan x='2.76923077' y='15.3846154'>
                  $
                </tspan>
              </text>
              <line
                x1='6.92307692'
                y1='-1.2297855e-14'
                x2='1.38461538'
                y2='6.70445344'
                stroke='#371447'
                strokeWidth='0.692307692'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <line
                x1='12.4615385'
                y1='-1.2297855e-14'
                x2='6.92307692'
                y2='6.70445344'
                stroke='#371447'
                strokeWidth='0.692307692'
                strokeLinecap='round'
                strokeLinejoin='round'
                transform='translate(9.692308, 3.352227) scale(-1, 1) translate(-9.692308, -3.352227) '
              />
              <path
                d='M0,16.6160137 C0,17.3183089 2.81436375,17.9104401 6.22351572,17.9930631 C9.63266768,18.0756861 12.3890033,17.3320794 12.4615385,16.6160137'
                stroke='#371447'
                strokeWidth='1.38461538'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </g>
            <g transform='translate(22.153846, 11.076923)'>
              <text
                fontFamily='Lato-Black, Lato'
                fontSize='13.8461538'
                fontWeight='700'
                fill='#371447'
              >
                <tspan x='2.76923077' y='15.3846154'>
                  $
                </tspan>
              </text>
              <line
                x1='6.92307692'
                y1='-1.2297855e-14'
                x2='1.38461538'
                y2='6.70445344'
                stroke='#371447'
                strokeWidth='0.692307692'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <line
                x1='12.4615385'
                y1='-1.2297855e-14'
                x2='6.92307692'
                y2='6.70445344'
                stroke='#371447'
                strokeWidth='0.692307692'
                strokeLinecap='round'
                strokeLinejoin='round'
                transform='translate(9.692308, 3.352227) scale(-1, 1) translate(-9.692308, -3.352227) '
              />
              <path
                d='M0,16.6160137 C0,17.3183089 2.81436375,17.9104401 6.22351572,17.9930631 C9.63266768,18.0756861 12.3890033,17.3320794 12.4615385,16.6160137'
                stroke='#371447'
                strokeWidth='1.38461538'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </g>
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

export default DebtManagementIcon;
