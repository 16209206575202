import { LocalStorageType, SessionStorageType } from '../types';

// Session Storage, this is NOT shared between tabs but it is copied over if you duplicate a tab
export const getSessionStorage = (key: SessionStorageType) =>
  sessionStorage.getItem(key);
export const setSessionStorage = (key: SessionStorageType, value: string) =>
  sessionStorage.setItem(key, value);
export const deleteSessionStorage = (key: SessionStorageType) =>
  sessionStorage.removeItem(key);

// Local Storage, this is shared between tabs and is copied over if you duplicate a tab
export const getLocalStorage = (key: LocalStorageType) =>
  localStorage.getItem(key);
export const setLocalStorage = (key: LocalStorageType, value: any) =>
  localStorage.setItem(key, value);
export const deleteLocalStorage = (key: LocalStorageType) =>
  localStorage.removeItem(key);
