import { useEffect, useState } from 'react';
import { Link, LoadingOverlay } from '../';
import { useDownloadURL } from '../../../hooks';

interface IProps {
  blob: Blob | undefined;
  fileName: string;
  isLoading?: boolean;
  text: string;
  allytmln?: string;
}

const BlobLink: React.FC<IProps> = (props) => {
  const { downloadBlob } = useDownloadURL();
  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  const onClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    if (!props.isLoading && props.blob) {
      downloadBlob(props.blob, props.fileName);
    } else {
      setIsWaiting(true);
    }
  };

  useEffect(() => {
    if (!props.isLoading && isWaiting) {
      downloadBlob(props.blob, props.fileName);
    }
    if (!props.isLoading) {
      setIsWaiting(false);
    }
  }, [props.isLoading]);

  return (
    <>
      <Link
        onClick={onClick}
        to='#'
        text={props.text}
        allytmln={props.allytmln}
        size='sm'
      />
      {isWaiting && <LoadingOverlay />}
    </>
  );
};

export default BlobLink;
