import { useEffect, useState } from 'react';
import { Alert as MetAlert, AlertProps, Box } from '@ally/metronome-ui';

type IProps = { show: boolean } & AlertProps;

const Alert: React.FC<IProps> = (props: IProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    setShowAlert(props.show);
  }, [props.show]);

  return (
    <>
      {showAlert && (
        <Box marginBottom='20px'>
          <MetAlert
            cancellable
            handleClose={() => setShowAlert(false)}
            {...props}
          />
        </Box>
      )}
    </>
  );
};

export default Alert;
