import { useContext, useState } from 'react';
import { AxiosResponse } from 'axios';
import { storeContext } from '../../store/store';
import {
  Appointment,
  Plaid,
  Relationship,
  RelationshipList,
  Timeslot,
  Timeslots,
  UpcomingAppointmentList,
  UpcomingAppointment,
  Esign,
} from '../../models';
import { useAxios } from '../index';
import { WorkType } from '../../types';

const version: String = 'v1';

const useCustomersService = () => {
  const { state, dispatch } = useContext(storeContext);
  const { investInstance } = useAxios('/customers-service');
  const [timeslots, setTimeslots] = useState<Timeslots>(new Timeslots(true));
  const getACMStatus = async (): Promise<RelationshipList> => {
    const relationshipList = new RelationshipList(true);
    dispatch({
      type: 'SET_RELATIONSHIPS',
      payload: relationshipList,
    });
    const { data }: AxiosResponse<{ relationshipList: Relationship[] }> =
      ((await investInstance(
        'GET',
        `/${version}/customers/accounts/relationships`
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    if (data) {
      relationshipList.setData(data.relationshipList);
    } else {
      relationshipList.setError();
    }
    dispatch({
      type: 'SET_RELATIONSHIPS',
      payload: relationshipList,
    });
    return relationshipList;
  };

  const getInstitutions = async () => {
    const { data: institutions }: AxiosResponse<any> =
      ((await investInstance(
        'GET',
        `/${version}/external-accounts/customer-institutions`
      ).catch((error: Error) => {})) as AxiosResponse) || {};

    dispatch({
      type: 'SET_INSTITUTIONS',
      payload: institutions,
    });
  };

  const postInstitutions = async (publicToken: string) => {
    const response: AxiosResponse<any> =
      ((await investInstance(
        'POST',
        `/${version}/external-accounts/institutions/access-token`,
        {
          data: { publicToken },
        }
      ).catch((error: Error) => {})) as AxiosResponse) || {};
  };

  const getLinkToken = async () => {
    let plaid: Plaid = new Plaid() && state.appCache.plaid;
    const {
      data: linkToken,
    }: AxiosResponse<{
      expiration: Date;
      linkToken: string;
    }> = ((await investInstance(
      'GET',
      `/${version}/external-accounts/institutions/link-token`
    ).catch((error: Error) => {})) as AxiosResponse) || {
      expiration: new Date(),
      linkToken: '',
    };
    plaid.linkToken = linkToken;
    dispatch({
      type: 'SET_PLAID',
      payload: plaid,
    });
    return linkToken;
  };

  /**
   *
   * @param appointmentType Will get timeslots only for a specific worktype, defaults to WEALTH_INTRO
   * @param resourceId Optional: Will get timeslots only for a specific advisor
   * @returns All timeslots for a specific date range and, if resourceId provided, a specific avisor.
   */
  const getApointmentTimeslots = async (
    appointmentType: WorkType = 'WEALTH_INTRO',
    startTime?: Date,
    endTime?: Date,
    resourceId?: string
  ) => {
    if (!timeslots.hasLoaded) {
      const tmpTimeslots = Object.assign(new Timeslots(true), timeslots);

      const params = new URLSearchParams();
      params.append('appointmentType', appointmentType);
      startTime && params.append('startTime', startTime.toString());
      endTime && params.append('endTime', endTime.toString());
      resourceId && params.append('resourceId', resourceId);

      const data: AxiosResponse<Timeslots> = (await investInstance(
        'GET',
        `/${version}/customers/appointments/timeslots?${params.toString()}`,
        { data: {} } // Need to include data or content-type won't be set correctly
      ).catch((error: Error) => {
        timeslots.setError();
      })) as AxiosResponse;
      if (data && data.data.timeslots?.length > 0) {
        // Since dates come back as strings you need to reset them to Dates
        const validTimeslots = data.data.timeslots.map((slot: Timeslot) =>
          Object.assign(slot, {
            startTime: new Date(slot.startTime),
            endTime: new Date(slot.endTime),
          })
        );
        data.data.timeslots = validTimeslots;
        tmpTimeslots.setData(data.data);
      } else {
        tmpTimeslots.setError();
      }
      setTimeslots(tmpTimeslots);
      return tmpTimeslots;
    }
    return timeslots;
  };

  /**
   * Removes the specified timeslot from timeslots. Use this if they have a timeslot conflict so
   * that you don't need to recall the get timeslots call since it takes awhile.
   * @param timeslot Timeslot to be removed
   * @returns Updated timeslots with the provided one removed
   */
  const removeTimeslot = (remove: Timeslot): Timeslots => {
    const tmpTimeslots = timeslots;
    tmpTimeslots.timeslots = timeslots.timeslots.filter(
      (timeslot: Timeslot) => timeslot !== remove
    );
    setTimeslots(tmpTimeslots);
    return tmpTimeslots;
  };

  // https://confluence.int.ally.com/display/WM/Appointment+Scheduler
  const bookAppointment = async (
    appointment: Appointment
  ): Promise<AxiosResponse<any>> => {
    const response: AxiosResponse = await investInstance(
      'POST',
      `/${version}/customers/appointments`,
      {
        data: appointment,
      }
    );
    return response;
  };

  const getAppointments = async () => {
    const upcomingAppointmentList: UpcomingAppointmentList = Object.assign(
      new UpcomingAppointmentList(),
      state.appCache.upcomingAppointmentList
    );
    if (!upcomingAppointmentList.shouldReload()) {
      return upcomingAppointmentList;
    }
    upcomingAppointmentList.setIsLoading();
    dispatch({
      type: 'SET_UPCOMING_APPOINTMENTS',
      payload: upcomingAppointmentList,
    });
    const { data }: AxiosResponse<UpcomingAppointment[]> =
      ((await investInstance(
        'GET',
        `/${version}/customers/appointments`
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    if (data) {
      upcomingAppointmentList.setData(data);
    } else {
      upcomingAppointmentList.setError();
    }
    dispatch({
      type: 'SET_UPCOMING_APPOINTMENTS',
      payload: upcomingAppointmentList,
    });
  };

  const getEsignStatus = async () => {
    const esign = new Esign(true);
    dispatch({
      type: 'SET_ESIGN_STATUS',
      payload: esign,
    });
    const { data }: AxiosResponse<Esign> =
      ((await investInstance('GET', `/${version}/customers/esign`).catch(
        (err: Error) => {
          esign.setIsLoading(false);
          esign.setError(err.message);
        }
      )) as AxiosResponse) || {};

    if (data) {
      esign.setData(data);
    } else {
      esign.setIsLoading(false);
      esign.setError('No data');
    }

    dispatch({
      type: 'SET_ESIGN_STATUS',
      payload: esign,
    });
  };

  return {
    getACMStatus,
    getInstitutions,
    postInstitutions,
    institutions: state.appCache.institutions
      ? state.appCache.institutions
      : [],
    getLinkToken,
    getApointmentTimeslots,
    timeslots,
    bookAppointment,
    removeTimeslot,
    getAppointments,
    upcomingAppointmentList: state.appCache.upcomingAppointmentList,
    upcomingAppointment: state.appCache.upcomingAppointmentList.upcomingAppointments.reduce(
      (acc: null | UpcomingAppointment, curr: UpcomingAppointment) => {
        const now = new Date();
        const date = new Date(curr.schedEndTime);
        if (date < now || (acc && date > new Date(acc.schedEndTime))) {
          return acc;
        } else {
          return curr;
        }
      },
      null
    ),
    getEsignStatus,
    esignStatus: state.appCache.esignStatus,
  };
};

export default useCustomersService;
