import { useMemo } from 'react';
import { CookieType } from '../../types';

const useCookie = () => {
  const getCookie = useMemo(
    () => (key: CookieType, defaultValue: string = ''): string => {
      const res = document.cookie.split(';').reduce((total, currentCookie) => {
        const item = currentCookie.split('=');
        // jwt is getting split with an extra space in front so need to trim it
        const storedKey = item[0].trim();
        const storedValue = item[1];
        return key === storedKey ? decodeURIComponent(storedValue) : total;
      }, defaultValue);
      return res;
    },
    []
  );

  // This does not seem to work?...
  const setCookie = useMemo(
    () => (key: CookieType, value: string, numberOfDays: number = 1) => {
      const now = new Date();
      now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
      document.cookie = `${key}=${value};expires=${now.toUTCString()};path=/`;
    },
    []
  );

  const clearCookies = () => {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
    });
  };

  return { getCookie, setCookie, clearCookies };
};

export default useCookie;
