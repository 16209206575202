import { useState, useEffect } from 'react';

const useSkipToMainManager = () => {
  const [skipToMainShown, setSkipToMainShown] = useState<boolean>(false);

  useEffect(() => {
    const foundMainContent: HTMLHeadingElement = document.getElementsByTagName(
      'h1'
    )[0];
    if (foundMainContent) {
      document.addEventListener('keydown', handleFirstKeyPress, { once: true });
    }
  }, []);

  function handleFirstKeyPress(e: KeyboardEvent) {
    // TODO: Which is deprecated, need to fix this
    if (e.which === 9) {
      setSkipToMainShown(true);
      document.addEventListener('keydown', handleSecondKeyPress, {
        once: true,
      });
    }
  }

  function handleSecondKeyPress(e: KeyboardEvent) {
    // TODO: Which is deprecated, need to fix this
    if (e.which !== 13) {
      setSkipToMainShown(false);
    }
  }

  function skipToMain() {
    document.getElementsByTagName('h1')[0].focus();
    setSkipToMainShown(false);
  }

  return { skipToMainShown, skipToMain };
};

export default useSkipToMainManager;
