import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const InsightsIcon: React.FC<IProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width='34px'
      height='26px'
      viewBox='0 0 34 26'
      version='1.1'
      aria-hidden={true}
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-60.000000, -329.000000)'>
          <g transform='translate(60.000000, 329.000000)'>
            <path
              d='M32.3057162,13.5 L25.5,13.5 L25.5,3 C25.5,1.61928813 24.3807119,0.5 23,0.5 L3,0.5 C1.61928813,0.5 0.5,1.61928813 0.5,3 L0.5,23 C0.5,24.3807119 1.61928813,25.5 3,25.5 L23,25.5 C24.3807119,25.5 25.5,24.3807119 25.5,23 L25.5,19.422555 L32.3057162,13.5 Z'
              stroke='#DDDDDD'
              fill='#FFFFFF'
            />
            <path
              d='M10.287544,5.15416226 C5.88309019,6.08644635 2.41818895,11.1599267 4.74803634,16.4473192 C6.1509441,19.4275905 9.06970315,20.89057 11.1343339,21 C11.9553244,20.1028118 14.5249332,17.3611923 15.3790615,16.4323603 C11.9299838,16.4323603 11.2131077,16.5196972 10.4604566,16.1077524 C7.82262307,14.6645646 7.78524277,10.8628201 10.949267,9.71581841 C12.778379,9.14277783 15.4027968,10.2760864 15.4027968,13.2685549 C15.4027968,14.3561813 15.4299721,13.2486481 15.3858266,16.4323603 L15.3858266,20.6878195 L20,20.6878195 L20,12.8911306 C20,8.52313456 15.9646479,4.11359882 10.287544,5.15416226'
              fill='#650360'
            />
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

interface IProps {}

export default InsightsIcon;
