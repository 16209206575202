import { Button, TextBody, useModalContext } from '@ally/metronome-ui';
import { Modal } from '../../molecules';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  ${({ theme: { colors } }): string => `
    color: ${colors['sky-3']};
  `}
`;

const PaddedTextBody = styled(TextBody)`
  padding-bottom: 20px;
`;

interface IProps {}

const FullPictureModal: React.FC<IProps> = (props: IProps) => {
  const context = useModalContext();
  const { showModal } = context;

  return (
    <Modal
      context={context}
      heading='Get the full picture.'
      headingProps={{ fontSize: '1.6rem' }}
      content={
        <>
          <PaddedTextBody tag='p' size='sm'>
            Select <b>add new account</b> to easily and securely link pretty
            much any type of domestic financial account to your Wealth Overview.
          </PaddedTextBody>
          <TextBody tag='p' size='sm'>
            Some institutions or account types may have extra security hoops to
            jump through on their end. Your advisor will be able to help if you
            ever need support getting linked up.
          </TextBody>
        </>
      }
      trigger={
        <StyledButton variant='link' text='Need help?' onClick={showModal} />
      }
      width='md'
    />
  );
};

export default FullPictureModal;
