import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './GlobalStyle';
import { MetronomeProvider } from '@ally/metronome-ui';
import { ScrollToTop } from './components/atoms';
import {
  useAppCache,
  useConfig,
  useCustomersService,
  useEventListener,
  useScript,
  useSession,
  useWealthController,
  useLogRocket,
} from './hooks';
import { useEffect } from 'react';
import { Routes } from './components/organisms';
import { ViewportProvider } from './contexts';
import allytm from '@allysf/allytm';
import { muiAllyTheme } from './utils';

function App() {
  const { hydrateClient } = useWealthController();
  const { getACMStatus } = useCustomersService();
  const { isLoggedIn, session, logout, getLocalStorage } = useSession(true);
  const { orionConfig, allyTmUrl } = useConfig();
  const { loadScript } = useScript(
    `${orionConfig.baseUrl}/widgets/commons.bundle.js`,
    'orion-common'
  );
  const allyTmScript = useScript(allyTmUrl, 'ally-tm');
  const { appCache, getRelationship } = useAppCache();
  const { initializeLogRocket, LogRocket } = useLogRocket();
  const { addListener } = useEventListener();

  useEffect(() => {
    initializeLogRocket();
    allyTmScript.loadScript();
    allytm();
    loadScript();
    // Trigger to log a user off if they log off from another tab
    const logoutListener = (): void => {
      if (getLocalStorage('logout')) {
        logout();
      }
    };
    addListener('storage', logoutListener);
  }, []);

  useEffect(() => {
    LogRocket.identify(session.username, {
      sviUserId: session.sviuserid,
    });
    LogRocket.getSessionURL((sessionURL: string) => {
      if (window.allytm && window.allytm.ddo) {
        window.allytm.ddo.set('LogRocket.sessionURL', sessionURL);
      }
    });

    if (
      isLoggedIn &&
      !appCache.relationshipList.hasLoaded &&
      !appCache.relationshipList.isLoading
    ) {
      const checkACMStatus = async () => {
        const relationshipList = await getACMStatus();
        if (getRelationship('WEALTH', relationshipList) && session.username) {
          hydrateClient();
        }
      };
      checkACMStatus();
    }
  }, [isLoggedIn]);

  return (
    <>
      <GlobalStyle />
      <ViewportProvider>
        <MetronomeProvider theme={muiAllyTheme}>
          <Router>
            <ScrollToTop />
            <Routes />
          </Router>
        </MetronomeProvider>
      </ViewportProvider>
    </>
  );
}

export default App;
