import React, { Suspense, useEffect, useState } from 'react';
import DefaultTemplate from '../../templates/DefaultTemplate';
import Loader from '../../atoms/Loader';
import {
  FinancialPictureWidget,
  PendingNotificationBanner,
} from '../../organisms';
import {
  useWealthController,
  useAppCache,
  useAllyTm,
  useNotifications,
  useDocumentsController,
  useCustomersService,
} from '../../../hooks';
import { Alert } from '../../molecules';

const WealthOverviewWidget = React.lazy(
  () => import('../../organisms/WealthOverviewWidget')
);

const AssetsUnderCareWidget = React.lazy(
  () => import('../../organisms/AssetsUnderCareWidget')
);

const AllocationWidget = React.lazy(
  () => import('../../organisms/AllocationWidget')
);

const GoalRoadmapWidget = React.lazy(
  () => import('../../organisms/GoalRoadmapWidget')
);

interface IProps {}

const WealthOverviewPage: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const {
    goalsSummary,
    wealthOverview,
    getWealthOverview,
    getGoalsSummary,
    getAccounts,
    getNetWorthDetails,
  } = useWealthController();
  const { getAppointments } = useCustomersService();
  const { getPendingAccounts } = useNotifications();
  const { isProspect, isFundedWealth, isFundedBrokerage } = useAppCache();
  const { getProposals, getQPRS } = useDocumentsController();
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    allyTmEvent('pageview', 'Wealth:Wealth Overview');
    getAccounts();
    getGoalsSummary();
    getProposals();
    getQPRS();
    getPendingAccounts();
    getNetWorthDetails();
    getWealthOverview();
    getAppointments();
  }, []);

  return (
    <DefaultTemplate>
      <Suspense fallback={<></>}>
        <Alert
          show={!!wealthOverview.error}
          variant='error'
          heading="Your money's there, but your info isn't."
          body='Hold tight while we fix an issue on our end and get your Wealth Overview back up and running.'
        />
        <PendingNotificationBanner />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <WealthOverviewWidget
          refreshWidgets={() => {
            setRefresh(refresh + 1);
          }}
        />
      </Suspense>
      {!isProspect && isFundedWealth && (
        <Suspense fallback={<Loader />}>
          <AssetsUnderCareWidget refresh={refresh} />
        </Suspense>
      )}
      {(isFundedBrokerage || isFundedWealth) && (
        <Suspense fallback={<Loader />}>
          <AllocationWidget refresh={refresh} />
        </Suspense>
      )}
      {goalsSummary.goals.length > 0 && (
        <Suspense fallback={<Loader />}>
          <GoalRoadmapWidget />
        </Suspense>
      )}
      {goalsSummary.goals.length === 0 && (
        <Suspense fallback={<Loader />}>
          <FinancialPictureWidget />
        </Suspense>
      )}
    </DefaultTemplate>
  );
};

export default WealthOverviewPage;
