import { createRef } from 'react';
import { Box, TextBody } from '@ally/metronome-ui';
import { Drawer } from '../../molecules';
import { WealthAccount } from '../../../models';
import { Text, DL } from '../../atoms';
import { useAllyTm } from '../../../hooks';

interface IProps {
  account: WealthAccount;
  isLoading: boolean;
}

const AccountDetailsDrawer: React.FC<IProps> = ({
  account,
  isLoading,
}: IProps) => {
  const focusRef = createRef<HTMLElement>();
  const { allyTmEvent } = useAllyTm();

  const onOpen = () => {
    allyTmEvent('clickEvent', {
      tagName: 'button',
      allytmln: 'openAccountDetailsDrawer',
      href: account.accountId,
    });
  };

  const onClose = () => {
    allyTmEvent('clickEvent', {
      tagName: 'button',
      allytmln: 'closeAccountDetailsDrawer',
      href: account.accountId,
    });
  };

  return (
    <Drawer
      title='Account Details'
      focusRef={focusRef}
      onOpen={onOpen}
      onClose={onClose}
      contents={
        <Box
          display='flex'
          flexDirection={['column', 'column', 'column', 'row']}
        >
          <Box width={['100%', '100%', '520px']} paddingBottom='10px'>
            <DL>
              <TextBody
                tag='dt'
                size='xs'
                weight='bold'
                ref={focusRef}
                tabIndex={-1}
              >
                PORTFOLIO TYPE
              </TextBody>
              <Text tag='dd' size='md' isLoading={isLoading}>
                {account.model}
              </Text>
            </DL>
          </Box>
          <Box
            width={['100%', '100%', '400px']}
            paddingX={['0', '0', '0', '40px']}
            paddingBottom='10px'
          >
            <DL>
              <TextBody tag='dt' size='xs' weight='bold'>
                MANAGEMENT STYLE
              </TextBody>
              <Text tag='dd' size='md' isLoading={isLoading}>
                {account.managementStyle}
              </Text>
            </DL>
          </Box>
          <Box paddingBottom='10px' width='100%'>
            <DL>
              <TextBody tag='dt' size='xs' weight='bold'>
                PORTFOLIO DESCRIPTION
              </TextBody>
              <Text tag='dd' size='md' isLoading={isLoading}>
                {account.portfolioDescription}
              </Text>
            </DL>
          </Box>
        </Box>
      }
    />
  );
};

export default AccountDetailsDrawer;
