import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { Relationship } from '.';

interface IRelationshipList extends IAPIData {
  relationships: Relationship[];
}

class RelationshipList extends APIData implements IRelationshipList {
  relationships: Relationship[] = [];

  setData(relationships: Relationship[]) {
    this.relationships = relationships || [];
    this.setLoaded();
  }
}

export { RelationshipList };
export type { IRelationshipList };
