import { useEffect, useState } from 'react';
import { TextBody, Box } from '@ally/metronome-ui';
import { LoadingSpinner } from '../../atoms/';
import {
  useScript,
  useOrionWidget,
  useConfig,
  useSession,
} from '../../../hooks';
import { OrionEventType } from '../../../types';
import styled from 'styled-components';

const StyledTextBody = styled(TextBody)`
  display: inline-block;
  vertical-align: middle;
`;

interface IProps {
  type: string;
  widgetId: string;
  options?: any;
  refresh?: number;
}

interface OrionWidgetEvent extends Event {
  detail: {
    name: string;
  };
}

const OrionWidget: React.FC<IProps> = (props) => {
  const { orionConfig } = useConfig();
  const { orionToken, refreshSessionTimer } = useSession();

  const WidgetTypes: any = {
    performance: {
      script: `${orionConfig.baseUrl}/widgets/performanceChart.bundle.js`,
      widgetName: 'performanceChart',
      errorState: 'performance',
    },
    performanceSummary: {
      script: `${orionConfig.baseUrl}/widgets/performanceSummary.bundle.js`,
      widgetName: 'performanceSummary',
      errorState: 'performance',
    },
    allocation: {
      script: `${orionConfig.baseUrl}/widgets/allocationChart.bundle.js`,
      widgetName: 'allocationChart',
      errorState: 'allocation',
    },
    positions: {
      script: `${orionConfig.baseUrl}/widgets/positions.bundle.js`,
      widgetName: 'positions',
      errorState: 'positions',
    },
    retirement: {
      script: `${orionConfig.baseUrl}/widgets/retirementChart.bundle.js`,
      widgetName: 'retirementChart',
      errorState: 'retirement',
    },
    transactions: {
      script: `${orionConfig.baseUrl}/widgets/transactions.bundle.js`,
      widgetName: 'transactions',
      errorState: 'transactions',
    },
  };

  const { scriptLoaded, loadScript } = useScript(
    WidgetTypes[props.type].script,
    'script-' + WidgetTypes[props.type].widgetName
  );
  const { loadWidget, widgetStatus } = useOrionWidget(
    WidgetTypes[props.type].widgetName,
    props.widgetId,
    props.options
  );

  // initial check if the widget is loaded or not
  const [widgetLoaded, setWidgetLoaded] = useState<boolean>(
    !!window?.OrionWidgets?.[WidgetTypes[props.type].widgetName]
  );

  // Initial useEffect to load all scripts
  useEffect(() => {
    loadScript();
  }, [loadScript]);

  // Additional useEffect to run once the scriptLoaded value has changed
  useEffect(() => {
    if (
      scriptLoaded &&
      widgetLoaded &&
      window.OrionWidgets &&
      window.OrionWidgets[WidgetTypes[props.type].widgetName]
    ) {
      loadWidget();
      refreshSessionTimer();
    }
  }, [
    scriptLoaded,
    widgetLoaded,
    orionToken.token,
    props.options,
    props.refresh,
    window.OrionWidgets,
  ]);

  // Handle event when widget is loaded successfully
  const handleWidgetLoad = (event: OrionWidgetEvent) => {
    if (event.detail.name === WidgetTypes[props.type].widgetName) {
      setWidgetLoaded(true);
    }
  };

  useEffect(() => {
    window.addEventListener(OrionEventType, handleWidgetLoad as EventListener);
    return () =>
      window.removeEventListener(
        OrionEventType,
        handleWidgetLoad as EventListener
      );
  }, []);

  return (
    <>
      <LoadingSpinner
        color='white'
        height='300px'
        isLoading={widgetStatus === 'unitialized' || widgetStatus === 'loading'}
        isSlowMessage={`We're still working on bringing in your ${
          WidgetTypes[props.type].errorState
        } details. Hold tight.`}
      >
        {widgetStatus !== 'error' && props.children}
      </LoadingSpinner>
      <Box
        id={props.widgetId}
        display={widgetStatus === 'error' ? 'none' : 'block'}
      />
      {widgetStatus === 'error' && (
        <Box lineHeight='300px' textAlign='center'>
          <StyledTextBody tag='p' size='sm'>
            We can't bring in your {WidgetTypes[props.type].errorState} details
            right now.
          </StyledTextBody>
        </Box>
      )}
    </>
  );
};

export default OrionWidget;
