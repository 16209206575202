import { useContext, useState } from 'react';
import { storeContext } from '../../store/store';
import { useCustomersService, useWealthController } from '../index';

interface IPlaid {
  version: string;
  create: Function;
}

declare global {
  interface Window {
    Plaid: any;
  }
}

interface IPlaidHandler {
  exit: Function;
  open: Function;
  destroy: Function;
}

const usePlaid = () => {
  const { state } = useContext(storeContext);
  const [handler, setPlaidHandler] = useState<IPlaidHandler | null>(null);
  const [pollingStatus, setPollingStatus] = useState<
    '' | 'polling' | 'success' | 'failed'
  >('');
  const { getLinkToken, postInstitutions } = useCustomersService();
  const { netWorthDetails, getNetWorthDetails } = useWealthController();

  const createPlaidHandler = async (
    focusEl: HTMLElement | undefined,
    successCallback: Function
  ) => {
    if (window.hasOwnProperty('Plaid')) {
      const { linkToken } = state.appCache.plaid.linkToken.linkToken
        ? state.appCache.plaid.linkToken
        : (await getLinkToken()) || {};
      const Plaid: IPlaid = window.Plaid;
      const tmpHandler = Plaid.create({
        token: linkToken,
        onLoad: () => {},
        onSuccess: async (public_token: string, metadata: Object) => {
          await postInstitutions(public_token);
          successCallback();
          tmpHandler.destroy();
        },
        onExit: (err: Error, metadata: Object): void => {
          if (focusEl) {
            focusEl.focus();
          }
          tmpHandler.destroy();
        },
        onEvent: (eventName: string, metadata: Object): void => {},
      });
      setPlaidHandler(tmpHandler);
    }
  };

  /**
   * To be used when you add new accounts with plaid. Will poll the orion /networth-details endpoint
   * to see when new accounts have propogated from Plain through Orion
   */
  const startPolling = (): void => {
    setPollingStatus('polling');
    let attempts = 0;
    const netWorthAccountLength = Object.values({
      ...netWorthDetails.assets,
      ...netWorthDetails.liabilities,
    }).flat().length;
    const interval = setInterval(async () => {
      attempts++;
      const tmpNetWorthDetails = await getNetWorthDetails();
      if (
        netWorthAccountLength !==
        Object.values({
          ...tmpNetWorthDetails.assets,
          ...tmpNetWorthDetails.liabilities,
        }).flat().length
      ) {
        clearInterval(interval);
        setPollingStatus('success');
      } else if (attempts >= 5) {
        clearInterval(interval);
        setPollingStatus('failed');
      }
    }, 5000);
  };

  return {
    handler,
    createPlaidHandler,
    startPolling,
    pollingStatus,
  };
};

export default usePlaid;
