import { useContext } from 'react';
import { storeContext } from '../../store/store';
import { NetWorthAccount, Relationship, RelationshipList } from '../../models';
import {
  RelationshipType,
  EquityAssetAccountClass,
  EquityAssetAccountClasses,
} from '../../types';

const useAppCache = () => {
  const { state } = useContext(storeContext);
  const wealthRelationship = state.appCache.relationshipList.relationships.find(
    ({ relationship }) => relationship === 'WEALTH'
  );

  const isProspect = (): boolean => {
    return !!(
      wealthRelationship &&
      wealthRelationship.relationshipInd === 'YES' &&
      wealthRelationship.relationshipStatus === 'PROSPECT'
    );
  };

  /**
   * return a boolean value to determine whether a user has a funded wealth account
   * @returns boolean
   */
  const isFundedWealth = (): boolean =>
    !!state.appCache.relationshipList.relationships.find(
      (relationship: Relationship) =>
        relationship.relationship === 'WEALTH' &&
        relationship.relationshipStatus === 'CLIENT_FUNDED'
    );

  /**
   * return a boolean value to determine whether a user has any funded brokerage accounts
   * @returns boolean
   */
  const isFundedBrokerage = (): boolean =>
    Object.values(state.appCache.netWorthDetails.assets)
      .flat()
      ?.some(
        (account: NetWorthAccount) =>
          isEquityAssetAccountClass(account.type) && account.balance > 0
      );

  /**
   * private helper function to check for type being an EquityAssetAccountClass type
   * @param {any} accountType
   * @returns {boolean}
   */
  const isEquityAssetAccountClass = (
    accountType: any
  ): accountType is EquityAssetAccountClass =>
    EquityAssetAccountClasses.includes(accountType);

  /**
   *
   * @param type RelationshipType: a RelationshipType value for the type of account you are seeking data for
   * @param relationshipList RelationshipList: Optional relationshipList if you want to use a different list than what's in state
   * @returns Relationship: an object of the relationship where the relationship type is equal to the supplied type
   */
  const getRelationship = (
    type: RelationshipType,
    relationshipList?: RelationshipList
  ): Relationship | undefined => {
    const relationships =
      relationshipList?.relationships ||
      state.appCache.relationshipList.relationships;
    return relationships.find(
      (relationship: Relationship) =>
        relationship.relationship === type.toUpperCase()
    );
  };

  return {
    appCache: state.appCache,
    isProspect: isProspect(),
    isFundedWealth: isFundedWealth(),
    isFundedBrokerage: isFundedBrokerage(),
    assetsUnderCare: state.appCache.wealthOverview.assetsUnderCare,
    assetsUnderCarePercentage:
      state.appCache.wealthOverview.assetsUnderCarePercentage,
    getRelationship,
    isWealthRelationship: !!wealthRelationship,
  };
};

export default useAppCache;
