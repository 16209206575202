import { Box, Space, TextBody, useTableDrawer } from '@ally/metronome-ui';
import styled from 'styled-components';
import { useAccountNumberMask, useFormatter } from '../../../hooks';
import { NetWorthAccount } from '../../../models';
import { AccountTypesName } from '../../../types';
import { muiAllyTheme } from '../../../utils';
import { Currency } from '../../atoms';

interface AccountInfo extends NetWorthAccount {
  accountNumber?: string;
}

const StyledBox = styled(Box)`
  border-bottom: 1px solid ${muiAllyTheme.colors['slate-2']};
  padding-bottom: 14px;
  margin-bottom: 14px;
  line-height: normal;
  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const StyledTextBody = styled(TextBody)`
  font-size: 0.8rem;
`;

const StyledBoldSpan = styled.span`
  font-size: 0.93333rem;
  font-weight: bold;
  color: ${muiAllyTheme.colors['slate-5']};
`;

const StyledDateSpan = styled.span`
  font-size: 0.8rem;
  color: ${muiAllyTheme.colors['slate-5']};
`;

interface IProps {}

const NetWorthAccountsTableDrawer: React.FC<IProps> = (props: IProps) => {
  const {
    row: {
      original: { accounts, name },
    },
  } = useTableDrawer();
  const { dateTimeFormatter, getTimezone } = useFormatter();
  const { maskAccountNumber } = useAccountNumberMask();

  return (
    <Box marginY='15px' marginLeft='24px' marginRight='40px'>
      {accounts.map(
        ({
          accountId,
          institutionName,
          nickName,
          lastUpdatedDate,
          balance,
          accountNumber,
        }: AccountInfo) => (
          <StyledBox key={accountId}>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
            >
              <Box
                display='flex'
                flexDirection='column'
                flexShrink={1}
                flexWrap='wrap'
              >
                <StyledBoldSpan>{nickName}</StyledBoldSpan>
                <Space marginTop={[0, 0, '2px', 0]}>
                  <TextBody tag='span' size='md' data-private>
                    {institutionName}&nbsp;
                    {accountNumber && maskAccountNumber(accountNumber)}
                  </TextBody>
                </Space>
              </Box>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='flex-end'
                flexShrink={0}
              >
                <StyledBoldSpan>
                  <Currency value={balance} />
                </StyledBoldSpan>
                <StyledDateSpan>
                  Updated {dateTimeFormatter(lastUpdatedDate, true)}{' '}
                  {getTimezone(lastUpdatedDate)}
                </StyledDateSpan>
              </Box>
            </Box>
          </StyledBox>
        )
      )}
      {name.includes(AccountTypesName.savings) && (
        <StyledBox textAlign='center'>
          <StyledTextBody tag='p' size='sm'>
            Keep in mind, investment accounts are not FDIC-insured, not bank
            guaranteed, and may lose value.
          </StyledTextBody>
        </StyledBox>
      )}
    </Box>
  );
};

export default NetWorthAccountsTableDrawer;
