import styled from 'styled-components';

const StyledLI = styled.li`
  outline: none;
  list-style: none;
`;

interface IProps
  extends React.DetailedHTMLProps<
    React.LiHTMLAttributes<HTMLLIElement>,
    HTMLLIElement
  > {
  focusRef?: React.RefObject<HTMLLIElement>;
}

const LI: React.FC<IProps> = (props) => {
  return (
    <StyledLI
      {...props}
      ref={props.focusRef}
      tabIndex={props.focusRef ? -1 : undefined}
    >
      {props.children}
    </StyledLI>
  );
};

export default LI;
