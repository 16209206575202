import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const NetWorthIcon: React.FC<IProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='72px'
      viewBox='0 0 183 72'
      version='1.1'
      aria-hidden={true}
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-184.000000, -610.000000)'>
          <g transform='translate(185.000000, 610.000000)'>
            <path
              d='M11,32 L32,32 C34.7614237,32 37,34.2385763 37,37 L37,59 L37,59 L6,59 L6,37 C6,34.2385763 8.23857625,32 11,32 Z'
              fill='#9D73D9'
            ></path>
            <path
              d='M47,26 L67,26 C69.7614237,26 72,28.2385763 72,31 L72,59 L72,59 L42,59 L42,31 C42,28.2385763 44.2385763,26 47,26 Z'
              fill='#9D73D9'
            ></path>
            <path
              d='M116,14 L136,14 C138.761424,14 141,16.2385763 141,19 L141,59 L141,59 L111,59 L111,19 C111,16.2385763 113.238576,14 116,14 Z'
              fill='#9D73D9'
            ></path>
            <path
              d='M81,0 L101,0 C103.761424,-5.07265313e-16 106,2.23857625 106,5 L106,59 L106,59 L76,59 L76,5 C76,2.23857625 78.2385763,5.07265313e-16 81,0 Z'
              fill='#9D73D9'
            ></path>
            <path
              d='M152,9 L172,9 C174.761424,9 177,11.2385763 177,14 L177,59 L177,59 L147,59 L147,14 C147,11.2385763 149.238576,9 152,9 Z'
              fill='#9D73D9'
            ></path>
            <path
              d='M151,61 L173,61 C175.209139,61 177,62.790861 177,65 L177,69 L177,69 L147,69 L147,65 C147,62.790861 148.790861,61 151,61 Z'
              fill='#C0C8D6'
              transform='translate(162.000000, 65.000000) rotate(-180.000000) translate(-162.000000, -65.000000) '
            ></path>
            <path
              d='M115,61 L137,61 C139.209139,61 141,62.790861 141,65 L141,71 L141,71 L111,71 L111,65 C111,62.790861 112.790861,61 115,61 Z'
              fill='#C0C8D6'
              transform='translate(126.000000, 66.000000) rotate(-180.000000) translate(-126.000000, -66.000000) '
            ></path>
            <path
              d='M46,61 L68,61 C70.209139,61 72,62.790861 72,65 L72,72 L72,72 L42,72 L42,65 C42,62.790861 43.790861,61 46,61 Z'
              fill='#C0C8D6'
              transform='translate(57.000000, 66.500000) rotate(-180.000000) translate(-57.000000, -66.500000) '
            ></path>
            <path
              d='M10,61 L33,61 C35.209139,61 37,62.790861 37,65 L37,71 L37,71 L6,71 L6,65 C6,62.790861 7.790861,61 10,61 Z'
              fill='#C0C8D6'
              transform='translate(21.500000, 66.000000) rotate(-180.000000) translate(-21.500000, -66.000000) '
            ></path>
            <path
              d='M80,61 L102,61 C104.209139,61 106,62.790861 106,65 L106,70 L106,70 L76,70 L76,65 C76,62.790861 77.790861,61 80,61 Z'
              fill='#C0C8D6'
              transform='translate(91.000000, 65.500000) rotate(-180.000000) translate(-91.000000, -65.500000) '
            ></path>
            <path
              d='M0,48 C8.81209806,39.9710009 15.8149044,37.0507635 21.0084191,39.2392878 C28.7986911,42.5220742 29.4591192,42.5220742 38.8543487,42.5220742 C48.2495782,42.5220742 57.2546007,35.9565013 65.2772257,29.7192071 C73.2998507,23.4819128 81.1561239,5.76749473 90.5081219,7.06798051 C99.8601198,8.36846629 110.587435,27.5011379 125.793905,27.5011379 C141.000374,27.5011379 147.932876,16.2597826 160.834899,16.2597826 C169.436248,16.2597826 176.157949,19.542569 181,26.108142'
              stroke='#650360'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeDasharray='8,5'
            ></path>
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

interface IProps {}

export default NetWorthIcon;
