import { useContext, useEffect } from 'react';
import { TextHeading, TextBody } from '@ally/metronome-ui';
import styled from 'styled-components';

import { useConfig, useSession } from '../../../hooks';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { LoadingSpinner } from '../../atoms';
import { muiAllyTheme } from '../../../utils';
import { storeContext } from '../../../store/store';

const StyledTextHeading = styled(TextHeading)`
  color: ${muiAllyTheme.colors.plum};
`;

interface IProps {}

const LogoutPage: React.FC<IProps> = (props) => {
  const { clearSession, isLoggedIn } = useSession();
  const { logoutUrl } = useConfig();
  const { state, dispatch } = useContext(storeContext);

  useEffect(() => {
    if (!state.appCache.processingLogout) {
      dispatch({ type: 'BEGIN_LOGOUT', payload: true });
      clearSession();
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      window.location.href = logoutUrl;
    }
  }, [isLoggedIn]);

  return (
    <DefaultTemplate hideSubNav>
      <StyledTextHeading tag='h1' size='md'>
        Logging out
      </StyledTextHeading>
      <TextBody tag='strong' size='md'>
        Please Wait...
      </TextBody>
      <LoadingSpinner isLoading color='white' />
    </DefaultTemplate>
  );
};

export default LogoutPage;
