import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
body {
  margin: 0;
  height: 100vh;
  box-sizing: border-box;
  font-family: Lato,helvetica,arial,sans-serif;
  font-size: 15px;
  overflow-x: hidden;
}

* {
  font-family: Lato,helvetica,arial,sans-serif;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

html {
  font-size: 15px;
}

body input {
  overflow: visible;
}

body ::before,body ::after{
  background-repeat:no-repeat;
  box-sizing:border-box
}

body input {
  margin: 0;
}

body input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
`;

export default GlobalStyle;
