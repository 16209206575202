import { useMemo } from 'react';
import { muiCalendar } from '@ally/metronome-icons';
import { Box } from '@ally/metronome-ui';
import { useFormatter } from '../../../hooks';
import { Link, Text } from '../../atoms';
import { muiAllyTheme } from '../../../utils';
import { UpcomingAppointment as UpcomingAppointmentModel } from '../../../models';
import { AdvisorSummary, ZoomLink } from '..';
import { IconRow } from '../../molecules';

interface IProps {
  appointment: UpcomingAppointmentModel;
}

const UpcomingAppointment: React.FC<IProps> = ({ appointment }) => {
  const { getAppointmentDateTime } = useFormatter();
  const dateTime = useMemo(
    () => getAppointmentDateTime(appointment.schedStartTime),
    [appointment.schedStartTime]
  );

  return (
    <>
      {appointment.workType !== 'WEALTH_INTRO' &&
        !!appointment.resources.length && (
          <AdvisorSummary advisor={appointment.resources[0]} />
        )}
      <Text tag='strong' size='md' weight='bold'>
        Upcoming Meetings
      </Text>
      <Box paddingBottom='10px' paddingTop='5px'>
        <IconRow icon={muiCalendar}>
          <Text tag='p' size='sm'>
            <Text tag='b' size='sm' weight='bold'>
              {appointment.shortName}
            </Text>
            <Text tag='span' size='sm'>
              {dateTime}
            </Text>
          </Text>
        </IconRow>
        <IconRow>
          <Text tag='span' size='sm'>
            Meeting passcode:
          </Text>
          <Text tag='b' size='sm' weight='bold' keepSpace>
            {` ${appointment.zoomPwd}`}
          </Text>
        </IconRow>
        <IconRow>
          <ZoomLink link={appointment.zoomLink} />
        </IconRow>
      </Box>
      <Text tag='p' size='sm'>
        {appointment.workType === 'WEALTH_INTRO' ? (
          <>
            If you want to reschedule or cancel a meeting, call us at{' '}
            <Link
              text='1-855-880-2559'
              aria-label='Call us at 1-855-880-2559'
              to='tel:+1-855-880-2559'
              color={muiAllyTheme.colors['gray-900']}
            />
            .
          </>
        ) : (
          'To reschedule or cancel a meeting, call or email your advisor.'
        )}
      </Text>
    </>
  );
};

export default UpcomingAppointment;
