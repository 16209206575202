import styled from 'styled-components';
import { DropdownMenu, Box, useDropdownContext } from '@ally/metronome-ui';
import { DropDownLink, LI } from '../../atoms';
import { AccountListNav } from '../../molecules';
import { INavLinkDetail } from '../../../models';
import { ISubLink } from '../../../interfaces';
import { muiAllyTheme } from '../../../utils';
import { useTransmit } from '../../../hooks';

const StyledBox = styled(Box)`
  -webkit-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
`;

const StyledMenuItemLink = styled(DropdownMenu.MenuItemLink)`
  & > div {
    padding: 10px;
  }
  color: ${muiAllyTheme.colors.bluesuedeshoes};
  width: 380px;
  font-weight: 700;
`;

interface IProps {
  navItem: INavLinkDetail;
}

const MainNavMenu: React.FC<IProps> = (props) => {
  const { leaveWithTicket } = useTransmit();
  const context = useDropdownContext();

  return (
    <LI>
      <DropdownMenu
        smallCaret={true}
        topPos={34}
        context={context}
        variant='custom'
      >
        <DropdownMenu.Trigger
          ariaLabel={(isOpen: boolean) => `${props.navItem.title} menu`}
          content={
            <DropDownLink type='nav'>{props.navItem.title}</DropDownLink>
          }
        />
        <DropdownMenu.MenuContainer>
          {!!props.navItem.subLinks &&
            props.navItem.subLinks!.map((subNavItem: ISubLink) => {
              return subNavItem.subComponent === 'AccountListNav' ? (
                <StyledBox
                  maxHeight='264px'
                  overflowY='auto'
                  key={subNavItem.title}
                  width={['224px', '224px', '224px', '380px']}
                >
                  <AccountListNav closeMenu={context.closeMenu} />
                </StyledBox>
              ) : (
                <div
                  key={subNavItem.title}
                  onClick={
                    subNavItem.transmitdestination
                      ? (e) => leaveWithTicket(e, subNavItem.link)
                      : undefined
                  }
                >
                  <StyledMenuItemLink
                    to={subNavItem.transmitdestination ? '#' : subNavItem.link}
                    content={subNavItem.title}
                    key={subNavItem.title}
                  />
                </div>
              );
            })}
        </DropdownMenu.MenuContainer>
      </DropdownMenu>
    </LI>
  );
};

export default MainNavMenu;
