import { useConfig } from '../';
import { INavLinkDetail } from '../../models';

const useNav = () => {
  const { investConfig } = useConfig();
  const navLinkList: {
    mainNav: INavLinkDetail[];
    subNav: INavLinkDetail[];
    sideNav: INavLinkDetail[];
  } = {
    mainNav: [
      {
        title: 'Accounts',
        subLinks: [
          {
            title: 'View Snapshot',
            link: `${investConfig.svcproviderUrl}/serviceprovider/ssoToAlly?relayState=${investConfig.bankUrl}/dashboard`,
            transmitdestination: 'bank_react',
          },
          {
            title: 'AccountList',
            link: '',
            subComponent: 'AccountListNav',
          },
          {
            title: 'Explore Products and Rates',
            link: `${investConfig.svcproviderUrl}/serviceprovider/ssoToAlly?relayState=${investConfig.bankUrl}/products-and-rates`,
            transmitdestination: 'bank_react',
          },
        ],
      },
      {
        title: 'Transfers',
        link: `${investConfig.investUrl}/transfer`,
        transmitdestination: 'invest',
      },
      {
        title: 'Investments',
        link: `${investConfig.investUrl}/dashboard`,
        transmitdestination: 'invest',
      },
      {
        title: 'More',
        subLinks: [
          {
            title: 'Statements & Tax Forms',
            link: `${investConfig.investUrl}/accounts/activity-documents/documents/tax-forms`,
            transmitdestination: 'invest',
          },
          {
            title: 'Profile & Settings',
            link: `${investConfig.svcproviderUrl}/serviceprovider/ssoToAlly?relayState=${investConfig.bankUrl}/profile`,
            transmitdestination: 'bank_react',
          },
          {
            title: 'Beneficiaries',
            link: `${investConfig.svcproviderUrl}/serviceprovider/ssoToAlly?relayState=${investConfig.bankUrl}/beneficiaries/invest`,
            transmitdestination: 'bank_react',
          },
          {
            title: 'Forms',
            link: `${investConfig.svcproviderUrl}/serviceprovider/ssoToAlly?relayState=${investConfig.bankUrl}/bank/documents-and-forms`,
            transmitdestination: 'bank_react',
          },
        ],
      },
    ],
    subNav: [
      {
        title: 'Wealth Overview',
        link: '/wealth-overview',
      },
      {
        title: 'Net Worth Details',
        link: '/net-worth-details',
      },
      {
        title: 'Transfers',
        link: `${investConfig.investUrl}/transfer`,
        transmitdestination: 'invest',
      },
      {
        title: 'Documents and Tools',
        subLinks: [
          {
            title: 'Documents',
            link: `${investConfig.investUrl}/accounts/documents`,
            transmitdestination: 'invest',
          },
          {
            title: 'Document Upload',
            link: `${investConfig.investUrl}/document-upload`,
            transmitdestination: 'invest',
          },
          {
            title: 'Tax Center',
            link: `${investConfig.investUrl}/tax-center`,
            transmitdestination: 'invest',
          },
        ],
      },
    ],
    sideNav: [
      {
        title: 'View Snapshot',
        link: `${investConfig.svcproviderUrl}/serviceprovider/ssoToAlly?relayState=${investConfig.bankUrl}/dashboard`,
        isMainNav: true,
        transmitdestination: 'bank_react',
      },
      {
        title: 'Wealth Overview',
        link: `/wealth-overview`,
      },
      {
        title: 'Accounts',
        subComponent: 'AccountListNav',
        isMainNav: true,
      },
      {
        title: 'Transfers',
        link: `${investConfig.investUrl}/transfer`,
        isMainNav: true,
        transmitdestination: 'invest',
      },
      {
        title: 'Investments',
        link: `${investConfig.investUrl}/dashboard`,
        isMainNav: true,
        transmitdestination: 'invest',
      },
      {
        title: 'More',
        subLinks: [
          {
            title: 'Net Worth Details',
            link: '/net-worth-details',
          },
          {
            title: 'Transfers',
            link: `${investConfig.investUrl}/transfer`,
            transmitdestination: 'invest',
          },
        ],
      },
      {
        title: 'Documents and Tools',
        isMainNav: true,
        subLinks: [
          {
            title: 'Documents',
            link: `${investConfig.investUrl}/accounts/documents`,
            transmitdestination: 'invest',
          },
          {
            title: 'Document Upload',
            link: `${investConfig.investUrl}/document-upload`,
            transmitdestination: 'invest',
          },
          {
            title: 'Tax Center',
            link: `${investConfig.investUrl}/tax-center`,
            transmitdestination: 'invest',
          },
        ],
      },
      {
        title: 'Help',
        isMainNav: true,
        link: `${investConfig.svcproviderUrl}/serviceprovider/ssoToAlly?relayState=${investConfig.bankUrl}/help`,
        transmitdestination: 'bank_react',
      },
      {
        title: 'Contact',
        isMainNav: true,
        link: `${investConfig.svcproviderUrl}/serviceprovider/ssoToAlly?relayState=${investConfig.bankUrl}/contact`,
        transmitdestination: 'bank_react',
      },
    ],
  };

  return {
    navLinkList,
  };
};

export default useNav;
