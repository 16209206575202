import styled from 'styled-components';
import { muiAllyTheme } from '../../../utils';
import { UL } from '../../atoms/index';

const StyledNav = styled.nav`
  display: flex;
  width: 100%;
  ${(props: IProps): string => `
    height: ${props.isMainNav ? '68px' : '45px'};
    background-color: ${props.isMainNav ? muiAllyTheme.colors.plum : 'white'};
    box-shadow: ${props.isMainNav ? '' : '3px 3px 3px rgba(0,0,0,0.25)'};
  `};
`;

const InnerNav = styled(UL)`
  display: flex;
  width: 100%;
  max-width: 1330px;
  margin: 0 auto;
  align-items: center;
  padding: 0 10px;
  ${({ theme: { media } }): string => `
    ${media.up('md')} {
      padding: 0 20px;
  }`}
`;

interface IProps {
  isMainNav: boolean;
}

const NavBar: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  return (
    <StyledNav isMainNav={props.isMainNav}>
      <InnerNav>{props.children}</InnerNav>
    </StyledNav>
  );
};

export default NavBar;
