import { createRef, RefObject, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Box } from '@ally/metronome-ui';
import { AppointmentSelection } from '..';
import { ProspectAssessment, Timeslot, Timeslots } from '../../../models';
import { Alert, WealthHeading } from '../../molecules';
import { Loader } from '../../atoms';
import { useAllyTm } from '../../../hooks';
import { KeyValuePair } from '../../../interfaces';

const Wrapper = styled(Box)`
  button > {
    padding: 20px 0;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

interface IProps {
  submit: Function;
  subHeader?: string;
  alert?: string;
  wait?: boolean;
  setInputValues: Function;
  inputValues: ProspectAssessment;
  timeslots: Timeslots;
}

const BookAppointment: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const [errors, setErrors] = useState<KeyValuePair<string>[]>([]);
  const appointmentRef: RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    onUpdateAppointment('selectedAppointment', new Timeslot());
  }, []);

  const isFormInvalid = () => {
    const validationErrors: KeyValuePair<string>[] = [];
    let scrollLocationFound: boolean = false;
    if (
      appointmentRef &&
      appointmentRef.current &&
      (!props.inputValues.selectedAppointment ||
        new Date(
          props.inputValues.selectedAppointment.startTime
        ).getFullYear() === new Timeslot().defaultYear)
    ) {
      validationErrors.push({
        id: 'appointmentSelector',
        message: 'Pick a time to continue.',
      });
      if (!scrollLocationFound) {
        appointmentRef.current.scrollIntoView();
        scrollLocationFound = true;
      }
    }
    return validationErrors;
  };

  const submit = () => {
    setErrors([]);
    const validationErrors = isFormInvalid();
    setErrors(validationErrors);
    allyTmEvent(
      'customError',
      validationErrors
        .map((error: KeyValuePair<string>) => error.message)
        .join(', ')
    );
    validationErrors.length === 0 && props.submit();
  };

  const onUpdateAppointment = (
    property: 'selectedAppointment' | 'closedCaptioning',
    value: Timeslot | boolean
  ) => {
    props.setInputValues({
      ...props.inputValues,
      [property]: value,
    });
  };

  return (
    <Wrapper>
      <WealthHeading
        content='When should we connect?'
        subHeader={props.subHeader}
      />
      <Alert
        show={!!props.alert && !props.wait}
        variant='error'
        body={props.alert}
      />
      <Loader isLoading={props.timeslots.isLoading}>
        <Box ref={appointmentRef}>
          <AppointmentSelection
            onUpdateAppointment={onUpdateAppointment}
            timeslots={props.timeslots}
            timeslotError={
              errors.find((e) => e.id === 'appointmentSelector')?.message
            }
          />
        </Box>
      </Loader>
      <StyledButton
        allytmln='prospectFormSubmit'
        variant='primary'
        text='Next'
        onClick={submit}
        isLoading={props.wait}
      />
    </Wrapper>
  );
};

export default BookAppointment;
