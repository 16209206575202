import React from 'react';
import styled from 'styled-components';

const StyledDD = styled.dd`
  margin: 0;
`;

interface IProps {}

const DD: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  return <StyledDD {...props}>{props.children}</StyledDD>;
};

export default DD;
