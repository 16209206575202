import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { TextHeading, TextBody } from '@ally/metronome-ui';
import {
  useAppCache,
  useAssessmentManager,
  useAthenaLogger,
  useCookie,
  useCustomersService,
  useSession,
  useTransmit,
  useConfig,
} from '../../../hooks';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { HistoryState } from '../../../interfaces';
import { LoadingSpinner } from '../../atoms';
import styled from 'styled-components';
import { muiAllyTheme } from '../../../utils';

const StyledTextHeading = styled(TextHeading)`
  color: ${muiAllyTheme.colors.plum};
`;

interface IProps {}

const AuthenticatingPage: React.FC<IProps> = () => {
  const { getCookie } = useCookie();
  const { login, isLoggedIn, getSessionStorage } = useSession();
  const {
    location: { state },
  } = useHistory();
  const { getAssessment, inputValues } = useAssessmentManager();
  const { getACMStatus } = useCustomersService();
  const [from, setFrom] = useState<string>('/');
  const { getRelationship } = useAppCache();
  const [isWealthRelationship, setIsWealthRelationship] = useState<boolean>(
    false
  );
  const [isSecondary, setIsSecondary] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<
    'INIT' | 'INPROGRESS' | 'DONE'
  >('INIT');
  const { initTransmit } = useTransmit();
  const athenaLogger = useAthenaLogger();
  const {
    investConfig: { bankUrl },
  } = useConfig();

  useEffect(() => {
    const init = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      if (!searchParams.get('ticketID')) {
        return window.location.assign(bankUrl);
      }

      await initTransmit();
      const jwt = getCookie('jwt') || getSessionStorage('jwtToken');
      const refreshToken = getCookie('refreshToken');
      const { from: prev } = state ? (state as HistoryState) : { from: '/' };
      setFrom(prev);
      if (jwt) {
        await login(jwt, refreshToken);
        athenaLogger.sendLog('*TRANSMIT* User logging in via Transmit', 'info');
      } else {
        return window.location.assign(bankUrl);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const checkACMStatus = async () => {
        setLoadingStatus('INPROGRESS');
        const res = await getAssessment();
        setIsSecondary(!res);
        const relationshipList = await getACMStatus();
        setIsWealthRelationship(!!getRelationship('WEALTH', relationshipList));
        setLoadingStatus('DONE');
      };
      checkACMStatus();
    }
  }, [isLoggedIn]);

  return (
    <DefaultTemplate hideSubNav>
      <StyledTextHeading tag='h1' size='lg'>
        Authenticating
      </StyledTextHeading>
      <TextBody tag='strong' size='md'>
        Please Wait...
      </TextBody>
      <LoadingSpinner isLoading color='white' />
      {isLoggedIn && loadingStatus === 'DONE' && (
        <Redirect
          to={
            inputValues.assessmentStatus === 'SUBMITTED' ||
            (isSecondary && isWealthRelationship)
              ? from
              : '/prospect-assessment'
          }
        />
      )}
    </DefaultTemplate>
  );
};

export default AuthenticatingPage;
