import React, { useState } from 'react';
import DefaultTemplate from '../../templates/DefaultTemplate';
import {
  DocumentReviewSubmitted,
  DocumentReviewAccordion,
} from '../../organisms';

interface IProps {}

const DocumentReviewPage: React.FC<IProps> = (props) => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  return (
    <DefaultTemplate hideSubNav>
      {!isSubmitted ? (
        <DocumentReviewAccordion setIsSubmitted={setIsSubmitted} />
      ) : (
        <DocumentReviewSubmitted />
      )}
    </DefaultTemplate>
  );
};

export default DocumentReviewPage;
