import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { QPR } from '.';

interface IQPRList extends IAPIData {
  qprs: QPR[];
}

class QPRList extends APIData implements IQPRList {
  qprs: QPR[] = [];
  dataName = 'Quatertly Progress Report List';

  setData(qprs: QPR[]) {
    this.qprs = qprs;
    this.setLoaded();
  }
}

export { QPRList };
export type { IQPRList };
