import { ChangeEvent, useState } from 'react';
import { Box, Space, TextBody, TextHeading } from '@ally/metronome-ui';
import {
  AccountsPerformanceList,
  BenchmarkSelect,
  OrionWidget,
  TimeFrameSelect,
  WidgetMainValue,
} from '../../molecules';
import { useAllyTm, useAppCache, useWealthController } from '../../../hooks';
import { InsightsIcon } from '../../icons';
import { IWidgetProps } from '../../../interfaces';
import { WealthAccount } from '../../../models';

interface Options {
  period: number;
  benchmark: number;
  preview: boolean;
  accountId: string | undefined;
}

const comparisonInfo = [
  '',
  'The S&P 500 is a market index that tracks the performance of 500 publicly traded companies. It may not track your portfolio’s specific securities or reflect whether you’re on track to hit your goals.',
  'The T-Bill, or Treasure Bill, Index is a market-value weighted index that measures performance of the U.S. Treasury Bill market. It may not track your portfolio’s specific securities or reflect whether you’re on track to hit your goals.',
  'The EAFE Index tracks performance for mid- and large-cap stocks across major international equity markets. It may not track your portfolio’s specific securities or reflect whether you’re on track to hit your goals.',
  'Your advisor created a blended index that combines multiple indices. Keep in mind, this blended index may not track all of your portfolio’s specific securities or reflect whether you’re on track to hit your goals.',
];

interface IProps extends IWidgetProps {
  valueLabel: string;
  idPrefix: string;
  account?: WealthAccount;
}

const PerformanceWidget: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const { assetsUnderCare } = useAppCache();
  const { wealthOverview } = useWealthController();
  const initialState: Options = {
    period: 0,
    benchmark: 0,
    preview: true,
    accountId: props.account?.accountId,
  };
  const [options, setOptions] = useState<Options>(initialState);

  const changeValue = (e: ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = e.target;
    setOptions({ ...options, [id]: parseInt(value, 10) });
    allyTmEvent('clickEvent', {
      tagName: 'button',
      allytmln: `${id === 'period' ? 'timeFrame' : 'comparisonIndex'}-${value}`,
      href: value,
    });
  };

  return (
    <Box display='flex' flexDirection='column'>
      <Box
        display='flex'
        flexDirection={['column', 'column', 'column', 'row', 'row']}
        paddingBottom='30px'
      >
        <Box marginRight='auto' paddingBottom={['15px', '15px', '15px', 0]}>
          <WidgetMainValue
            value={props.account ? props.account.balance : assetsUnderCare}
            isLoading={wealthOverview.isLoading}
          >
            {props.valueLabel}
          </WidgetMainValue>
        </Box>
        <Box
          display='flex'
          flexDirection={['column', 'column', 'row', 'row', 'row']}
        >
          <Box
            paddingBottom='10px'
            maxWidth={['100%', '100%', '100%', '200px']}
            marginRight={[0, 0, '20px']}
          >
            <BenchmarkSelect
              id='benchmark'
              onChange={changeValue}
              value={`${options.benchmark}`}
            />
          </Box>
          <Box
            paddingBottom='10px'
            maxWidth={['100%', '100%', '100%', '200px']}
          >
            <TimeFrameSelect
              id='period'
              onChange={changeValue}
              value={`${options.period}`}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection={['column', 'column', 'column', 'row', 'row']}
      >
        <Box
          flex='auto'
          width={['100%', '100%', '100%', '60%']}
          marginRight={[0, 0, 0, '15px', '30px']}
        >
          <OrionWidget
            type='performance'
            widgetId='performanceChart'
            options={options}
            refresh={props.refresh}
          />
          {options.benchmark !== 0 && (
            <Box
              bg='light-gray'
              padding='20px'
              display='flex'
              flexDirection='row'
              alignItems='center'
            >
              <Space paddingLeft='13px'>
                <InsightsIcon />
                <TextBody size='md' tag='p'>
                  {comparisonInfo[options.benchmark]}
                </TextBody>
              </Space>
            </Box>
          )}
        </Box>
        <Box
          flex='auto'
          width={['100%', '100%', '100%', '30%']}
          borderLeft={['none', 'none', 'none', '1px solid']}
          borderColor={['grey-80', 'grey-80', 'grey-80', 'grey-80']}
          paddingLeft={[0, 0, 0, '15px', '30px']}
          paddingTop={['30px', '30px', '30px', 0, 0]}
        >
          <Space paddingBottom='20px'>
            <TextHeading size='xs'>Performance Summary</TextHeading>
          </Space>
          <OrionWidget
            type='performanceSummary'
            widgetId='performanceSummaryChart'
            options={options}
          />
          {props.account?.accountId ? null : (
            <Box
              borderTop='1px solid'
              borderColor='grey-80'
              marginTop={['20px', '20px', '20px', '10px']}
              paddingTop='20px'
            >
              <Space paddingBottom='20px'>
                <TextHeading size='xs'>
                  Your Personal Advice Accounts
                </TextHeading>
              </Space>
              <AccountsPerformanceList />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PerformanceWidget;
