import { Box, TextBody } from '@ally/metronome-ui';
import { Goal } from '../../../models';
import { Currency } from '../../atoms';
import { GoalIconSelector } from '../../icons';

interface IProps {
  goal: Goal;
}

const GoalTile: React.FC<IProps> = (props) => {
  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='flex-start'
      alignItems='center'
      width='100%'
      height='100%'
      role='text'
    >
      <GoalIconSelector type={props.goal.type} />
      <Box
        display='flex'
        flexDirection='column'
        marginRight='auto'
        paddingX='15px'
      >
        <TextBody size='sm' tag='strong'>
          {props.goal.year}
        </TextBody>
        <TextBody size='sm' tag='strong' weight='bold'>
          {props.goal.name}
        </TextBody>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-end'>
        <TextBody size='sm' tag='strong' weight='bold'>
          <Currency value={props.goal.amount} />
        </TextBody>
        <TextBody
          size='sm'
          tag='strong'
          weight='bold'
          type={props.goal.onTrack ? undefined : 'error'}
        >
          {props.goal.onTrack ? 'On track' : 'Off track'}
        </TextBody>
      </Box>
    </Box>
  );
};

export default GoalTile;
