import { Box, Label, Select, TextBody } from '@ally/metronome-ui';
import { ChangeEvent, useState } from 'react';
import { Card, OrionWidget, TabSelector } from '../../molecules';
import { useAllyTm, useAppCache } from '../../../hooks';
import { Tab } from '../../atoms';
import { IWidgetProps } from '../../../interfaces';
import { AssetTypes } from '../../../enums';
import styled from 'styled-components';

const StyledLabel = styled(Label)`
  text-transform: uppercase;
`;

interface Options {
  type: AssetTypes.AssetTypes;
  allAccounts: boolean;
}

const initialState: Options = {
  type: 0,
  allAccounts: false,
};

interface IProps extends IWidgetProps {}

const AllocationWidget: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const [options, setOptions] = useState<Options>(initialState);
  const { getRelationship } = useAppCache();

  const changeValue = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { id, value } = e.target;
    setOptions({ ...options, [id]: value });
    allyTmEvent('clickEvent', {
      tagName: 'button',
      allytmln: id,
      href: value,
    });
  };

  const selectTabHandler = (value: string): void => {
    setOptions({
      ...options,
      allAccounts: value === 'allAccounts' ? true : false,
    });
  };

  const showAssetsUnderCareTab = (): boolean => {
    const relationship = getRelationship('WEALTH');
    return !!(
      relationship && relationship?.relationshipStatus === 'CLIENT_FUNDED'
    );
  };

  return (
    <Card title='Allocation'>
      <Box
        display={['flex', 'block', 'block', 'flex']}
        flexDirection='row'
        justifyContent='space-between'
      >
        <TabSelector
          onChange={selectTabHandler}
          aria-label='change listed assets'
          defaultValue={
            showAssetsUnderCareTab() ? 'assetsUnderCare' : 'allAccounts'
          }
        >
          {showAssetsUnderCareTab() && (
            <Tab value='assetsUnderCare' text='Assets Under Care' />
          )}
          <Tab value='allAccounts' text='All Known Investments' />
        </TabSelector>
        <Box
          width={['200px', '100%', '100%', '200px']}
          paddingRight={['0px', '0px', '10px']}
        >
          <StyledLabel size='xs' htmlFor='type'>
            View
          </StyledLabel>
          <Select
            id='type'
            options={AssetTypes.AssetTypesArray}
            onChange={changeValue}
            value={options.type}
          />
        </Box>
      </Box>
      <Box paddingBottom='20px' paddingTop='10px'>
        <TextBody tag='p' size='md'>
          {options.allAccounts
            ? 'This view includes all Ally Invest accounts and any linked investment accounts at other institutions.'
            : ''}
        </TextBody>
      </Box>
      <OrionWidget
        type='allocation'
        widgetId='allocationChart'
        options={options}
        refresh={props.refresh}
      ></OrionWidget>
      {/*
      // TODO this will be re-added when risk is available
      <StyledBox>
        <RiskBox
          borderRight='1px solid'
          borderColor='slate-2'
          textAlign='center'
        >
          <TextBody tag='p' size='lg' fontSize='1.25rem'>
            Risk Approach:
          </TextBody>
          <Box width={['100%', '100%', '100%', '280px']}>
            <TextBody tag='p' size='lg' fontSize='1.25rem' fontWeight='bold'>
              {options.allAccounts ? 'NN' : 'Moderate Growth'}
            </TextBody>
          </Box>
        </RiskBox>
        <TextBodyRisk tag='p' size='md' fontSize='1rem'>
          {options.allAccounts
            ? "Your score reflects a [RISK TOLERANCE] approach to risk across all your investments. That means you're okay with some losses if it means maxing out your potential returns."
            : 'Your advisor has allocated your assets with your risk tolerance in mind. Remember, this is just one part to your overall approach to risk.'}
        </TextBodyRisk>
      </StyledBox>
      */}
    </Card>
  );
};

export default AllocationWidget;
