import { useLocation } from 'react-router-dom';

/***
 * Toolkit for using the current page's URL
 */
const useURL = () => {
  /***
   * Search is the query param string attached to the URL. Use this if you need to keep
   * query params when redirecting to new pages.
   */
  const { search, pathname } = useLocation();

  /***
   * Gets the specified query param from the current page and returns it or null if not present.
   */
  const getQueryParam = (param: QueryParams): string | null =>
    new URLSearchParams(search.toLowerCase()).get(param.toLowerCase());

  return {
    getQueryParam,
    search: search.toLowerCase(),
    pathname,
  };
};

type QueryParams = 'hidesubnav' | 'noLookup';

export default useURL;
