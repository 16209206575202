import { useEffect, useState } from 'react';
import { IListener } from '../../interfaces';
import { EventType } from '../../types';

const useEventListener = () => {
  const [listeners, setListeners] = useState<IListener[]>([]);

  useEffect(() => {
    try {
      return listeners.forEach((listener: IListener) => {
        window.removeEventListener(listener.eventType, listener.listener);
      });
    } catch (error) {}
  }, []);

  const addListener = (eventType: EventType, listener: any) => {
    window.addEventListener(eventType, listener);
    setListeners({ ...listeners, ...{ eventType, listener } });
  };

  return {
    addListener,
  };
};

export default useEventListener;
