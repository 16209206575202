import { NavBar } from '../../molecules';
import { DropdownMenu, useDropdownContext } from '@ally/metronome-ui';
import { NavLink, DropDownLink } from '../../atoms';
import styled from 'styled-components';
import { useSession, useNav, useTransmit } from '../../../hooks';
import { INavLinkDetail } from '../../../models';
import { ISubLink } from '../../../interfaces';

const StyledNavLink = styled(NavLink)`
  padding: 0 15px;
  :first-child {
    padding-left: 0;
  }
  :last-child {
    padding-right: 0;
  }
  ${({ theme: { colors } }): string => `
    color: ${colors['bluesuedeshoes']}
  `}
`;

const StyledDropdownMenuMenuItemLink = styled(DropdownMenu.MenuItemLink)`
  ${({ theme: { colors } }): string => `
    color: ${colors['bluesuedeshoes']}
  `}
`;
interface INavItemProps {
  navItem: INavLinkDetail;
}

const NavItem: React.FC<INavItemProps> = ({ navItem }) => {
  const context = useDropdownContext();
  const { leaveWithTicket } = useTransmit();

  return (
    <>
      {!navItem.subLinks && (
        <StyledNavLink
          transmitdestination={navItem.transmitdestination}
          to={navItem.link || '#'}
          text={navItem.title}
          subNav
        />
      )}
      {!!navItem.subLinks && (
        <DropdownMenu smallCaret={true} topPos={34} context={context}>
          <DropdownMenu.Trigger
            ariaLabel={(isOpen: boolean) =>
              `${isOpen ? 'Close' : 'Open'} ${navItem.title} menu`
            }
            content={
              <DropDownLink type={'subnav'}>{navItem.title}</DropDownLink>
            }
          />
          <DropdownMenu.MenuContainer>
            {navItem.subLinks.map((subNavItem: ISubLink) => (
              <div
                key={subNavItem.title}
                onClick={
                  subNavItem.transmitdestination
                    ? (e) => leaveWithTicket(e, subNavItem.link)
                    : undefined
                }
              >
                <StyledDropdownMenuMenuItemLink
                  key={subNavItem.title}
                  to={subNavItem.transmitdestination ? '#' : subNavItem.link}
                  content={subNavItem.title}
                />
              </div>
            ))}
          </DropdownMenu.MenuContainer>
        </DropdownMenu>
      )}
    </>
  );
};

interface IProps {}

const SubNav: React.FC<IProps> = (props) => {
  const {
    navLinkList: { subNav },
  } = useNav();
  const { isLoggedIn } = useSession();

  return (
    <NavBar isMainNav={false}>
      {isLoggedIn &&
        subNav.map((navItem: INavLinkDetail) => (
          <NavItem key={navItem.title} navItem={navItem} />
        ))}
    </NavBar>
  );
};

export default SubNav;
