interface ILinkToken {
  expiration: Date;
  linkToken: string;
}

class LinkToken implements ILinkToken {
  expiration: Date = new Date();
  linkToken: string = '';
}

export { LinkToken };
export type { ILinkToken };
