import React from 'react';
import { Card, PerformanceWidget } from '../../molecules';
import { IWidgetProps } from '../../../interfaces';

interface IProps extends IWidgetProps {}

const AssetsUnderCareWidget: React.FC<IProps> = (props) => {
  return (
    <Card title='Assets Under Care'>
      <PerformanceWidget
        valueLabel='Combined Account Value'
        idPrefix='auc'
        refresh={props.refresh}
      />
    </Card>
  );
};

export default AssetsUnderCareWidget;
