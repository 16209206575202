import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { Timeslot } from './Timeslot';

interface ITimeslots extends IAPIData {
  accountId: string;
  contactId: string;
  workTypeId: string;
  timeslots: Timeslot[];
}

class Timeslots extends APIData implements ITimeslots {
  accountId: string = '';
  contactId: string = '';
  workTypeId: string = '';
  timeslots: Timeslot[] = [];
  dataName = 'Timeslots';

  setData(input: Timeslots) {
    Object.assign(this, input);
    this.setLoaded();
  }
}

export { Timeslots };
export type { ITimeslots };
