import {
  Box,
  Button,
  ButtonGroup,
  Modal as MetModal,
  TextHeading,
  TextHeadingProps,
} from '@ally/metronome-ui';
import { ModalProps } from '@ally/metronome-ui/dist/cjs/Modal/Modal';
import { createRef, ReactNode } from 'react';
import styled from 'styled-components';
import { muiAllyTheme } from '../../../utils';

const StyledButton = styled(Button)`
  border: 0;
  &:hover {
    box-shadow: none;
    background-color: transparent;
  }
`;

interface IHeadingProps {
  color?: string;
  fontSize?: string;
  fontWeight?: 'bold' | 'normal';
}

interface IProps extends ModalProps, IHeadingProps {
  heading: string;
  headingProps?: IHeadingProps;
  continue?: (
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  cancel?: (
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  actions?: ReactNode;
}

const StyledTextHeading = styled(TextHeading)`
  ${(props: IHeadingProps): string => `
    color: ${props?.color || muiAllyTheme.colors['slate-5']};
    font-size: ${props?.fontSize || 'inherit'};
  `}
`;

const Modal: React.FC<IProps> = (props: IProps) => {
  const modalRef = createRef<HTMLHeadingElement>();

  return (
    <MetModal
      ref={modalRef}
      context={props?.context}
      trigger={props?.trigger}
      width={props?.width}
      content={
        <>
          {props.heading && (
            <StyledTextHeading
              {...props.headingProps}
              ref={modalRef}
              tabIndex={-1}
              size='xs'
              weight={props.headingProps?.fontWeight || 'bold'}
            >
              {props.heading}
            </StyledTextHeading>
          )}
          <Box paddingTop='10px'>{props.content}</Box>
          {(props.continue || props.cancel) && (
            <Box paddingTop='30px'>
              <ButtonGroup buttonSize='sm'>
                {props.continue && (
                  <Button text='Continue' onClick={props.continue} />
                )}
                {props.cancel && (
                  <StyledButton
                    variant='outline'
                    text='Cancel'
                    onClick={props.cancel}
                  />
                )}
              </ButtonGroup>
            </Box>
          )}
          {/* FOR CUSTOM ACTIONS */}
          {props.actions && <Box paddingTop='30px'>{props.actions}</Box>}
        </>
      }
      manualToggle
    />
  );
};

export default Modal;
