import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { Account } from '.';

interface IAccountList extends IAPIData {
  accounts: Account[];
}

class AccountList extends APIData implements IAccountList {
  accounts: Account[] = [];
  dataName = 'Wealth Accounts';

  setData(accounts: Account[]) {
    this.accounts = accounts || [];
    this.setLoaded();
  }
}

export { AccountList };
export type { IAccountList };
