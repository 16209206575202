import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { Goal, GoalOwner } from './index';

interface IGoalsSummary extends IAPIData {
  goals: Goal[];
  planId: string;
  principal: GoalOwner;
  spouse: GoalOwner | undefined;
}

class GoalsSummary extends APIData implements IGoalsSummary {
  goals: Goal[] = [];
  planId: string = '';
  principal: GoalOwner = new GoalOwner();
  spouse: GoalOwner | undefined;
  dataName = 'Goal Summary';

  setData(goalsSummary: GoalsSummary) {
    this.goals = goalsSummary.goals || [];
    this.planId = goalsSummary.planId;
    this.principal = goalsSummary.principal;
    this.spouse = goalsSummary.spouse;
    this.setLoaded();
  }
}

export { GoalsSummary };
export type { IGoalsSummary };
