import { useConfig } from '../';

const LogRocket = require('logrocket/setup')({
  sdkServer: 'https://cdn.lr-hv-in.com',
  ingestServer: 'https://r.lr-hv-in.com',
});

const blockedLRRoutes = [
  'capi/accounts',
  'invest-accounts/users',
  'users/wealth-accounts',
  'networth-details',
  'Phoenix/getUserAccountDetails',
];

/* Hook for handling logrocket */
const useLogRocket = () => {
  const { logRocketId } = useConfig();

  const initializeLogRocket = () => {
    LogRocket.init(logRocketId, {
      shouldCaptureIP: false,
      rootHostname: 'ally.com',
      network: {
        responseSanitizer: (response: any) => {
          const res = response.url.toLowerCase();
          if (blockedLRRoutes.some((route) => res.includes(route))) {
            delete response.body;
          }
          return response;
        },
      },
    });
  };
  return {
    initializeLogRocket,
    LogRocket,
  };
};

export default useLogRocket;
