import styled from 'styled-components';
import { muiChevronDown } from '@ally/metronome-icons';
import { Icon } from '@ally/metronome-ui';
import { muiAllyTheme } from '../../../utils';

const StyledDropDownLink = styled.span`
  padding: 0 5px 0 15px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  ${(props: IProps): string => `
    font-size: ${props.type === 'nav' ? '1.2rem' : '0.93333rem'};
    color: ${
      props.type === 'nav'
        ? muiAllyTheme.colors.white
        : muiAllyTheme.colors.bluesuedeshoes
    };
  `}
  &:hover {
    cursor: pointer;
  }
`;

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  padding-left: 5px;
`;

interface IProps {
  type: 'nav' | 'subnav' | 'page';
}

const DropDownLink: React.FC<IProps> = (
  props: React.PropsWithChildren<IProps>
) => {
  return (
    <StyledDropDownLink type={props.type}>
      {props.children}
      <StyledIcon
        icon={muiChevronDown}
        size='md'
        fill={props.type === 'nav' ? 'white' : 'bluesuedeshoes'}
      />
    </StyledDropDownLink>
  );
};

export default DropDownLink;
