import {
  AccountList,
  GoalsSummary,
  WealthOverview,
  PendingAccount,
  RelationshipList,
  NetWorthDetails,
  DepositAccountList,
  InvestAccountList,
  NetWorthPerformance,
  Plaid,
  ProposalList,
  QPRList,
  UpcomingAppointmentList,
  Esign,
} from '.';

class AppCache {
  accountList: AccountList;
  goalsSummary: GoalsSummary;
  wealthOverview: WealthOverview;
  pendingAccounts: PendingAccount[];
  suppressedNotifications: { [key: string]: boolean } = {};
  institutions: any[]; // Any for now untill we get types for it
  relationshipList: RelationshipList;
  netWorthDetails: NetWorthDetails;
  depositAccountList: DepositAccountList;
  investAccountList: InvestAccountList;
  netWorthPerformance: NetWorthPerformance;
  plaid: Plaid;
  proposalList: ProposalList;
  QPRList: QPRList;
  upcomingAppointmentList: UpcomingAppointmentList;
  isSecondary: boolean;
  esignStatus: Esign;
  processingLogout: boolean;

  constructor() {
    this.accountList = new AccountList();
    this.goalsSummary = new GoalsSummary();
    this.wealthOverview = new WealthOverview();
    this.pendingAccounts = [];
    this.suppressedNotifications = {};
    this.institutions = [];
    this.relationshipList = new RelationshipList();
    this.netWorthDetails = new NetWorthDetails();
    this.depositAccountList = new DepositAccountList();
    this.investAccountList = new InvestAccountList();
    this.netWorthPerformance = new NetWorthPerformance();
    this.plaid = new Plaid();
    this.proposalList = new ProposalList();
    this.QPRList = new QPRList();
    this.upcomingAppointmentList = new UpcomingAppointmentList();
    this.isSecondary = false;
    this.esignStatus = new Esign();
    this.processingLogout = false;
  }
}

export default AppCache;
