import { Table as MetTable } from '@ally/metronome-ui';
import { TableProps } from '@ally/metronome-ui/dist/cjs/Table/';
import styled from 'styled-components';

const StyledTable = styled(MetTable)`
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
  tr {
    td,
    th {
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
    td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

interface IProps<T extends object> extends TableProps<T> {}

const Table: React.FC<IProps<{}>> = (props) => {
  return <StyledTable isCaptionVisible {...props} />;
};

export default Table;
