import { BlobLink, WidgetLabel, DD } from '../../atoms';
import { useFormatter } from '../../../hooks';
import { FileBlob } from '../../../models';

interface IProps {
  label: string;
  file: FileBlob | undefined;
  allytmln: string;
  displayName: string;
  date?: Date;
  quarterText?: string; // props value from api: 4/1/2022 to 6/30/2022 and result should be `Q2 2022`
}

const LabeledBlobLink: React.FC<IProps> = (props) => {
  const { dateTimeFormatter, getQuarter } = useFormatter();
  let content = props.displayName;
  if (props.date) {
    content = `${content} ${dateTimeFormatter(props.date, false, {
      month: 'numeric',
      year: 'numeric',
      day: 'numeric',
    })}`;
  } else if (props.quarterText) {
    const [start, end] = props.quarterText.split('to');
    content = `${content} ${getQuarter(end)} ${dateTimeFormatter(end, false, {
      year: 'numeric',
    })}`;
  }

  return (
    <>
      <WidgetLabel tag='dt'>{props.label}</WidgetLabel>
      <DD>
        <BlobLink
          allytmln={props.allytmln}
          blob={props.file?.blob}
          isLoading={props.file?.isLoading}
          fileName={props.displayName}
          text={content}
        />
        {' (PDF)'}
      </DD>
    </>
  );
};

export default LabeledBlobLink;
