import { TextBody, TextBodyProps } from '@ally/metronome-ui';
import styled, { CSSProperties } from 'styled-components';
import Loader from '../Loader';

interface IProps extends TextBodyProps {
  isLoading?: boolean;
  fontSize?: string;
  textTransform?: CSSProperties['textTransform'];
  keepSpace?: boolean;
}

const StyledTextBody = styled(TextBody)`
  ${(props: IProps): string | undefined =>
    props.fontSize && `font-size: ${props.fontSize};`}
  ${(props: IProps): string | undefined =>
    props.textTransform && `text-transform: ${props.textTransform};`}
  ${(props: IProps): string | null =>
    props.keepSpace ? 'white-space: pre;' : null}
`;

const Text: React.FC<IProps> = (props) => {
  return (
    <Loader height='m' isLoading={props.isLoading}>
      <StyledTextBody {...props} />
    </Loader>
  );
};

export default Text;
