class Token {
  token: string = '';
  refreshToken: string = '';
  expiresIn: number = 3600;
  tokenType: string = 'Bearer';
  isLoading: boolean = false;
  expiresOn: Date = new Date();

  constructor(
    token: string = '',
    refreshToken: string = '',
    expiresIn: number = 3600,
    tokenType: string = 'Bearer',
    isLoading: boolean = false
  ) {
    this.token = token;
    this.refreshToken = refreshToken;
    this.expiresIn = expiresIn;
    this.tokenType = tokenType;
    this.isLoading = isLoading;
    this.expiresOn = new Date();
    // Set the expires time to current time + expires time - 1 minute
    this.expiresOn.setMilliseconds(
      this.expiresOn.getMilliseconds() + expiresIn * 1000 - 1 * 36000
    );
  }
}

export default Token;
