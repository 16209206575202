import { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import DefaultTemplate from '../../templates/DefaultTemplate';
import {
  ProspectAssessmentForm,
  AffiliateAgreement,
  BookAppointment,
} from '../../organisms';
import {
  useAssessmentManager,
  useURL,
  useAllyTm,
  useCustomersService,
  useAppCache,
} from '../../../hooks';
import { Appointment, ProspectAssessment, Timeslot } from '../../../models';
import { Alert, WealthHeading } from '../../molecules';
import { storeContext } from '../../../store/store';

const subHeader =
  'In this complimentary, 15-minute meeting, you’ll chat with a member of our team and learn about Ally Invest Personal Advice.';

const bookedTimeSlotStatus = 'TIMESLOT_BOOKED';

interface IProps {}

const ProspectAssessmentPage: React.FC<IProps> = (props) => {
  const {
    isLoading,
    inputValues,
    setInputValues,
    submitAssessment,
    getAssessment,
    updateUserSession,
  } = useAssessmentManager();
  const { search } = useURL();
  const { allyTmEvent } = useAllyTm();
  const {
    getApointmentTimeslots,
    timeslots,
    bookAppointment,
    removeTimeslot,
  } = useCustomersService();
  const [pageState, setPageState] = useState<
    | 'Initial'
    | 'AffiliateAgreement'
    | 'AssessmentError'
    | 'AssessmentSubmitted'
    | 'AppointmentError'
    | 'AppointmentSubmissionError'
    | 'AppointmentConflict'
    | 'Interstitials'
  >('Initial');
  const [wait, setWait] = useState<boolean>(false);
  const [attempts, setAttempts] = useState<number>(0);
  const [
    errorSubmittingAssessement,
    setErrorSubmittingAssessement,
  ] = useState<boolean>(false);
  const [bookAppointmentCache, setbookAppointmentCache] = useState<{
    opportunityId: string;
    contactId: string;
    email: string;
  }>({ opportunityId: '', contactId: '', email: '' });
  const { state } = useContext(storeContext);
  const { isWealthRelationship } = useAppCache();

  useEffect(() => {
    getAssessment(true);
    getApointmentTimeslots();
  }, []);

  const nextStep = async (shouldSubmit: boolean, saveEsign: boolean) => {
    setWait(true);
    setAttempts(0);
    const assessment: ProspectAssessment | null = await submitAssessment(
      shouldSubmit,
      saveEsign
    );
    if (!assessment) {
      setWait(false);
      setErrorSubmittingAssessement(true);
    } else if (shouldSubmit) {
      if (assessment?.opportunityId) {
        setbookAppointmentCache({
          opportunityId: assessment.opportunityId,
          contactId: assessment.contactId,
          email: assessment.email,
        });
        inputValues.selectedAppointment?.startTime &&
          (await submitAppointment(assessment));
      } else {
        setWait(false);
        setErrorSubmittingAssessement(true);
      }
    } else {
      setWait(false);
      setPageState('AffiliateAgreement');
    }
  };

  /**
   * Book appointment can have 3 different results
   * 1.) Success, keep going
   * 2.) Failed API, stop have user retry
   * 3.) Race condition and someone took the appointment before they selected it, show user appointment selector again
   */
  const submitAppointment = async (assessment?: ProspectAssessment) => {
    allyTmEvent(
      'conversionEvent',
      'Wealth:Prospect:Lead Form:On Your Way',
      'completed',
      inputValues.assessmentId
    );
    setWait(true);
    const appointment: Appointment = {
      serviceAppointment: {
        parentRecordId:
          assessment?.opportunityId || bookAppointmentCache.opportunityId,
        contactId: assessment?.contactId || bookAppointmentCache.contactId,
        email: inputValues.selectedAppointment.resources[0].email,
        workTypeId: inputValues.selectedAppointment.workTypeId,
        serviceTerritoryId: inputValues.selectedAppointment.territoryId,
        schedStartTime: inputValues.selectedAppointment.startTime,
        schedEndTime: inputValues.selectedAppointment.endTime,
        extendedFields: [
          {
            name: 'Closed_Caption__c',
            value: inputValues.closedCaptioning?.toString() || 'false',
          },
        ],
      },
      assignedResources: [
        {
          serviceResourceId: inputValues.selectedAppointment.resources[0].id,
          isRequiredResource: true,
          isPrimaryResource: true,
        },
      ],
    };
    // ONLY add these if they have a value or else it will break the book appointment endpoint
    inputValues.comments &&
      appointment.serviceAppointment.extendedFields.push({
        name: 'Customer_Notes__c',
        value: inputValues.comments,
      });
    inputValues.emailJoin &&
      appointment.serviceAppointment.extendedFields.push({
        name: 'Email_CCs__c',
        value: inputValues.emailJoin,
      });
    inputValues.nameJoin &&
      appointment.serviceAppointment.extendedFields.push({
        name: 'Email_CC_Name__c',
        value: inputValues.nameJoin,
      });
    try {
      await bookAppointment(appointment);
      await updateUserSession();
      setPageState('Interstitials');
    } catch (error: any) {
      if (error.data?.errors[0]?.detail === bookedTimeSlotStatus) {
        // Race condition for someone booking the appointment before them
        removeTimeslot(inputValues.selectedAppointment);
        setInputValues({
          ...inputValues,
          selectedAppointment: new Timeslot(),
        });
        setPageState('AppointmentConflict');
      } else {
        // Anything else is an error, they get three retries and then told to call in
        if (attempts > 2) {
          setPageState('AppointmentSubmissionError');
        } else {
          setAttempts((prev) => prev + 1);
          setPageState('AppointmentError');
        }
      }
      setWait(false);
    }
    setWait(false);
  };

  const renderPageState = () => {
    switch (pageState) {
      case 'AffiliateAgreement':
        return (
          <AffiliateAgreement
            inputValues={inputValues}
            nextStep={nextStep}
            wait={wait}
            errorSubmitting={errorSubmittingAssessement}
          />
        );
      case 'AppointmentError':
        return (
          <BookAppointment
            inputValues={inputValues}
            setInputValues={setInputValues}
            submit={submitAppointment}
            wait={wait}
            timeslots={timeslots}
            subHeader={subHeader}
            alert='Oops! Our system is acting up. Pick your date and time again. Don’t worry; we’ve saved the rest of your info.'
          />
        );
      case 'AppointmentConflict':
        return (
          <BookAppointment
            inputValues={inputValues}
            setInputValues={setInputValues}
            submit={submitAppointment}
            timeslots={timeslots}
            wait={wait}
            subHeader={subHeader}
            alert='Oops! It looks like someone else just grabbed your spot. Pick a new date and time. Don’t worry; we’ve saved the rest of your info.'
          />
        );
      case 'Interstitials':
        return (
          <Redirect
            to={{
              pathname: '/prospect-assessment-interstitial',
              search,
              state: { selectedAppointment: inputValues.selectedAppointment },
            }}
          />
        );
      case 'AppointmentSubmissionError':
        return (
          <>
            <WealthHeading />
            <Alert
              show
              variant='error'
              body='Oops! Our system is acting up and we can’t complete your request right now. Call us for help at 1-855-880-2559.'
            />
          </>
        );
      case 'Initial':
      default:
        return (
          <ProspectAssessmentForm
            setInputValues={setInputValues}
            inputValues={inputValues}
            nextStep={nextStep}
            isLoading={isLoading || timeslots.isLoading}
            timeslots={timeslots}
            subHeader={timeslots.error ? '' : subHeader}
          />
        );
    }
  };

  return (
    <DefaultTemplate hideSubNav>
      {inputValues.assessmentStatus === 'SUBMITTED' ||
      (state.appCache.isSecondary && isWealthRelationship) ? (
        <Redirect to='/' />
      ) : (
        renderPageState()
      )}
    </DefaultTemplate>
  );
};

export default ProspectAssessmentPage;
