import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { UpcomingAppointment } from './UpcomingAppointment';

interface IUpcomingAppointmentList extends IAPIData {
  upcomingAppointments: UpcomingAppointment[];
}

class UpcomingAppointmentList
  extends APIData
  implements IUpcomingAppointmentList {
  upcomingAppointments: UpcomingAppointment[] = [];
  dataName = 'UpcomingAppointments';

  setData(appointments: UpcomingAppointment[]) {
    this.upcomingAppointments = appointments || [];
    this.setLoaded();
  }
}

export { UpcomingAppointmentList };
export type { IUpcomingAppointmentList };
