import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../../../interfaces';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const EmergenciesIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ? props.width : '35px'}
      height={props.height ? props.height : '36px'}
      viewBox='0 0 35 36'
      version='1.1'
      aria-hidden={true}
    >
      <g
        xmlns='http://www.w3.org/2000/svg'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(-211.000000, -310.000000)' stroke='#371447'>
          <g transform='translate(211.000000, 310.000000)'>
            <g transform='translate(0.384615, 0.440000)'>
              <path
                d='M9.07696937,30.7332744 C9.07696937,32.6206415 10.7575531,34.1506548 12.8306581,34.1506548 C14.9037632,34.1506548 16.5843469,32.6206415 16.5843469,30.7332744'
                strokeWidth='1.08333333'
              />
              <line
                x1='16.5843469'
                y1='21.044375'
                x2='16.5843469'
                y2='30.1728598'
                strokeWidth='1.08333333'
                strokeLinecap='square'
              />
              <path
                d='M32.1719457,20.8128598 C32.1719457,12.0051469 25.0373922,4.86508475 16.2364745,4.86508475 C7.43555681,4.86508475 0.301003344,12.0051469 0.301003344,20.8128598 C1.93103922,20.4513791 3.08364982,20.2706387 3.75883514,20.2706387 C5.0862803,20.2706387 6.17350327,20.8128598 7.02050404,20.8128598 C8.46986788,20.8128598 9.97118977,19.9796509 11.7963949,19.9796509 C13.0074557,19.9796509 14.7516264,20.8128598 16.7569819,20.8128598 C18.0766291,20.8128598 19.8788842,20.1140791 21.5424966,20.1140791 C23.111049,20.1140791 24.5218656,20.8128598 26.3961974,20.8128598 C27.0948194,20.8128598 28.0946768,20.1923589 29.2987121,20.1923589 C29.8643383,20.1923589 30.8220828,20.3991925 32.1719457,20.8128598 Z'
                strokeWidth='1.08333333'
              />
              <g
                transform='translate(7.374582, 6.537458)'
                strokeWidth='0.541666667'
              >
                <path d='M0,11.1392195 C0.226357628,10.0210921 0.568884222,8.94051557 1.02757978,7.89748975 C2.31546609,4.96897074 4.51914284,2.33647415 7.63861003,0' />
                <path
                  d='M9.93311037,11.1392195 C10.159468,10.0210921 10.5019946,8.94051557 10.9606901,7.89748975 C12.2485765,4.96897074 14.4522532,2.33647415 17.5717204,0'
                  transform='translate(13.752415, 5.569610) scale(-1, 1) translate(-13.752415, -5.569610) '
                />
              </g>
              <path
                d='M10.5899201,3.02402845 C11.2020255,3.02402845 11.6982349,2.56131332 11.6982349,1.99052529 C11.6982349,1.60999993 11.3287966,0.946491504 10.5899201,0 C9.85104361,0.946491504 9.48160535,1.60999993 9.48160535,1.99052529 C9.48160535,2.56131332 9.97781478,3.02402845 10.5899201,3.02402845 Z'
                strokeWidth='0.541666667'
                fill='#371447'
              />
              <path
                d='M1.55981979,8.04114709 C2.17192514,8.04114709 2.66813457,7.57843197 2.66813457,7.00764393 C2.66813457,6.62711858 2.29869631,5.96361015 1.55981979,5.01711864 C0.820943276,5.96361015 0.451505017,6.62711858 0.451505017,7.00764393 C0.451505017,7.57843197 0.947714445,8.04114709 1.55981979,8.04114709 Z'
                strokeWidth='0.541666667'
                fill='#371447'
              />
              <path
                d='M32.4126626,8.04114709 C33.024768,8.04114709 33.5209774,7.57843197 33.5209774,7.00764393 C33.5209774,6.62711858 33.1515391,5.96361015 32.4126626,5.01711864 C31.6737861,5.96361015 31.3043478,6.62711858 31.3043478,7.00764393 C31.3043478,7.57843197 31.8005573,8.04114709 32.4126626,8.04114709 Z'
                strokeWidth='0.541666667'
                fill='#371447'
              />
              <path
                d='M22.9310573,3.02402845 C23.5431626,3.02402845 24.039372,2.56131332 24.039372,1.99052529 C24.039372,1.60999993 23.6699338,0.946491504 22.9310573,0 C22.1921807,0.946491504 21.8227425,1.60999993 21.8227425,1.99052529 C21.8227425,2.56131332 22.3189519,3.02402845 22.9310573,3.02402845 Z'
                strokeWidth='0.541666667'
                fill='#371447'
              />
            </g>
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

export default EmergenciesIcon;
