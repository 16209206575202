import { Box, TextBody } from '@ally/metronome-ui';
import styled from 'styled-components';
import { useWealthController } from '../../../hooks';
import { IWidgetProps } from '../../../interfaces';
import { muiAllyTheme } from '../../../utils';
import { Currency, DL, NumberCaret, WealthAccountsUpToDate } from '../../atoms';
import { Card, NetWorthOverTimeChart, Popover } from '../../molecules';
import { Plaid } from '../../organisms';

const StyledDL = styled(DL)`
  margin-left: 40px;
`;

interface IProps extends IWidgetProps {
  onSuccess: Function;
}

const NetWorthDetailsWidget: React.FC<IProps> = (props) => {
  const {
    netWorthDetails,
    wealthOverview,
    netWorthPerformance,
  } = useWealthController();

  return (
    <>
      <Card isHeroCard title='Net Worth Details'>
        <Box
          display='flex'
          flexDirection={['column', 'column', 'row']}
          paddingTop='20px'
        >
          <Box display='flex' flexDirection='column' width='100%'>
            <Box
              display='flex'
              flexDirection={['column', 'column', 'column', 'column', 'row']}
              justifyContent='flex-start'
              alignContent='stretch'
            >
              <Box
                flex={1}
                maxWidth={['100%', '100%', '100%', '100%', '340px']}
                marginRight={[0, 0, 0, 0, '35px']}
              >
                <DL>
                  <Popover
                    popoverAriaLabel='Net Worth'
                    triggerText='Net Worth'
                    body='The value of the assets you own minus the liabilities, or debt, you owe.'
                    triggerAriaLabel='Select to learn more about net worth'
                    heading='Net Worth'
                  />
                  <TextBody tag='dd' size='xl'>
                    <Currency
                      isLoading={wealthOverview.isLoading}
                      value={wealthOverview.netWorth}
                    />
                  </TextBody>
                </DL>
                <Box
                  display='flex'
                  flexDirection='row'
                  paddingBottom={['10px', '10px', '10px', '10px']}
                  alignItems='baseline'
                >
                  <NumberCaret
                    isLoading={netWorthPerformance.isLoading}
                    value={netWorthPerformance.percentChangeThisYear}
                    suffix='%'
                  />
                  <TextBody tag='p' size='sm'>
                    this year
                  </TextBody>
                </Box>
                <Box paddingBottom='20px'>
                  <WealthAccountsUpToDate wealthOverview={wealthOverview} />
                  <Plaid
                    buttonText='Add new account'
                    onSuccess={props.onSuccess}
                  />
                </Box>
                <Box display='flex' flexDirection='row' paddingBottom='20px'>
                  <DL>
                    <Popover
                      popoverAriaLabel='Assets'
                      triggerText='Assets'
                      body='The value of the assets you own minus the liabilities, or debt, you owe.'
                      triggerAriaLabel='Select to learn more about net worth'
                      heading='Assets'
                    />
                    <TextBody tag='dd' size='lg'>
                      <Currency
                        isLoading={wealthOverview.isLoading}
                        value={netWorthDetails.totalAssets}
                      />
                    </TextBody>
                  </DL>

                  <StyledDL>
                    <Popover
                      popoverAriaLabel='Liabilities'
                      triggerText='Liabilities'
                      body='The value of the assets you own minus the liabilities, or debt, you owe.'
                      triggerAriaLabel='Select to learn more about net worth'
                      heading='Liabilities'
                    />
                    <TextBody tag='dd' size='lg'>
                      <Currency
                        isLoading={wealthOverview.isLoading}
                        value={netWorthDetails.totalLiabilities}
                      />
                    </TextBody>
                  </StyledDL>
                </Box>

                <Box marginBottom='10px'>
                  <TextBody tag='p' size='sm'>
                    We include your accounts with us and any linked accounts at
                    other institutions in your net worth view.
                  </TextBody>
                </Box>
              </Box>

              <Box
                flex={1}
                borderLeft={[
                  0,
                  0,
                  0,
                  0,
                  `1px solid ${muiAllyTheme.colors['grey-80']}`,
                ]}
                paddingLeft={[0, 0, 0, 0, '28px']}
                width='100%'
                height='100%'
              >
                <NetWorthOverTimeChart refresh={props.refresh} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default NetWorthDetailsWidget;
