import { Alert } from '../../molecules';
import { useNotifications } from '../../../hooks';
import { TextBody } from '@ally/metronome-ui';
import { Link } from '../../atoms';

interface IProps {}

const PendingNotificationBanner: React.FC<IProps> = (props) => {
  const { pendingAccounts } = useNotifications();

  return (
    <Alert
      show={!!pendingAccounts.length}
      variant='info'
      heading={
        <TextBody tag='p' size='sm'>
          We need you to review a few documents before we can open your new
          Personal Advice accounts.{' '}
          <Link $internal to='/document-review' text='Go to document review' />
        </TextBody>
      }
    />
  );
};

export default PendingNotificationBanner;
