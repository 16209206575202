import styled from 'styled-components';
import { Button } from '@ally/metronome-ui';
import { muiAllyTheme } from '../../../utils';

const StyledTab = styled(Button)`
  padding-bottom: 7px;
  margin-right: 28px;
  font-size: 1rem;
  ${(props: IProps): string => `
    color: ${
      props.active
        ? muiAllyTheme.colors.plum
        : muiAllyTheme.colors.bluesuedeshoes
    };
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: ${
      props.active ? muiAllyTheme.colors.plum : 'transparent'
    };
  `};
  &:last-of-type {
    margin-right: 0px;
  }
`;

interface IProps {
  active?: boolean;
  value: string;
  text: string;
}

const Tab: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledTab
      {...{ ...props, role: 'tab' }}
      variant='link'
      aria-selected={props.active}
      text={props.text}
    />
  );
};
export default Tab;
