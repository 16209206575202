import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { Member, WealthAccount } from '.';

interface IWealthOverview extends IAPIData {
  advisedSince: Date;
  totalNumberOfAccounts: number;
  totalNumberDisconnectedAccounts: number;
  members: Member[];
  netWorth: number;
  assetsUnderCare: number;
  assetsUnderCarePercentage: number;
  wealthAccounts: WealthAccount[];
}

class WealthOverview extends APIData implements IWealthOverview {
  advisedSince: Date = new Date();
  totalNumberOfAccounts: number = 0;
  totalNumberDisconnectedAccounts: number = 0;
  members: Member[] = [];
  netWorth: number = 0;
  assetsUnderCare: number = 0;
  assetsUnderCarePercentage: number = 0;
  wealthAccounts: WealthAccount[] = [];
  dataName = 'Wealth Overview';

  setData(WealthOverview: WealthOverview) {
    this.advisedSince = WealthOverview.advisedSince;
    this.totalNumberOfAccounts = WealthOverview.totalNumberOfAccounts;
    this.totalNumberDisconnectedAccounts =
      WealthOverview.totalNumberDisconnectedAccounts;
    this.members = WealthOverview.members || [];
    this.netWorth = WealthOverview.netWorth;
    this.assetsUnderCare = WealthOverview.assetsUnderCare;
    this.assetsUnderCarePercentage = WealthOverview.assetsUnderCarePercentage;
    this.wealthAccounts = WealthOverview.wealthAccounts || [];
    this.setLoaded();
  }
}

export { WealthOverview };
export type { IWealthOverview };
