import {
  Box,
  Button,
  TableColumn,
  TextBody,
  TextHeading,
} from '@ally/metronome-ui';
import { SortableColumn } from '@ally/metronome-ui/dist/cjs/Table/types';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFormatter, useWealthController } from '../../../hooks';
import { IWidgetProps, Series } from '../../../interfaces';
import { NetWorthAccount } from '../../../models';
import { ColorsList } from '../../../types';
import { LoadingSpinner } from '../../atoms';
import { Plaid, NetWorthAccountsTableDrawer } from '../../organisms';
import { DonutChart } from '../../molecules';
import Table from '../Table';
import { muiAllyTheme } from '../../../utils';

const StyledTable = styled(Table)`
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
  thead {
    th {
      &:first-child {
        padding-left: 16px;
      }
    }
  }
  tr {
    td {
      padding-top: 10px;
      padding-bottom: 10px;
      &:first-child {
        padding-left: 16px;
      }
    }
    &.even {
      background-color: ${muiAllyTheme.colors['white']};
    }
  }
`;

interface IProps extends IWidgetProps {
  type: string;
  onSuccess: Function;
}
interface AccountInfo extends NetWorthAccount {
  accountNumber?: string;
}

interface BreakDownData {
  title: string;
  data: Series<AccountInfo[]>[];
  subTitle: string;
  columns: TableColumn<Series<AccountInfo[]>>[];
}

interface NetWorthBreakDownDetail {
  total: number;
  label: TabsType;
  details: {
    [key: string]: NetWorthAccount[];
  };
}
type TabsType = 'Assets' | 'Liabilities';

const BreakDownDetails = (props: IProps) => {
  const { netWorthDetails, userAccounts } = useWealthController();
  const { numberFormatter } = useFormatter();
  const [breakDownData, setBreakDownData] = useState<BreakDownData>();

  const noDataColumns = (
    currentTab: TabsType
  ): TableColumn<Series<AccountInfo[]>>[] => [
    {
      Header: () => (
        <TextBody tag='span' size='md'>
          Accounts
        </TextBody>
      ),
      accessor: 'name',
      alignment: 'left',
      Cell: ({ cell }: SortableColumn): React.ReactNode => (
        <Box paddingY='10px'>
          {!netWorthDetails.error && currentTab === 'Liabilities' && (
            <>
              <TextHeading size='xs' tag='h3'>
                You don’t currently have any {currentTab.toLowerCase()}.
              </TextHeading>
              <TextBody size='md' tag='p'>
                You may be crushing the debt game, or you may need to add an
                account. (We’re guessing a little of both).
              </TextBody>
            </>
          )}
          {(netWorthDetails.error || currentTab === 'Assets') && (
            <TextBody size='md' tag='p'>
              This information isn’t available right now. Try again later, or
              call us for help.
            </TextBody>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const {
      assets,
      liabilities,
      totalAssets,
      totalLiabilities,
    } = netWorthDetails;

    const { total, details, label }: NetWorthBreakDownDetail =
      props.type === 'Asset'
        ? { total: totalAssets, details: assets, label: 'Assets' }
        : {
            total: totalLiabilities,
            details: liabilities,
            label: 'Liabilities',
          };

    const data: BreakDownData = {
      title: `${label}`,
      subTitle: numberFormatter(total),
      // Calculate the assets / liabilities sum with average percentage
      data: Object.entries(details).reduce(
        (
          acc: Series<AccountInfo[]>[],
          [key, curr]: [string, AccountInfo[]],
          index
        ) => {
          // remove hidden accounts and handle if there is no account
          curr = curr.filter((info) => !info.isHidden);
          if (!curr.length) {
            return [];
          }
          const [{ typeGroup, designation }] = curr;
          if (typeGroup !== null && designation === props.type) {
            const totalBalance = curr.reduce(
              (totalBalance, { balance }) => (totalBalance += balance),
              0
            );
            const percentage: number = totalBalance / total;
            acc.push({
              name: `${typeGroup} (${curr.length} accounts)`,
              percentage: isNaN(percentage)
                ? '0.00%'
                : numberFormatter(percentage, {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  }),
              y: Number(totalBalance.toFixed(2)),
              color: ColorsList[key],
              accounts: curr.map((row) => {
                const account = userAccounts.accounts.find(
                  (acc) => acc.id === row.accountId
                );
                return {
                  ...row,
                  accountNumber: account ? account.number : undefined,
                };
              }),
            });
          }
          return acc;
        },
        []
      ),
      // Set table columns and renderers
      columns: [
        {
          Header: 'Accounts',
          accessor: 'name',
          alignment: 'left',
          Cell: ({
            cell,
            isDrawerOpen,
            toggleDrawer,
            drawerTriggerRef,
          }: SortableColumn): React.ReactNode | string => {
            return (
              <Box
                display={'flex'}
                alignItems='center'
                color={muiAllyTheme.colors.bluesuedeshoes}
                fontSize='0.9333rem'
              >
                <Box
                  width='14px'
                  height='14px'
                  bg={cell.row.original.color}
                  borderRadius='50%'
                  marginRight='10px'
                />
                <Button
                  allytmln='breakDownDetailTableDrawer'
                  text={cell.value}
                  variant='link'
                  onClick={(): void => {
                    toggleDrawer();
                  }}
                  ref={drawerTriggerRef}
                  aria-expanded={isDrawerOpen ? 'true' : 'false'}
                />
              </Box>
            );
          },
        },
        {
          Header: `% of ${label}`,
          accessor: 'percentage',
          alignment: 'right',
        },
        {
          Header: 'Value',
          accessor: 'y',
          alignment: 'right',
          Cell: ({ cell }: SortableColumn): React.ReactNode | string =>
            numberFormatter(cell.value),
        },
      ],
    };

    if (data.data.length === 0) {
      data.data = [{ name: '', percentage: '', y: 0, color: '' }];
      data.columns = noDataColumns(label);
    }

    setBreakDownData(data);
  }, [props.type, netWorthDetails.hasLoaded, props.refresh]);

  return (
    <Box
      display='flex'
      flexDirection={['column', 'column', 'column', 'row']}
      alignItems='center'
      overflowX='hidden'
    >
      <LoadingSpinner isLoading={!breakDownData}>
        {breakDownData && (
          <>
            <Box
              flex={1}
              maxWidth={['fit-content', 'fit-content', 'fit-content', '300px']}
              alignSelf={['center', 'center', 'center', 'baseline']}
              marginRight={['0', '0', '10px']}
            >
              <DonutChart
                {...breakDownData}
                title={`Total ${breakDownData.title}`}
                height='300px'
                colors={breakDownData.data.map((item) => item.color)}
                id='breakdown-donut-chart'
              />
            </Box>

            <Box flex={1} justifySelf='center' width='100%'>
              <Box
                display={'flex'}
                paddingBottom='10px'
                alignItems='flex-start'
              >
                <TextHeading tag='h3' size='xs'>
                  Your {breakDownData.title}
                </TextHeading>
                <Plaid
                  hideManageAccount
                  buttonText='Add an account'
                  onSuccess={props.onSuccess}
                />
              </Box>
              <StyledTable
                isCaptionVisible={false}
                captionText={`Your ${breakDownData.title} breakdown data`}
                data={breakDownData.data}
                columns={breakDownData.columns}
                drawer={<NetWorthAccountsTableDrawer />}
              />
              {/* TODO: will be added once link is provided */}
              {/* <Box display={'flex'} alignItems='flex-start'>
                                <Button
                                    marginLeft='auto'
                                    variant='link'
                                    fontSize='0.9333rem'
                                    content='Download your assets breakdown (XLS)'
                                />
                                </Box> */}
            </Box>
          </>
        )}
      </LoadingSpinner>
    </Box>
  );
};

export default BreakDownDetails;
