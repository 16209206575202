enum JWTValues {
  nbf = 'nbf',
  exp = 'exp',
  name = 'name',
  unique_name = 'unique_name',
  sviuserid = 'sviuserid',
  first_name = 'first_name',
  guid = 'guid',
}

export default JWTValues;
