import { createContext, FC, useContext, useState } from 'react';

import Transmitigator, { TransmitRef } from '@ally/transmitigator';
import { useConfig } from '../../hooks';
import TransmitToken from '../../models/TransmitToken';

export interface ITransmitContext {
  transmitRef: TransmitRef;
  transmitToken: TransmitToken;
  setTransmitToken: (token: TransmitToken) => void;
}

const TransmitContext = createContext<ITransmitContext>({} as ITransmitContext);

export const useTransmitRef = (): ITransmitContext =>
  useContext(TransmitContext);

export const TransmitProvider: FC = ({ children }) => {
  const { transmitConfig } = useConfig();
  const [transmitToken, setTransmitToken] = useState(new TransmitToken());

  const transmitigator = Transmitigator({
    server: transmitConfig.baseUrl,
    application: {
      id: 'ALLYUSBOLB',
      name: 'AOB',
      version: '1.0',
      apigeeApiKey: transmitConfig.apiKey,
    },
  });

  const hookInstance = transmitigator.useTransmit();

  const transmitRef = {
    ...transmitigator,
    useTransmit: () => hookInstance,
  };

  return (
    <TransmitContext.Provider
      value={{
        transmitRef,
        transmitToken,
        setTransmitToken,
      }}
    >
      {children}
    </TransmitContext.Provider>
  );
};
