import {
  createContext,
  useReducer,
  PropsWithChildren,
  Dispatch,
  useEffect,
  useContext,
} from 'react';
import { AppCache, Session } from '../models';
import { Action } from './actions';
import { reducer } from './reducer';

type Context = { state: State; dispatch: Dispatch<Action> };

interface State {
  session: Session;
  appCache: AppCache;
}

export const initialState: State = {
  session: new Session(),
  appCache: new AppCache(),
};

const initialStoreContext: Context = {
  state: initialState,
  dispatch: (_a) => {},
};

const storeContext = createContext(initialStoreContext);
const useStore = () => useContext(storeContext);

const { Provider } = storeContext;

const StateProvider = ({ children }: PropsWithChildren<any>) => {
  const STORAGE_KEY = 'WEALTH-TS';

  const [state, dispatch] = useReducer(
    reducer,
    initialStoreContext.state,
    (state) => {
      const persistedData = sessionStorage.getItem(STORAGE_KEY);
      const newState = persistedData ? JSON.parse(persistedData) : initialState;
      // Add all bottom level state objects here or they will not persist to new tabs and manual page refreshes
      return {
        ...state,
        session: newState.session,
        appCache: newState.appCache,
      };
    }
  );

  useEffect(() => {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  }, [state]);

  // Use the new value on sessionStorage change
  useEffect(() => {
    window.addEventListener('storage', () => {
      const persistedData = sessionStorage.getItem(STORAGE_KEY);
      const newData = persistedData
        ? (JSON.parse(persistedData) as State)
        : null;

      if (newData) {
        dispatch({ type: 'SYNC_REQUEST', payload: newData });
      }
    });
  }, []);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { storeContext, StateProvider, initialStoreContext, useStore };
export default State;
