enum AccountTypes {
  savings = 0,
  checking = 1,
  ira = 2,
  cd = 3,
  credit = 4,
  mortgage = 5,
  invest = 6,
}

export default AccountTypes;
