import React from 'react';
import { WealthAccount } from '../../../models';
import { Card, PerformanceWidget } from '../../molecules';

const AccountPerformanceWidget: React.FC<IProps> = (props) => {
  return (
    <Card title='Performance'>
      <PerformanceWidget
        account={props.account}
        valueLabel='Account Value'
        idPrefix='acc-perf'
      />
    </Card>
  );
};

interface IProps {
  account: WealthAccount;
}

export default AccountPerformanceWidget;
