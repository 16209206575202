import Loader from '../Loader';
import {
  TextHeading,
  TextHeadingProps,
} from '@ally/metronome-ui/dist/cjs/Typography/Text';

// Had to manually add the props from metronome since HeadingProps isn't exported
// will request to export this in their office hours
interface IProps extends TextHeadingProps {
  isLoading?: boolean;
}

const Heading: React.FC<IProps> = (props) => {
  return (
    <>{props.isLoading ? <Loader height='m' /> : <TextHeading {...props} />}</>
  );
};

export default Heading;
