import { createContext, useEffect, useState } from 'react';

interface IProps {}

interface ViewportProps {
  width: number;
  height: number;
}

const initialViewportContext: ViewportProps = {
  width: window.innerWidth,
  height: window.innerHeight,
};

export const viewportContext = createContext(initialViewportContext);

export const ViewportProvider: React.FC<IProps> = ({
  children,
}: React.PropsWithChildren<IProps>) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};
