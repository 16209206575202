import { LinkToken } from '.';

interface IPlaid {
  linkToken: LinkToken;
}

class Plaid implements IPlaid {
  linkToken: LinkToken = new LinkToken();
}

export { Plaid };
export type { IPlaid };
