class TransmitToken {
  access_token: string = '';
  userNamePvtEncrypt: string = '';
  expirationDate: Date | undefined = undefined;

  constructor(
    access_token: string = '',
    userNamePvtEncrypt: string = '',
    expirationDate: Date | undefined = undefined
  ) {
    const newExpiration = new Date();
    // set the new expiration to current time + 4 minutes and 30 seconds
    newExpiration.setMinutes(newExpiration.getMinutes() + 4, 30);
    this.access_token = access_token;
    this.userNamePvtEncrypt = userNamePvtEncrypt;
    this.expirationDate = expirationDate || newExpiration;
  }
}

export default TransmitToken;
