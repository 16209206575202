import React, { useEffect, useState } from 'react';
import { useAllyTm, usePlaid, useWealthController } from '../../../hooks';
import { BreakDownWidget, NetWorthDetailsWidget } from '../../organisms';
import DefaultTemplate from '../../templates/DefaultTemplate';

interface IProps {}

const NetWorthDetailsPage: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const {
    setWealthOverviewIsLoading,
    getWealthOverview,
    getNetWorthDetails,
    getNetWorthPerformance,
  } = useWealthController();
  const { startPolling, pollingStatus } = usePlaid();
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    allyTmEvent('pageview', 'Wealth:Net Worth Details');
    getWealthOverview();
    getNetWorthDetails();
    getNetWorthPerformance();
  }, []);

  useEffect(() => {
    if (pollingStatus === 'polling') {
      setWealthOverviewIsLoading();
    } else if (pollingStatus === 'success') {
      getWealthOverview();
      getNetWorthPerformance();
      setRefresh(refresh + 1);
    } else if (pollingStatus === 'failed') {
      setWealthOverviewIsLoading(false);
    }
  }, [pollingStatus]);

  return (
    <DefaultTemplate>
      <NetWorthDetailsWidget onSuccess={startPolling} refresh={refresh} />
      <BreakDownWidget onSuccess={startPolling} refresh={refresh} />
    </DefaultTemplate>
  );
};

export default NetWorthDetailsPage;
