import styled from 'styled-components';
import { Box, TextBody, Button, Icon } from '@ally/metronome-ui';
import { muiChatOutline } from '@ally/metronome-icons/dist/cjs';
import { Link } from '../../atoms';

const NavBox = styled(Box)`
  display: flex;
  width: 100%;
  max-width: 1330px;
  margin: 0 auto;
  align-items: center;
  padding: 10px 10px 0 10px;
  ${({ theme: { media } }): string => `
    ${media.up('md')} {
      padding: 10px 20px 0 20px;
  }`}
`;

const StyledText = styled(TextBody)`
  font-weight: bold;
  margin-left: 5px;
`;

const StyledLink = styled(Link)`
  margin: 0 5px;
`;

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

const StyledMuiIcon = styled(Icon)`
  margin: auto 2px;
`;

interface IProps {}

// TODO: Get aria labels for phone number and live chat
const ContactNav: React.FC<IProps> = (props) => {
  return (
    <NavBox>
      <Box marginLeft='auto' display='flex' alignItems='center' flexWrap='wrap'>
        <StyledText tag='strong' size='sm'>
          Investments:
        </StyledText>
        <StyledLink
          to='tel:+1-855-880-2559'
          text='1-855-880-2559'
          aria-label='Call us at 1-855-880-2559'
        />
        {/* TODO add back in when we have chat available
        <StyledButton
          variant='link'
          aria-label='Start a live chat'
          content={
            <Box display='flex' alignItems='center'>
              <StyledMuiIcon muiIcon={muiChatOutline} ariaHidden />
              <Box>Chat</Box>
            </Box>
          }
        /> */}
      </Box>
    </NavBox>
  );
};

export default ContactNav;

// In case we want to match what's in Live with the mail link,
// will also need to get aria for this button
/*
  <Link
    to='mailto:support@invest.ally.com'
    content={
      <Box display='flex' alignItems='center'>
        <StyledMuiIcon muiIcon={muiMailClosed} ariaHidden />
        <Box>Email</Box>
      </Box>
    }
  />
*/
