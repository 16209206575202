import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Spinner, Box, TextBody } from '@ally/metronome-ui';

const CustomSpinner = styled(Spinner)`
  position: relative;
  left: 50%;
  right: 50%;
`;

const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;

interface IProps {
  isLoading: boolean;
  color?: 'white' | 'grey';
  height?: string;
  isSlowMessage?: string;
}

const LoadingSpinner: React.FC<IProps> = (
  props: React.PropsWithChildren<IProps>
) => {
  const [timeoutExpired, setTimeoutExpired] = useState<boolean>(false);

  useEffect(() => {
    if (props.isSlowMessage) {
      setTimeout(function () {
        setTimeoutExpired(true);
      }, 15000);
    }
  }, []);

  return (
    <>
      {props.isLoading ? (
        <Box textAlign={props.isLoading ? 'center' : 'left'}>
          <StyledBox height={props.height}>
            <CustomSpinner
              themeColor={props.color === 'white' ? 'dark' : 'light'}
            />
            {timeoutExpired && (
              <Box position='relative' top='36px' width='100%'>
                <TextBody tag='p' size='md'>
                  {props.isSlowMessage}
                </TextBody>
              </Box>
            )}
          </StyledBox>
        </Box>
      ) : (
        props.children
      )}
    </>
  );
};

export default LoadingSpinner;
