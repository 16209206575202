import { useEffect, useState } from 'react';
import { useAllyTm } from '../../hooks';

/**
 * Use hook params if you want to use the hook to manage the state of showing/masking the account number.
 * Leave them blank if you have multiple account numbers in one component to mask or don't want the hook to
 * manage the show/mask state. Use the maskAccountNumber function to mask instead.
 * @param accountNumber Account number to be masked
 * @param show Boolean to show or hide the full account number and mask the rest
 */
const useAccountNumberMask = (
  accountNumber: string = '',
  show: boolean = false
) => {
  const [showAccountNumber, setShowAccountNumber] = useState<boolean>(show);
  const [maskedAccountNumber, setMaskedAccountNumber] = useState<string>(
    accountNumber
  );
  const { allyTmEvent } = useAllyTm();

  useEffect(() => {
    setMaskedAccountNumber(maskAccountNumber(accountNumber, showAccountNumber));
  }, [showAccountNumber, accountNumber]);

  /**
   * Use this when you have muiple account numbers to make in one component and
   * don't need to toggle between showing and hiding them.
   * @param account Account number to mask
   * @param show Boolean to show or hide the full account number and mask the rest
   * @returns Account number that is either masked or unmasked
   */
  const maskAccountNumber = (
    account: string,
    show: boolean = false,
    numDots: number = 0
  ) => {
    if (show) {
      return account;
    } else if (numDots) {
      return account && `${'•'.repeat(numDots)}${account.slice(-4)}`;
    } else {
      return account && `${'•'.repeat(account.length - 4)}${account.slice(-4)}`;
    }
  };

  return {
    maskAccountNumber,
    maskedAccountNumber,
    showAccountNumber,
    toggleMask: () => {
      setShowAccountNumber(!showAccountNumber);
      allyTmEvent('clickEvent', {
        tagName: 'button',
        allytmln: 'toggleMaskAccountNumber',
        href: accountNumber,
      });
    },
    setShowAccountNumber,
  };
};

export default useAccountNumberMask;
