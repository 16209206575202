interface IHouseholdIds {
  allyHouseholdId: number | undefined;
  orionHouseholdId: number | undefined;
}

class HouseholdIds implements IHouseholdIds {
  allyHouseholdId: number | undefined;
  orionHouseholdId: number | undefined;

  constructor(allyHouseholdId?: number, orionHouseholdId?: number) {
    this.allyHouseholdId = allyHouseholdId;
    this.orionHouseholdId = orionHouseholdId;
  }
}

export { HouseholdIds };
export type { IHouseholdIds };
