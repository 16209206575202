interface IGoalOwner {
  name: string;
  lifeExpectancyStartYear: number;
  lifeExpectancyEndYear: number;
}

class GoalOwner implements IGoalOwner {
  name: string;
  lifeExpectancyStartYear: number;
  lifeExpectancyEndYear: number;

  constructor(
    name: string = '',
    lifeExpectancyStartYear: number = 0,
    lifeExpectancyEndYear: number = 0
  ) {
    this.name = name;
    this.lifeExpectancyStartYear = lifeExpectancyStartYear;
    this.lifeExpectancyEndYear = lifeExpectancyEndYear;
  }
}

export { GoalOwner };
export type { IGoalOwner };
