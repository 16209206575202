import {
  Box,
  Button,
  TableColumn,
  TextBody,
  useModalContext,
} from '@ally/metronome-ui';
import { SortableColumn } from '@ally/metronome-ui/dist/cjs/Table/types';
import colors from '@ally/metronome-ui/dist/cjs/tools/theme/theme-items/colors';
import { useEffect, useState } from 'react';
import { useFormatter, useViewport, useWealthController } from '../../../hooks';
import { IWidgetProps } from '../../../interfaces';
import { HistoricalDetail } from '../../../models';
import { ColorsList } from '../../../types';
import { Currency, Highchart, LoadingSpinner } from '../../atoms';
import { TableViewModal } from '../../molecules';

interface IProps extends IWidgetProps {}

const SM_SCREEN_BREAKPOINT = 620;

const CurrencyCell = ({ cell }: SortableColumn): React.ReactNode | string => (
  <Currency value={cell.value} />
);

const NetWorthOverTimeChart: React.FC<IProps> = (props: IProps) => {
  const context = useModalContext();
  const { netWorthPerformance, netWorthDetails } = useWealthController();
  const { dateTimeFormatter, numberFormatter, dateToString } = useFormatter();
  const { width } = useViewport();
  const [chartOptions, setChartOptions] = useState({});

  const columns: TableColumn<HistoricalDetail[]>[] = [
    {
      Header: 'DATE',
      accessor: 'month',
      alignment: 'left',
      Cell: ({ cell }: SortableColumn): React.ReactNode | string => {
        const date = new Date(
          cell.row.original.year,
          cell.row.original.month - 1,
          1
        );
        return `${dateTimeFormatter(date, false, {
          month: 'short',
        })}, ${dateTimeFormatter(date, false, { year: 'numeric' })}`;
      },
    },
    {
      Header: 'ASSETS',
      accessor: 'assets',
      alignment: 'center',
      Cell: CurrencyCell,
    },
    {
      Header: 'LIABILITIES',
      accessor: 'liabilities',
      alignment: 'center',
      Cell: CurrencyCell,
    },
    {
      Header: 'NET WORTH',
      accessor: 'netWorth',
      alignment: 'right',
      Cell: CurrencyCell,
    },
  ];

  useEffect(() => {
    setChartOptions({
      chart: {
        zoomType: 'xy',
        reflow: false,
        spacing: [10, 0, 15, 0],
      },
      title: {
        style: {
          display: 'none',
        },
        text: '',
      },
      subtitle: {
        text: netWorthDetails.lastUpdatedDate
          ? `Last updated as of ${dateToString(
              new Date(netWorthDetails.lastUpdatedDate)
            )}.`
          : '',
        align: width < SM_SCREEN_BREAKPOINT ? 'center' : 'right',
        verticalAlign: 'bottom',
        y: 28,
        style: {
          fontSize: width < SM_SCREEN_BREAKPOINT ? '0.6667rem' : '0.9444rem',
          color: ColorsList.title,
        },
      },
      xAxis: [
        {
          labels: {
            step: width < SM_SCREEN_BREAKPOINT ? 4 : 2,
            style: {
              color: colors['slate-5'],
              fontSize: '0.6667rem',
            },
            overflow: false,
          },
          categories: netWorthPerformance.historicalDetails.map((detail) => {
            return dateTimeFormatter(
              new Date(detail.year, detail.month - 1, 1),
              false,
              {
                month: 'short',
                year: 'numeric',
              }
            );
          }),
          lineWidth: 0,
        },
      ],
      yAxis: [
        {
          title: {
            text: '',
          },
          labels: {
            style: {
              color: colors['slate-5'],
              fontSize: '0.6667rem',
            },
            formatter: function () {
              const self: any = this;
              return `${numberFormatter(self.value, {
                currency: 'USD',
                notation: 'compact',
                minimumFractionDigits: 0,
              })}`;
            },
          },
          gridLineColor: colors['grey-80'],
          gridLineDashStyle: 'Dash',
          opposite: true,
        },
        {
          title: {
            text: '',
          },
          labels: {
            style: {
              color: colors['slate-5'],
              fontSize: '0.6667rem',
            },
            formatter: function () {
              const self: any = this;
              return `${numberFormatter(self.value, {
                currency: 'USD',
                notation: 'compact',
                minimumFractionDigits: 0,
              })}`;
            },
          },
          gridLineColor: colors['grey-80'],
          gridLineDashStyle: 'Dash',
          opposite: true,
        },
      ],
      tooltip: {
        formatter: function () {
          const self: any = this;
          return `<b>${self.series.name}</b><br/>
                        <span style="color:${self.color}">\u25CF</span>
                        ${numberFormatter(Math.abs(self.y), {
                          currency: 'USD',
                          notation: 'compact',
                          maximumFractionDigits: 1,
                        })}<br/>
                        ${self.key}`;
        },
      },
      legend: {
        floating: true,
        layout: 'horizontal',
        align: width < SM_SCREEN_BREAKPOINT ? 'center' : 'left',
        verticalAlign: 'bottom',
        y: width < SM_SCREEN_BREAKPOINT ? 0 : 20,
        x: -10,
        itemDistance: 30,
        itemStyle: {
          color: colors['slate-5'],
          fontSize: '0.8333rem',
          fontWeight: 'normal',
        },
      },
      plotOptions: {
        column: {
          pointWidth: width < SM_SCREEN_BREAKPOINT ? 22 : 32,
          stacking: 'normal',
        },
      },
      series: [
        {
          name: 'Assets',
          type: 'column',
          data: netWorthPerformance.historicalDetails.map(
            (detail) => detail.assets
          ),
          color: ColorsList.assets,
          borderRadiusTopLeft: 4.7,
          borderRadiusTopRight: 4.7,
          states: {
            hover: {
              color: ColorsList.assets,
              brightness: 0,
            },
          },
        },
        {
          name: 'Liabilities',
          type: 'column',
          data: netWorthPerformance.historicalDetails.map(
            (detail) => detail.liabilities * -1
          ),
          color: ColorsList.liabilities,
          borderRadiusBottomLeft: 4.7,
          borderRadiusBottomRight: 4.7,
        },
        {
          name: 'Net Worth',
          type: 'spline',
          data: netWorthPerformance.historicalDetails.map(
            (detail) => detail.netWorth
          ),
          marker: {
            enabled: false,
          },
          dashStyle: 'Dash',
          color: ColorsList.netWorth,
        },
      ],
      lang: {
        noData:
          'This information isn’t available right now. Try again later, or call us for help.',
      },
      noData: {
        style: {
          fontWeight: 'normal',
          fontSize: '0.9999rem',
          fontFamily: 'inherit',
          color: colors['slate-5'],
        },
        useHTML: true,
      },
    });
  }, [
    width,
    netWorthDetails.hasLoaded,
    netWorthPerformance.hasLoaded,
    props.refresh,
  ]);

  return (
    <LoadingSpinner
      color='white'
      isLoading={netWorthPerformance.isLoading}
      isSlowMessage='Graph is still loading.'
    >
      <>
        <Box display={'flex'} paddingBottom='10px' alignItems='flex-start'>
          <TextBody tag='b' size='sm' weight='bold'>
            Net Worth Over Time
          </TextBody>

          <Button
            allytmln='tableViewNetWorthPerformance'
            variant='link'
            text='Table View'
            marginLeft='auto'
            onClick={() => context.toggleModal()}
          />
        </Box>
        <Highchart
          id='net-worth-over-time'
          height='326px'
          width='100%'
          chartOptions={chartOptions}
        />

        <TableViewModal
          context={context}
          data={netWorthPerformance.historicalDetails}
          columns={columns}
          captionText='NetWorth over time table view'
        />
      </>
    </LoadingSpinner>
  );
};

export default NetWorthOverTimeChart;
