import { muiCaretDown, muiCaretUp } from '@ally/metronome-icons/dist/cjs';
import { Box, Icon, TextBody } from '@ally/metronome-ui';
import styled from 'styled-components';
import { Loader } from '..';

const StyledIcon = styled(Icon)`
  align-self: center;
  padding-left: 4px;
`;

interface IProps {
  value: number;
  isLoading: boolean;
  suffix?: string;
}

const NumberCaret = ({ value, isLoading, suffix }: IProps) => {
  return (
    <>
      {isLoading ? (
        <Box flex={1} height='15px'>
          <Loader width='m' height='s' />
        </Box>
      ) : (
        <>
          <StyledIcon
            icon={value > 0 ? muiCaretUp : muiCaretDown}
            size='md'
            fill='black'
          />
          <TextBody tag='span' size='lg'>
            &nbsp;{value}
            {suffix}&nbsp;
          </TextBody>
        </>
      )}
    </>
  );
};

export default NumberCaret;
