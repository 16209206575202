import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { muiAllyTheme } from '../../../utils';
import Loader from '../Loader';

// Currency formatting is based on the Intl.NumberFormat, reference this link for more information
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat

interface IProps {
  value: number;
  isSigned?: boolean;
  isColorCoded?: boolean;
  locales?: string;
  formatOptions?: Intl.NumberFormatOptions;
  isLoading?: boolean;
  hasError?: boolean;
}

const Currency: React.FC<IProps> = (props) => {
  const [formatted, setFormatted] = useState<string>();
  const [color, setColor] = useState<string>('');

  const StyledSpan = styled.span`
    color: ${color};
  `;

  useEffect(() => {
    var formatter = new Intl.NumberFormat(
      props.locales ? props.locales : 'en-US',
      { ...{ style: 'currency', currency: 'USD' }, ...props.formatOptions }
    );
    setFormatted(formatter.format(props.value));
    if (props.isColorCoded) {
      setColor(
        props.value >= 0
          ? muiAllyTheme.colors.success
          : muiAllyTheme.colors.error
      );
    } else {
      setColor('');
    }
  }, [props]);

  return (
    <>
      {props.isLoading ? (
        <Loader height='m' />
      ) : (
        <StyledSpan>
          {props.isSigned && props.value >= 0 && '+'}
          {props.hasError ? '--' : formatted}
        </StyledSpan>
      )}
    </>
  );
};

export default Currency;
