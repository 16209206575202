import { useContext, useEffect, useState } from 'react';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { storeContext } from '../../store/store';
import { HouseholdIds, Session, Token, TokenJWT } from '../../models';
import { useCookie, useJWT, useConfig, useTransmit } from '..';
import {
  deleteLocalStorage,
  deleteSessionStorage,
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from '../../utils/StorageUtil';
import { clearCookies } from '../../utils/CookieUtil';
import { makeGuid } from '../../utils';
import { useTransmitRef } from '../../contexts/transmitContext';
import { useBroadcastRef } from '../../contexts/messagingContext';

const version: String = 'v2';

/***
 * mainSession: Boolean, Only set this to true in the main session instance in app.tsx to ensure effects only get ran once
 */
const useSession = (mainSession?: boolean) => {
  const { state, dispatch } = useContext(storeContext);
  const { getCookie } = useCookie();
  const { decodeJWT } = useJWT();
  const { investConfig, wealthConfig, transmitConfig } = useConfig();
  const [sessionTimer, setSessionTimer] = useState<NodeJS.Timeout>();
  const { refreshTransmitToken, initTransmit, logoutTransmit } = useTransmit();
  const { transmitToken } = useTransmitRef();
  const {
    broadcastRef: { broadcastDispatch },
  } = useBroadcastRef();

  const instance: AxiosInstance = axios.create({
    baseURL: investConfig.baseUrl,
  });

  const proxyInstance: AxiosInstance = axios.create({
    baseURL: wealthConfig.proxyUrl,
  });

  useEffect(() => {
    let tmpSessionTimer: NodeJS.Timeout;
    if (mainSession && state.session.IDPToken.token) {
      if (transmitConfig.useTransmit && !transmitToken.access_token) {
        initTransmit(true);
      }
      refreshSessionTimer();
      tmpSessionTimer = setInterval(() => {
        if (
          new Date() > new Date(getLocalStorage('sessionExpiry') || new Date())
        ) {
          logout();
          sessionTimer && clearInterval(sessionTimer);
        }
      }, 5000);
      setSessionTimer(tmpSessionTimer);
    }
    return () => {
      if (mainSession && sessionTimer) {
        clearInterval(sessionTimer);
      }
    };
  }, [state.session.username]);

  useEffect(() => {
    if (mainSession && !!state.session.IDPToken.token) {
      const interval = setInterval(() => {
        refreshIDPToken();
        refreshTransmitToken();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [transmitToken?.expirationDate, state?.session?.IDPToken?.token]);

  const refreshSessionTimer = () => {
    if (state.session.IDPToken.token) {
      const newExpiry = new Date();
      newExpiry.setMinutes(newExpiry.getMinutes() + 15);
      setLocalStorage('sessionExpiry', newExpiry);
      getOrionToken();
    }
  };

  const login = async (token: string, refreshToken: string) => {
    deleteLocalStorage('logout');
    const tokenObject = decodeJWT(token);
    const username = tokenObject.unique_name;

    const houseHoldIds =
      ((await getHouseholdId(token, username)) as HouseholdIds) ||
      new HouseholdIds();
    const orionToken =
      (await getOrionToken(token, houseHoldIds.allyHouseholdId)) || new Token();

    const expireDate = new Date(+tokenObject.exp * 1000);
    const expiresIn = expireDate.getTime() - new Date().getTime();
    const newSession: Session = new Session(
      username,
      '',
      tokenObject.sviuserid,
      tokenObject.guid,
      new TokenJWT(token, refreshToken, expiresIn, 'Bearer', false),
      orionToken,
      houseHoldIds
    );
    refreshSessionTimer();
    dispatch({ type: 'LOGIN', payload: newSession });
    return token;
  };

  /**
   * Function used to update a prospects session to get their household ids and orion token
   * now that they have a wealth relationship
   */
  const updateProspectSession = async () => {
    const householdIds =
      ((await getHouseholdId(
        state.session.IDPToken.token,
        state.session.username
      )) as HouseholdIds) || new HouseholdIds();
    const orionToken =
      (await getOrionToken(
        state.session.IDPToken.token,
        householdIds.allyHouseholdId
      )) || new Token();
    dispatch({
      type: 'LOGIN',
      payload: { ...state.session, householdIds, orionToken },
    });
  };

  const getIDPToken = async (username: string, password: string) => {
    // TODO: Remove before going to prod
    const IDPTokenData = new URLSearchParams();
    IDPTokenData.append('username', username); //'ipXYyBhJpr'
    IDPTokenData.append('password', password); //'p'
    dispatch({ type: 'SET_IDP_TOKEN_IS_LOADING', payload: true });
    const {
      data: token,
    }: AxiosResponse<{
      access_token: string;
      expires_in: number;
      token_type: string;
    }> =
      ((await proxyInstance
        .post('/identity/connect/token', IDPTokenData, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .catch((error: Error) => {
          dispatch({ type: 'SET_IDP_TOKEN_IS_LOADING', payload: false });
          return Promise.reject(error);
        })) as AxiosResponse) || {};
    if (token) {
      const IDPToken = new Token(
        token.access_token,
        '',
        token.expires_in,
        token.token_type,
        false
      );
      return IDPToken.token;
    }
  };

  const getOrionToken = async (IDPToken: string = '', householdId?: number) => {
    const ssoToken = IDPToken || state.session.IDPToken.token;
    const allyHouseholdId =
      householdId || state.session.householdIds.allyHouseholdId;
    if (ssoToken && allyHouseholdId) {
      const headersConfig = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${state.session.IDPToken.tokenType} ${ssoToken}`,
        },
      };
      dispatch({ type: 'SET_ORION_TOKEN_IS_LOADING', payload: true });
      const {
        data: token,
      }: AxiosResponse<{
        access_token: string;
        expires_in: number;
        token_type: string;
      }> =
        ((await instance
          .get(
            `/wealth-service/${version}/users/token/${allyHouseholdId}`,
            headersConfig
          )
          .catch(() => {
            dispatch({ type: 'SET_ORION_TOKEN_IS_LOADING', payload: false });
          })) as AxiosResponse) || {};
      if (token) {
        return new Token(
          token.access_token,
          '',
          token.expires_in,
          token.token_type,
          false
        );
      }
      return new Token();
    }
  };

  const getHouseholdId = async (IDPToken: string, username: string) => {
    const ssoToken = IDPToken || state.session.IDPToken.token;
    const headersConfig = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${state.session.IDPToken.tokenType} ${ssoToken}`,
      },
    };
    const { data: householdID }: AxiosResponse<HouseholdIds> =
      ((await instance
        .get(
          `/wealth-service/${'v1'}/users/${username}/households`,
          headersConfig
        )
        .catch(() => {})) as AxiosResponse) || {};
    if (householdID) {
      return householdID;
    }
  };

  const refreshIDPToken = async () => {
    const currentDate = new Date();
    const expiresOn = new Date(state.session.IDPToken.expiresOn);
    const isExpired = expiresOn.getTime() < currentDate.getTime();

    if (!isExpired) {
      return;
    }

    let newToken = new TokenJWT();
    if (state?.session?.IDPToken?.refreshToken) {
      (await proxyInstance
        .post(
          '/apigee/refresh',
          { token: transmitToken.access_token },
          {
            headers: { correlationid: makeGuid() },
            withCredentials: true,
          }
        )
        .catch((err: Error) => {
          proxyInstance.post('/log', {
            message: `*TRANSMIT* failed to refresh IDP token. Error: ${err.message}`,
            level: 'error',
            timeStamp: new Date(),
            user: state.session.guid,
          });
        })) as AxiosResponse;

      const newJwt = getCookie('jwt');
      const tokenObject = decodeJWT(newJwt);
      const expireDate = new Date(+tokenObject.exp * 1000);
      const expiresIn = expireDate.getTime() - new Date().getTime();
      newToken = new TokenJWT(
        getCookie('jwt'),
        getCookie('refreshToken'),
        expiresIn,
        'Bearer',
        false
      );
      dispatch({ type: 'SET_IDP_TOKEN', payload: newToken });
    } else {
      logout();
    }
    return newToken;
  };

  const logout = () => {
    window.location.href = '/logout';
  };

  const clearSession = async () => {
    clearCookies();
    deleteLocalStorage('sessionExpiry');
    deleteSessionStorage('jwtToken');
    deleteSessionStorage('WEALTH-TS');
    broadcastDispatch({ type: 'LOGOUT' }, true);
    dispatch({ type: 'BEGIN_LOGOUT', payload: false });

    await logoutTransmit();
  };

  return {
    session: state.session,
    isLoggedIn: !!state.session.IDPToken.token,
    login,
    updateProspectSession,
    logout,
    clearSession,
    getIDPToken,
    refreshIDPToken,
    getOrionToken,
    IDPToken: state.session.IDPToken,
    orionToken: state.session.orionToken,
    getHouseholdId,
    getSessionStorage,
    setSessionStorage,
    deleteSessionStorage,
    refreshSessionTimer,
    getLocalStorage,
    setLocalStorage,
    deleteLocalStorage,
  };
};

export default useSession;
