import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../../../interfaces';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const HomeIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ? props.width : '36px'}
      height={props.height ? props.height : '36px'}
      viewBox='0 0 36 36'
      version='1.1'
      aria-hidden={true}
    >
      <g
        xmlns='http://www.w3.org/2000/svg'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(-211.000000, -244.000000)'>
          <g transform='translate(211.000000, 244.000000)'>
            <g>
              <rect x='0' y='0' width='36' height='36' />
              <g transform='translate(0.000000, 4.153846)'>
                <path
                  d='M11.4183444,5.79512846 L11.4183444,1.53846154 L4.70941465,1.53846154 L4.69565217,10.0227896'
                  stroke='#371447'
                  strokeWidth='2'
                />
                <path d='M17.1616503,1.87895928 L31.3338066,11.2332478 L31.3338066,26.3818359 L3.04891861,26.3818359 L3.04891861,18.691871 L3.03451936,11.0864501 L17.1616503,1.87895928 Z M13.5864259,14.8535156 L7.85550088,14.8535156 L7.85550088,25.8084811 L13.5864259,25.8084811 L13.5864259,14.8535156 Z M26.0125826,14.2325346 L17.520151,14.2325346 L17.520151,20.9287861 L26.0125826,20.9287861 L26.0125826,14.2325346 Z' />
                <path
                  d='M3.28281841,10.0507061 L17.1616503,0.843215321 L31.3338066,10.1975038 L31.3338066,27.1878756 L3.04891861,27.1878756 L3.04891861,10.0507061'
                  stroke='#371447'
                  strokeWidth='2'
                />
                <path
                  d='M16.8474914,0.421248174 L33.1062924,11.5291093 L31.8132223,12.2683651 L16.7663529,2.34325544 L2.57611778,12.413657 C2.25620303,12.1577814 2.00045996,11.9594191 1.80896094,11.8184749 C1.66020652,11.708991 1.46949124,11.5772504 1.23674979,11.4233466 L1.23674979,11.4233466 L16.8474914,0.421248174 Z'
                  stroke='#371447'
                  strokeWidth='0.692186515'
                  fill='#371447'
                />
                <rect
                  stroke='#371447'
                  strokeWidth='1.13043478'
                  x='17.180602'
                  y='14.4113712'
                  width='8.56187291'
                  height='6.90265345'
                />
                <rect
                  stroke='#371447'
                  strokeWidth='1.13043478'
                  fill='#371447'
                  x='8.30769231'
                  y='15.2307692'
                  width='5.73092501'
                  height='11.3320136'
                />
                <path
                  d='M1.04094511e-12,26.7760181 C0.0607799929,26.7760181 11.7435425,26.7760181 35.0482877,26.7760181'
                  stroke='#371447'
                />
              </g>
            </g>
            <line
              x1='21.4615385'
              y1='25.0620117'
              x2='21.5'
              y2='18.5'
              stroke='#371447'
            />
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

export default HomeIcon;
