import { TextBody, TextHeading, Box, Space } from '@ally/metronome-ui';
import { LI, UL, Link } from '../../atoms';
import styled from 'styled-components';

const StyledLI = styled(LI)`
  margin-bottom: 8px;
`;
const DiscLI = styled(StyledLI)`
  list-style: disc;
`;

interface IProps {}

const DocumentReviewSubmitted: React.FC<IProps> = (props) => {
  return (
    <Box>
      <TextHeading tag='h1' size='lg'>
        We've received your submission
      </TextHeading>
      <Box width={['100%', '100%', 2 / 3]}>
        <Space marginTop='20px'>
          <TextBody tag='p' size='md'>
            We'll email you once we've opened your new accounts. If we need to
            verify any of your information before we can do that, we'll email
            you details on how to upload additional documents.
          </TextBody>
          <TextBody tag='p' size='md'>
            Once we've opened your new accounts, you can transfer assets on your
            own or with the help of your advisor or your wealth concierge. Here
            are a few ways:
          </TextBody>
          <UL>
            <DiscLI>Transfer money from a bank account</DiscLI>
            <DiscLI>Transfer stocks or other investments</DiscLI>
            <DiscLI>Request a wire transfer</DiscLI>
            <DiscLI>
              Rollover 401(k) and IRA accoutns from other institutions
            </DiscLI>
          </UL>
          <TextBody tag='p' size='md'>
            In the meantime, you'll still have access to your Wealth Overview,
            where you can continue to link accounts and build out your net worth
            view.
          </TextBody>
          <Link
            $internal
            size='sm'
            to='/wealth-overview'
            text='Go to Wealth Overview'
            variant='primary'
          />
        </Space>
      </Box>
    </Box>
  );
};

export default DocumentReviewSubmitted;
