import { MainNavMenu } from '../../molecules';
import { NavLink } from '../../atoms';
import { useNav } from '../../../hooks';
import { INavLinkDetail } from '../../../models';

interface IProps {}

const MainNavItems: React.FC<IProps> = (props) => {
  const {
    navLinkList: { mainNav },
  } = useNav();

  return (
    <>
      {mainNav.map((navItem: INavLinkDetail) => {
        return !navItem.subLinks && !navItem.subComponent ? (
          <NavLink
            to={navItem.link || '#'}
            text={navItem.title}
            key={navItem.title}
            transmitdestination={navItem.transmitdestination}
          />
        ) : (
          <MainNavMenu navItem={navItem} key={navItem.title} />
        );
      })}
    </>
  );
};

export default MainNavItems;
