import { Dejargonator, DejargonatorProps, TextBody } from '@ally/metronome-ui';
import styled from 'styled-components';

const StyledDejargTrigger = styled(TextBody)`
  ${({ theme: { colors } }): string => `
    border-bottom: 1px dotted ${colors['slate-3']};
    width: fit-content;
  `}
`;

interface IProps extends Omit<DejargonatorProps, 'trigger'> {
  triggerText: string;
  triggerAriaLabel?: string;
}

const Popover: React.FC<IProps> = (props: IProps) => {
  return (
    <Dejargonator
      popoverAriaLabel={props.popoverAriaLabel}
      trigger={{
        variant: 'element',
        ariaLabel: props.triggerAriaLabel || props.triggerText,
        content: (
          <StyledDejargTrigger tag='dt' size='sm' weight='bold'>
            {props.triggerText}
          </StyledDejargTrigger>
        ),
      }}
      body={props.body}
      heading={props.heading || ''}
    />
  );
};

export default Popover;
