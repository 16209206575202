import React from 'react';
import LabeledSelect from '../LabeledSelect';

const options = [
  { label: 'None', value: '0' },
  { label: 'S&P 500', value: '1' },
  { label: 'T-Bill Index', value: '2' },
  { label: 'EAFE', value: '3' },
  // { label: 'Custom Blend', value: '4' }, Removed for cash-1062
];

interface IProps {
  id: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  value: string;
}

const BenchmarkSelect: React.FC<IProps> = (props) => {
  return (
    <LabeledSelect
      label='Comparison Index'
      options={options}
      id={props.id}
      onChange={props.onChange}
      value={props.value}
    />
  );
};

export default BenchmarkSelect;
