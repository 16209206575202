import { useEffect } from 'react';
import styled from 'styled-components';
import {
  ScrollToTop,
  Link,
  AccordionCard,
  UL,
  LI,
  Text,
  Loader,
} from '../../atoms/';
import {
  ConsentAccordion,
  AccordionFooterComponent,
  WealthHeading,
  Alert,
} from '../../molecules/';
import { Box, Button, TextBody } from '@ally/metronome-ui';
import {
  useConfig,
  useDownloadURL,
  useAllyTm,
  useCustomersService,
} from '../../../hooks';
import { ProspectAssessment } from '../../../models';
import { useHistory } from 'react-router';

const SignWrapper = styled(Box)`
  > * {
    margin-bottom: 10px;
  }
  > :last-child {
    margin-bottom: 24px;
  }
  > b {
    display: block;
  }
  > a {
    margin: 14px 0 24px;
  }
`;

const FileWrapper = styled(Box)`
  > * {
    margin-bottom: 24px;
  }
  > b {
    display: block;
    margin-bottom: 10px;
  }

  li:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const StyledUL = styled(UL)`
  padding-inline-start: 40px;
  li {
    list-style: disc;
  }
`;

const StyledAlert = styled(Alert)`
  max-width: 615px;
`;

interface IProps {
  nextStep: (shouldSubmit: boolean, saveEsign: boolean) => void;
  inputValues: ProspectAssessment;
  wait?: boolean;
  errorSubmitting?: boolean;
}

const AffiliateAgreement: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const history = useHistory();
  const { getEsignStatus, esignStatus } = useCustomersService();

  useEffect(() => {
    allyTmEvent('pageview', 'Wealth:Prospect:Lead Form:Consent Call');
    allyTmEvent(
      'conversionEvent',
      'Wealth:Prospect:Lead Form:Consent Call',
      'intermediate',
      props.inputValues.assessmentId
    );
  }, []);

  useEffect(() => {
    getEsignStatus();
  }, []);

  const { investDocsRoot, investConfig } = useConfig();
  const { downloadURL } = useDownloadURL();

  const submitAssessment = () => {
    props.nextStep(true, esignStatus.status !== 'ACCEPTED');
  };

  const submitContent = (
    <>
      <Text size='md' tag='p'>
        Before we can give you a call, we need you to verify that you've read
        and understood a few things about our advisors as well as the
        relationship between clients and broker-dealers or investment advisors.
      </Text>
      <UL>
        <LI>
          <Link
            to={`${investConfig.bankUrl}/resources/pdf/invest/aia-form-crs.pdf`}
            text='Form CRS - Ally Invest Advisors '
            target='_blank'
          />{' '}
          (PDF)
        </LI>
        <LI>
          <Link
            to={`${investConfig.bankUrl}/resources/pdf/invest/wealth-form-adv-part2b-supplement.pdf`}
            text='Form ADV 2B '
            target='_blank'
          />{' '}
          (PDF)
        </LI>
      </UL>
      <Text tag='b' size='md'>
        By selecting <b>Agree and Submit</b>, you acknowledge that:
      </Text>
      <StyledUL>
        <LI>
          You've received and can view the Form CRS - Ally Invest Advisors, and
          ADV-2B
        </LI>
        <LI>
          You've printed or downloaded copies of the Ally Invest Information for
          your records
        </LI>
      </StyledUL>
      <StyledAlert
        show={!!props.errorSubmitting}
        variant='error'
        body='Oops! Our system is acting up. Try submitting again or call us for help at 1-855-880-2559.'
        cancellable={false}
      />
    </>
  );

  const footerContent = AccordionFooterComponent(
    <>
      If you have trouble viewing, printing, or downloading the Ally Invest
      Information, call us at{' '}
      <Link
        to='tel:+1-855-880-2559'
        text='1-855-880-2559'
        aria-label='Call us at 1-855-880-2559'
      />
      .
    </>
  );

  return (
    <Box width={['100%', '100%', 2 / 3]}>
      <WealthHeading content="Here's the paperwork." />
      <ScrollToTop />
      <Loader height='m' isLoading={esignStatus.isLoading}>
        <>
          {esignStatus.status !== 'ACCEPTED' ? (
            <ConsentAccordion>
              <AccordionCard title='Sign online'>
                <SignWrapper>
                  <Text size='md' tag='b' weight='bold'>
                    Consent to receive information electronically
                  </Text>
                  <Text size='md' tag='p'>
                    Whether you're new here or already have an account with us,
                    we need your consent to receive certain info from us
                    electronically. From time to time we make changes to the
                    info in this agreement, and depending on what those changes
                    are, we may need to capture your consent again. If you
                    prefer paper statements, providing your consent will not
                    impact any settings on your accounts.
                  </Text>
                  <Text size='md' tag='p'>
                    Your consent will apply to your Ally Bank deposit, Ally
                    Credit Card, Ally Auto, Ally Home and Ally Invest accounts -
                    or whichever combination of those you have with us, plus all
                    disclosures, agreements and other documents relating to
                    those accounts. You agree we may also obtain your electronic
                    signature, which is legally the same as a handwritten
                    signature.
                  </Text>
                  <Text size='md' tag='p'>
                    To receive information electronically, you need: a device
                    with an internet connection, an email address, a way to
                    store these documents, and software to view and save PDF
                    files. Once your account is open, you can update your email
                    address online. We'll notify you of software or hardware
                    changes that could prevent you from accessing or retaining
                    these records.
                  </Text>
                  <Text size='md' tag='p'>
                    You'll have the right to withdraw your consent anytime, at
                    no charge. In most cases, withdrawing your consent means
                    you'll lose online access to any Ally Bank deposit, Ally
                    Credit Card, Ally Auto, Ally Home and Ally Invest accounts.
                    We may also cancel scheduled transactions and you may only
                    receive paper account statements. You can regain access to
                    most of these accounts by logging in and giving consent
                    again. Your Ally Invest account, however, will be restricted
                    to transfer or liquidation only and closed.
                  </Text>
                  <Text size='md' tag='p'>
                    You can{' '}
                    <Link
                      to={`${investDocsRoot}/contact-us/`}
                      target='_blank'
                      text='call us'
                      fontSize='1.067rem'
                    />{' '}
                    if you ever want to withdraw your consent, or to request
                    paper copies of digital documents. Keep in mind, for Ally
                    Invest accounts, we may charge a fee for paper copies of
                    your account information, including statements and trade
                    confirmations.
                  </Text>
                  <Text size='md' tag='p'>
                    By selecting{' '}
                    <Text tag='strong' size='md' weight='bold'>
                      Agree and Continue
                    </Text>{' '}
                    below, you acknowledge that you have read and agreed to the
                    consent above. View more{' '}
                    <Link
                      onClick={() =>
                        downloadURL(
                          `${investDocsRoot}/content/dam/pdf/allyesign/ally-esign-consent.pdf`,
                          'ally-esign-consent.pdf'
                        )
                      }
                      to={`${investDocsRoot}/content/dam/pdf/allyesign/ally-esign-consent.pdf`}
                      target='_blank'
                      text='here'
                      fontSize='1.067rem'
                    />{' '}
                    and save the information for your records.
                  </Text>
                </SignWrapper>
              </AccordionCard>
              <AccordionCard
                title='File away your forms'
                submitButtonContent='Agree and Submit'
                submitCard={submitAssessment}
                disableButton={props.wait}
                footerComponent={footerContent}
              >
                <FileWrapper>{submitContent}</FileWrapper>
              </AccordionCard>
            </ConsentAccordion>
          ) : (
            <>
              <Box paddingBottom='24px'>
                <TextBody tag='span' size='md' weight='bold'>
                  File away your forms
                </TextBody>
              </Box>
              <FileWrapper>
                {submitContent}
                <Button
                  onClick={submitAssessment}
                  size='sm'
                  variant='primary'
                  text='Agree and Submit'
                />
                {footerContent}
              </FileWrapper>
            </>
          )}
        </>
      </Loader>

      <Button
        text='Back to form'
        variant='outline'
        size='sm'
        mt='16px'
        onClick={() => history.go(0)}
      />
    </Box>
  );
};

export default AffiliateAgreement;
