import styled from 'styled-components';
import { Box } from '@ally/metronome-ui';
import { MainNav, SubNav, Footer, ContactNav } from '../../organisms';
import { Row, SkipToMainButton } from '../../atoms';
import { useSession, useViewport } from '../../../hooks';
import React, { useState } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  justify-content: space-between;
  ${({ theme: { colors } }): string => `
    background-color: ${colors['page-gray']};
  `}
`;

const StyledMain = styled.main`
  flex: 1 0 auto;
  min-height: 300px;
  max-width: 1330px;
  margin: 0 auto;
  width: 100%;
`;

const StyledFooter = styled.footer`
  margin-top: 40px;
  flex-shrink: 0;
  max-width: 1330px;
  margin: 40px auto 0 auto;
  width: 100%;
`;

interface IProps {
  footer?: React.ReactNode;
  hideSubNav?: boolean;
}

const DefaultTemplate: React.FC<IProps> = (props) => {
  const { isLoggedIn } = useSession();
  const { isLessThanLarge } = useViewport();
  const [showSideNav, setShowSideNav] = useState<boolean>(false);

  return (
    <Wrapper>
      <Box marginBottom='40px'>
        <SkipToMainButton></SkipToMainButton>
        <MainNav
          showSideNav={showSideNav}
          setShowSideNav={setShowSideNav}
          hideSubNav={props.hideSubNav}
        />
        {isLoggedIn && !isLessThanLarge && !props.hideSubNav && <SubNav />}
        {!isLessThanLarge && <ContactNav />}
      </Box>
      <StyledMain aria-hidden={showSideNav}>
        <Row>{props.children}</Row>
      </StyledMain>
      <StyledFooter aria-hidden={showSideNav}>
        {props.footer ? props.footer : <Footer></Footer>}
      </StyledFooter>
    </Wrapper>
  );
};

export default DefaultTemplate;
