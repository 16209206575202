import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references

interface IResource {
  id: string;
  email: string;
}

interface ITimeslot extends IAPIData {
  workTypeId: string;
  territoryId: string;
  startTime: Date;
  resources: IResource[];
  endTime: Date;
}

class Timeslot extends APIData implements ITimeslot {
  workTypeId: string = '';
  territoryId: string = '';
  defaultYear = 1900;
  // Set year to 1900 so that we can check if a user selected an appointment or it's just a default date
  startTime: Date = new Date(new Date().setFullYear(this.defaultYear));
  resources: IResource[] = [];
  endTime: Date = new Date();
  dataName = 'Timeslot';

  setData(input: Timeslot) {
    Object.assign(this, input);
    this.setLoaded();
  }
}

export { Timeslot };
export type { ITimeslot, IResource };
