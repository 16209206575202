import { forwardRef, Ref } from 'react';
import { TextBody, TextBodyProps } from '@ally/metronome-ui';

interface IProps extends Omit<TextBodyProps, 'size'> {
  ref?: React.Ref<unknown>;
}

const WidgetLabel: React.FC<IProps> = forwardRef((props, ref: Ref<unknown>) => (
  <TextBody tag={props.tag} size='xs' weight='bold' ref={ref} tabIndex={-1}>
    {props.children}
  </TextBody>
));

export default WidgetLabel;
