import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Dejargonator, TextBody } from '@ally/metronome-ui';
import { Card } from '../../molecules';
import AccountDetailsDrawer from '../AccountDetailsDrawer';
import { useAccountNumberMask, useWealthController } from '../../../hooks';
import { WealthAccount } from '../../../models';
import { Currency, Heading, Text } from '../../atoms';

const AccountNumberDL = styled.dl`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  > dt::after {
    content: ' ';
    white-space: pre;
  }
  > dd {
    padding-right: 20px;
  }
`;

// Remove for inv-3971, add back when earnings are added back
//grid-template-areas: 'value earnings return yield';
//grid-template-columns: 1fr 1fr 1fr 1fr;
const AccountValuesDL = styled.dl`
  margin: 0;
  display: grid;
  column-gap: 50px;
  grid-template-areas: 'value return yield';
  grid-template-columns: 1fr 1fr 1fr;
  ${({ theme: { media } }): string => `
    ${media.up('xl')} {
      margin-left: auto;
      column-gap: 60px;
    }
  `}
  ${({ theme: { media } }): string => `
    ${media.down('lg')} {
      dd.value {
        font-size: 1.0666rem;
      }
    }
  `}
  ${({ theme: { media } }): string => `
    ${media.between('md', 'sm')} {
      grid-template-areas:
        'value yield'
        'earnings return';
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
      column-gap: 10px;
    }
  `}
`;

const StyledDejargTrigger = styled(TextBody)`
  ${({ theme: { colors } }): string => `
    border-bottom: 1px dotted ${colors['slate-3']};
  `}
`;

const StyledTextBody = styled(TextBody)`
  margin-top: 4px;
`;

interface Info {
  label: string;
  value?: number;
  percent?: number;
  dejarg?: string;
}

interface AccountValue {
  [key: string]: Info;
}

interface IProps {
  account: WealthAccount;
}

const AccountDetailsWidget: React.FC<IProps> = (props) => {
  const [accountValues, setAccountValues] = useState<AccountValue>({
    balance: {
      label: 'Account Value',
      value: 0,
    },
    // Removed for inv-3971, will be added back once earnings are fixed. Also need to add the styles back above
    /*
    totalGainLoss: {
      label: 'Net Earnings',
      value: 0,
      dejarg:
        'We calculate your net earnings as the total value change in your account since you opened it. You’re seeing the dollar value of your investments’ performance combined with any deposits, withdrawals, and fees or expenses.',
    },
    */
    netReturn: {
      label: 'Net Return',
      percent: 0,
      dejarg:
        'We use a standard time-weighted rate-of-return method to calculate your net return. You’re seeing your investments’ performance over time, including the deduction of any fees and expenses, without the influence of deposits or withdrawals. This lets you focus on the performance of your underlying investments.',
    },
    projectedReturn: {
      label: 'Yield',
      percent: 0,
      dejarg:
        'The income your investments are expected to earn over time, such as dividends and interest. It doesn’t include capital gains.',
    },
  });

  const {
    maskedAccountNumber,
    toggleMask,
    showAccountNumber,
  } = useAccountNumberMask(props.account?.accountNumber, true);

  const { wealthOverview } = useWealthController();

  useEffect(() => {
    if (props.account) {
      let tmpAccountValues: AccountValue = { ...accountValues };
      Object.keys(accountValues).forEach((key: string) => {
        if (['netReturn', 'projectedReturn'].includes(key)) {
          tmpAccountValues[key].percent = +props.account[
            key as keyof WealthAccount
          ];
        } else {
          tmpAccountValues[key].value = +props.account[
            key as keyof WealthAccount
          ];
        }
      });
      setAccountValues(tmpAccountValues);
    }
  }, [wealthOverview.hasLoaded, props.account.accountId]);

  return (
    <Card
      drawer={
        props.account && (
          <AccountDetailsDrawer
            account={props.account}
            isLoading={wealthOverview.isLoading}
          />
        )
      }
    >
      <Box
        display='flex'
        flexDirection={['column', 'column', 'column', 'column', 'row']}
      >
        <Box
          flexShrink={1}
          paddingRight={['0', '0', '0', '0', '15px']}
          paddingBottom={['20px', '20px', '20px', '20px', '0']}
        >
          <Heading
            size='md'
            tag='h1'
            tabIndex={-1}
            isLoading={wealthOverview.isLoading}
          >
            {props.account?.accountHolders.join(' ')} Account
          </Heading>
          <AccountNumberDL>
            <TextBody tag='dt' size='sm' weight='bold'>
              Account Number:
            </TextBody>
            <Text tag='dd' size='sm' isLoading={wealthOverview.isLoading}>
              {maskedAccountNumber}
            </Text>
            <Button
              variant='link'
              text={showAccountNumber ? 'Hide' : 'Show'}
              onClick={toggleMask}
              ariaLabel={
                showAccountNumber
                  ? 'Hide account number'
                  : 'Show account number'
              }
            />
          </AccountNumberDL>
        </Box>
        <AccountValuesDL>
          {Object.values(accountValues).map((value: Info) => (
            <Box key={value.label}>
              {value.dejarg ? (
                <Dejargonator
                  popoverAriaLabel={value.label}
                  heading={value.label}
                  body={value.dejarg}
                  trigger={{
                    ariaLabel: 'Select to learn more about ' + value.label,
                    variant: 'element',
                    content: (
                      <StyledDejargTrigger tag='dt' size='xs' weight='bold'>
                        {value.label}
                      </StyledDejargTrigger>
                    ),
                  }}
                />
              ) : (
                <StyledTextBody tag='dt' size='xs' weight='bold'>
                  {value.label}
                </StyledTextBody>
              )}
              <Text
                tag='dd'
                size='md'
                className='value'
                fontSize='1.3333rem'
                isLoading={wealthOverview.isLoading}
              >
                {value.percent && (
                  <Currency
                    value={value.percent / 100}
                    formatOptions={{
                      style: 'percent',
                      maximumFractionDigits: 2,
                    }}
                  />
                )}
                {value.value && <Currency value={value.value} />}
              </Text>
            </Box>
          ))}
        </AccountValuesDL>
      </Box>
    </Card>
  );
};

export default AccountDetailsWidget;
