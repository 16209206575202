import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { NetWorthAccount } from './NetWorthAccount';

interface INetWorthDetails extends IAPIData {
  lastUpdatedDate: string;
  netWorth: number;
  assets: {
    [key: string]: NetWorthAccount[];
  };
  liabilities: {
    [key: string]: NetWorthAccount[];
  };
  totalAssets: number;
  totalLiabilities: number;
}

class NetWorthDetails extends APIData implements INetWorthDetails {
  lastUpdatedDate: string = '';
  netWorth: number = 0;
  assets: {
    [key: string]: NetWorthAccount[];
  } = {};
  liabilities: {
    [key: string]: NetWorthAccount[];
  } = {};
  totalAssets: number = 0;
  totalLiabilities: number = 0;
  dataName = 'NetWorth Details';

  setData(wealthOverview: NetWorthDetails) {
    this.lastUpdatedDate = wealthOverview.lastUpdatedDate;
    this.netWorth = wealthOverview.netWorth;
    this.assets = wealthOverview.assets || {};
    this.liabilities = wealthOverview.liabilities || {};
    this.totalAssets = wealthOverview.totalAssets;
    this.totalLiabilities = wealthOverview.totalLiabilities;
    this.setLoaded();
  }
}

export { NetWorthDetails };
export type { INetWorthDetails };
