import { Config } from '../models';

export default {
  environment: 'prod',
  orionConfig: {
    baseUrl: 'https://resources.advizr.com',
    host: 'webapi.advizr.com',
  },
  allyDocsRoot: 'https://ally.com/resources/pdf/corporate/',
  investDocsRoot: 'https://ally.com',
  investConfig: {
    baseUrl: 'https://api.invest.ally.com',
    bankUrl: 'https://secure.ally.com',
    investUrl: 'https://live.invest.ally.com',
    svcproviderUrl: 'https://invest.ally.com/serviceprovider/live',
  },
  wealthConfig: {
    baseUrl: 'https://wealth.invest.ally.com',
    proxyUrl: 'https://node-proxy.invest.ally.com',
  },
  logoutUrl: 'https://www.ally.com/logged-off',
  logRocketId: 'mvv2ld/wealthfe-prod',
  allyTmUrl:
    'https://assets.adobedtm.com/cd392cd56789/2a1d94722ac8/launch-14329df20c84.min.js',
  transmitConfig: {
    baseUrl: 'https://secure.ally.com/acs/customers/authenticate',
    apiKey: 'XhnbsDYmBensf2vqVXAfv0uD7SJBA27u',
    useTransmit: true,
  },
} as Config;
