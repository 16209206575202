import React from 'react';
import LabeledSelect from '../LabeledSelect';

const options = [
  { label: 'Year-to-date', value: '0' },
  { label: 'Quarter-to-date', value: '8' },
  { label: '1 Year', value: '1' },
  { label: '3 Year', value: '2' },
  { label: '5 Year', value: '3' },
  { label: 'Since funding', value: '7' },
];

const TimeFrameSelect: React.FC<IProps> = (props) => {
  return (
    <LabeledSelect
      label='Time Frame'
      options={options}
      id={props.id}
      onChange={props.onChange}
      value={props.value}
    />
  );
};

interface IProps {
  id: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  value: string;
}

export default TimeFrameSelect;
