import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const AssetsIcon: React.FC<IProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='76px'
      viewBox='0 0 195 76'
      version='1.1'
      aria-hidden={true}
    >
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g transform='translate(-467.000000, -609.000000)' strokeWidth='2'>
          <g transform='translate(468.000000, 610.000000)'>
            <polyline
              stroke='#C0C8D6'
              points='1.37134748e-12 61 5.7708951 61 24.8639199 24.0976213 54.6524895 41.9256051 87.1180556 16.8446267 98.2274137 0 110.870948 43.788662 142.049722 21.0213292 165.651054 34.2025222 180.9375 20.7146662 193 20.7146662'
            ></polyline>
            <polyline
              stroke='#650360'
              points='2.76628512e-13 74 16.5605099 74 54.3456971 7.71620841 84.1798157 39.7944725 97.9913729 16.1334011 131.276243 44.621552 157.93537 26.9235021 173.959472 7 193 16.1334011'
            ></polyline>
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

interface IProps {}

export default AssetsIcon;
