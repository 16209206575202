import { useMemo, useState, useEffect } from 'react';
import { Dimensions } from '../../models';
import { useSession } from '../index';
import useConfig from '../useConfig';

declare global {
  interface Window {
    OrionWidgets: any;
  }
}

const useOrionWidget = (
  widgetName: string,
  widgetId: string,
  options: string
) => {
  const { orionToken } = useSession();
  const [widgetStatus, setWidgetStatus] = useState<string>('uninitialized');
  const { orionConfig } = useConfig();

  return {
    widgetStatus,
    loadWidget: useMemo(
      () => () => {
        if (window.hasOwnProperty('OrionWidgets')) {
          const widgets = window.OrionWidgets;
          if (
            widgets &&
            widgets[widgetName] &&
            document.getElementById(widgetId)
          ) {
            setWidgetStatus('loading');
            widgets[widgetName].init({
              el: document.getElementById(widgetId),
              host: orionConfig.host,
              token: orionToken.token,
              options,
              onError(errorMessage: string) {
                console.error('error: ' + errorMessage);
                setWidgetStatus('error');
              },
              onSuccess() {
                console.log('success: ', widgetId);
                setWidgetStatus('success');
              },
              onEmpty() {
                console.log('empty', widgetId);
                setWidgetStatus('empty');
              },
              onLayoutChange(dimensions: Dimensions) {},
            });
          }
        } else {
          console.error(`Missing script for: ${widgetName}`);
        }
      },
      [orionToken.token, options, widgetId, widgetName]
    ),
  };
};

export default useOrionWidget;
