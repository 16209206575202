import React, { useEffect } from 'react';
import { Modal } from '@ally/metronome-ui';
import { PlaidInterstitialContent } from '../.';

interface IProps {
  isShown: boolean;
  setModalShow: (isShown: boolean) => void;
  focusEl?: HTMLElement;
  onSuccess: Function;
}

const PlaidInterstitial: React.FC<IProps> = (props) => {
  const { isShown, setModalShow, focusEl, onSuccess } = props;

  useEffect(() => {
    setModalShow(isShown);
  }, [setModalShow, isShown]);

  const modalRef: React.RefObject<HTMLElement> = React.createRef<HTMLElement>();

  return (
    <>
      <Modal
        content={
          <PlaidInterstitialContent
            setModalShow={setModalShow}
            focusEl={focusEl}
            modalRef={modalRef}
            onSuccess={onSuccess}
          />
        }
        setShowOption={isShown}
        toggleCallback={(isOpen: boolean): void => setModalShow(isOpen)}
        ref={modalRef}
        manualToggle
      />
    </>
  );
};

export default PlaidInterstitial;
