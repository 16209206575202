import React from 'react';
import { Box } from '@ally/metronome-ui';

const Row: React.FC<IProps> = (props) => {
  return (
    <Box
      maxWidth='1330px'
      marginX={['10px', '10px', '20px', '20px', '20px', 0]}
    >
      {props.children}
    </Box>
  );
};

interface IProps {}

export default Row;
