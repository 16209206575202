import { TextBody } from '@ally/metronome-ui';
import { WealthOverview } from '../../../models';
import { Loader } from '..';

interface IProps {
  wealthOverview: WealthOverview;
}

const WealthAccountsUpToDate = ({ wealthOverview }: IProps) => {
  return (
    <>
      {!wealthOverview.isLoading ? (
        <TextBody tag='p' size='sm'>
          {wealthOverview.totalNumberOfAccounts -
            wealthOverview.totalNumberDisconnectedAccounts}{' '}
          of {wealthOverview.totalNumberOfAccounts} accounts are up to date.
        </TextBody>
      ) : (
        <Loader height='s' />
      )}
    </>
  );
};

export default WealthAccountsUpToDate;
