import React from 'react';
import styled from 'styled-components';

const StyledDL = styled.dl`
  margin: 0;
`;

const DL: React.FC<IProps> = (props: React.PropsWithChildren<IProps>) => {
  return <StyledDL {...props}>{props.children}</StyledDL>;
};

interface IProps {}

export default DL;
