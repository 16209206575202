import { useEffect, useState } from 'react';
import { useFormatter } from '../../../hooks';
import { IChartProps } from '../../../interfaces';
import { NetWorthAccount } from '../../../models';
import { muiAllyTheme } from '../../../utils';
import { Highchart } from '../../atoms';

interface AccountInfo extends NetWorthAccount {
  accountNumber?: string;
}

interface IProps extends IChartProps<AccountInfo[]> {}

const DonutChart: React.FC<IProps> = (props: IProps) => {
  const [options, setOptions] = useState({});
  const { numberFormatter } = useFormatter();

  useEffect(() => {
    // Donut Chart Props
    setOptions({
      tooltip: {
        formatter: function () {
          const self: any = this;
          return `<p>${self.point.name}</p><br /><b>${numberFormatter(
            self.point.y
          )}</b>`;
        },
      },
      colors: props.colors,
      plotOptions: {
        pie: {
          showInLegend: false,
          innerSize: '80%',
          fillColor: muiAllyTheme.colors['patina-3'],
          dataLabels: {
            enabled: false,
            distance: -14,
            color: 'white',
            style: {
              fontweight: 'bold',
              fontsize: 50,
            },
          },
        },
      },
      chart: {
        type: 'pie',
        spacing: [2, 2, 2, 2],
        fillColor: muiAllyTheme.colors['patina-3'],
      },
      title: {
        text: props.title,
        floating: true,
        margin: 0,
        y: 150,
        style: {
          fontWeight: 'normal',
          fontSize: '0.93333rem',
          color: muiAllyTheme.colors['slate-5'],
        },
      },
      subtitle: {
        text: props.subTitle,
        y: 176,
        style: {
          fontWeight: 'bold',
          fontSize: '1.067rem',
          color: muiAllyTheme.colors['slate-5'],
        },
      },
      series: [{ data: props.data }],
    });
  }, [props.data]);

  return (
    <Highchart
      id={props?.id}
      width={props?.width}
      height={props?.height}
      chartOptions={options}
    />
  );
};

export default DonutChart;
