import { Box } from '@ally/metronome-ui';
import { useCustomersService } from '../../../hooks';
import UpcomingAppointment from '../UpcomingAppointment';

interface IProps {}

const UpcomingMeetings: React.FC<IProps> = (props) => {
  const { upcomingAppointment } = useCustomersService();

  return (
    <Box display='flex' flexDirection='column'>
      {upcomingAppointment && (
        <UpcomingAppointment
          key={upcomingAppointment.serviceAppointmentId}
          appointment={upcomingAppointment}
        />
      )}
    </Box>
  );
};

export default UpcomingMeetings;
