import { useEffect, createRef, useState } from 'react';
import { Media, Box, Link } from '@ally/metronome-ui';
import { LI, NavLink } from '../../atoms';
import { useSession, useConfig } from '../../../hooks';
import MainNavItems from './MainNavItems';
import { NavBar } from '../../molecules';
import { SideNav } from '../../organisms';
import { AllyIcon } from '../../icons';
import { muiMenu } from '@ally/metronome-icons';
import styled from 'styled-components';
import { muiAllyTheme } from '../../../utils';
import { useBroadcastRef } from '../../../contexts/messagingContext';

const StyledLink = styled(Link)`
  display: flex;
  flex-flow: column;
  color: ${muiAllyTheme.colors.white};
  font-size: 0.667rem;
  align-items: center;
  width: 34px;
  svg {
    width: 30px;
    height: 30px;
    margin: 0;
    path {
      fill: ${muiAllyTheme.colors.white};
    }
  }
`;

interface IProps {
  showSideNav: Boolean;
  setShowSideNav: Function;
  hideSubNav?: boolean;
}

interface IProps {}

const MainNav: React.FC<IProps> = (props) => {
  const { isLoggedIn, logout } = useSession();
  const { investConfig } = useConfig();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const ref: React.RefObject<HTMLElement> = createRef<HTMLElement>();
  const {
    broadcastRef: { broadcastDispatch },
  } = useBroadcastRef();

  useEffect(() => {
    if (!props.showSideNav && !initialLoad && ref && ref.current) {
      ref.current.focus();
    }
    setInitialLoad(false);
  }, [props.showSideNav]);

  const toggleSideNav = (e: React.MouseEvent): void => {
    e.preventDefault();
    props.setShowSideNav(!props.showSideNav);
  };

  return (
    <>
      {!props.showSideNav && (
        <NavBar isMainNav={true}>
          <Media.MdDown>
            {isLoggedIn && (
              <Box width={[1 / 3]}>
                <StyledLink
                  ref={ref}
                  onClick={toggleSideNav}
                  to='#'
                  aria-expanded={props.showSideNav}
                  aria-label='Open Mobile Menu'
                  aria-haspopup='true'
                  aria-controls='sidenav'
                  text='Menu'
                  icon={{ map: muiMenu }}
                />
              </Box>
            )}
            <Box textAlign='center' width={isLoggedIn ? 1 / 4 : '100%'}>
              <AllyIcon />
            </Box>
          </Media.MdDown>
          <Media.LgUp>
            <LI>
              <AllyIcon />
            </LI>
            {isLoggedIn && <MainNavItems />}
            <NavLink to='https://www.ally.com/help/' text='Help' pushRight />
            <NavLink
              to={`${investConfig.svcproviderUrl}/serviceprovider/ssoToAlly?relayState=${investConfig.bankUrl}/contact`}
              text='Contact'
              transmitdestination='bank_react'
            />
            {isLoggedIn && <NavLink size='sm' to='/logout' text='Log Out' />}
          </Media.LgUp>
        </NavBar>
      )}
      <Media.MdDown>
        {isLoggedIn && props.showSideNav && (
          <SideNav
            toggleSideNav={toggleSideNav}
            setShowSideNav={props.setShowSideNav}
            hideSubNav={props.hideSubNav}
          />
        )}
      </Media.MdDown>
    </>
  );
};

export default MainNav;
