import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { Proposal } from '.';

interface IProposalList extends IAPIData {
  proposals: Proposal[];
}

class ProposalList extends APIData implements IProposalList {
  proposals: Proposal[] = [];
  dataName = 'Proposals';

  setData(proposals: Proposal[]) {
    this.proposals = proposals;
    this.setLoaded();
  }
}

export { ProposalList };
export type { IProposalList };
