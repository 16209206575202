import { muiBankTheme } from '@ally/metronome-ui';

const muiAllyTheme = {
  ...muiBankTheme,
};

muiAllyTheme.font.heading.xs.size = '1.0666rem';
muiAllyTheme.font.heading.sm.size = '1.25rem';
muiAllyTheme.font.heading.md.size = '1.6rem';

muiAllyTheme.font.body.xs.size = '0.7333rem';
muiAllyTheme.font.body.lg.size = '1.2rem';
muiAllyTheme.font.body.xl.size = '1.8666rem';

export default muiAllyTheme;
