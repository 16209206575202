import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const GoalTrackingIcon: React.FC<IProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='83px'
      viewBox='0 0 155 83'
      version='1.1'
      aria-hidden={true}
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-1005.000000, -645.000000)'>
          <g transform='translate(966.000000, 646.000000)'>
            <g transform='translate(40.000000, 0.000000)'>
              <path
                d='M0,73.976702 C18.4684801,70.077311 36.3704782,58.6453977 53.7059942,39.6809621 C79.7092683,11.2343086 88.5967413,-6.48509266e-13 110.16,-6.48509266e-13 C124.535506,-6.48509266e-13 138.815506,11.1619487 153,33.485846 L153,82 L0,82 L0,73.976702 Z'
                fill='#DEEDF5'
              ></path>
              <path
                d='M35.0883231,63.8154955 C46.9661015,54.1972262 56.9148381,49.2391287 78.4947232,61.2868084 C100.074608,73.3344882 125.68796,82 128.289943,82 C130.024598,82 87.2612841,82 0,82 C15.4736965,76.2891766 27.1698042,70.2276751 35.0883231,63.8154955 Z'
                fill='#9AC7DF'
              ></path>
              <path
                d='M0,74.1565217 C18.4684801,70.2476523 36.3704782,58.7879507 53.7059942,39.7774171 C79.7092683,11.2616166 88.5967413,-6.48509266e-13 110.16,-6.48509266e-13 C124.535506,-6.48509266e-13 138.815506,11.1890807 153,33.5672422'
                stroke='#0071C4'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

interface IProps {}

export default GoalTrackingIcon;
