import { useEffect, useState } from 'react';
import {
  Box,
  TextHeading,
  TextBody,
  ButtonGroup,
  Button,
} from '@ally/metronome-ui';
import { useScript, usePlaid } from '../../../hooks';

interface IProps {
  setModalShow: (isShown: boolean) => void;
  focusEl?: HTMLElement;
  modalRef: React.RefObject<HTMLElement>;
  onSuccess: Function;
}

const PlaidInterstitialContent: React.FC<IProps> = (props) => {
  const { setModalShow, focusEl, modalRef, onSuccess } = props;
  const [plaidError, setPlaidError] = useState<boolean>(false);
  const { scriptLoaded, loadScript } = useScript(
    'https://cdn.plaid.com/link/v2/stable/link-initialize.js',
    'plaid-script'
  );
  const { handler, createPlaidHandler } = usePlaid();

  const handleClick = (): void => {
    if (handler) {
      handler.open();
      setModalShow(false);
    } else {
      try {
        createPlaidHandler(focusEl, onSuccess);
      } catch {
        setPlaidError(true);
        throw new Error('could not find handler');
      }
    }
  };

  useEffect(() => {
    loadScript();
  }, [loadScript]);

  useEffect(() => {
    if (scriptLoaded) {
      createPlaidHandler(focusEl, onSuccess);
    }
    return () => {
      if (handler) {
        handler.destroy();
      }
    };
  }, [scriptLoaded]);

  return (
    <>
      <TextHeading
        ref={modalRef as React.RefObject<HTMLHeadingElement>}
        tabIndex={-1}
        size='xs'
      >
        Important Notice
      </TextHeading>
      <Box paddingTop='10px'>
        {plaidError ? (
          <TextBody size='md' tag='p' type='error'>
            Sorry! Plaid is currently unavailable, please try again later.
          </TextBody>
        ) : (
          <TextBody size='md' tag='p'>
            Selecting <b>Continue</b> will begin the process of adding your
            accounts, powered by Plaid, a trusted third party. Plaid will
            connect you to your other financial institution’s website to
            complete this process. Since we don’t own or control these sites, we
            aren’t responsible for their products, services, or information.
            These sites have a different privacy policy, level of security, and
            terms and conditions.
          </TextBody>
        )}
        <Box padding='30px 0 0 0'>
          {!plaidError && (
            <ButtonGroup>
              <Button
                variant='primary'
                text='Continue'
                onClick={handleClick}
                isLoading={!handler}
              />
              <Button
                variant='link'
                text='Cancel'
                onClick={() => setModalShow(false)}
              />
            </ButtonGroup>
          )}
        </Box>
      </Box>
    </>
  );
};

export default PlaidInterstitialContent;
