import React from 'react';
import { SkeletonLoader } from '@ally/metronome-ui';

interface IProps {
  height?: 's' | 'm' | 'l' | 'xl';
  width?: 's' | 'm' | 'l' | 'xl';
  isLoading?: boolean;
}

const Loader: React.FC<IProps> = (props) => {
  return (
    <>
      {props.isLoading ? (
        <SkeletonLoader.Container>
          <SkeletonLoader.Row
            height={props.height ? props.height : 'xl'}
            width={props.width ? props.width : 'xl'}
          />
        </SkeletonLoader.Container>
      ) : (
        props.children
      )}
    </>
  );
};

export default Loader;
