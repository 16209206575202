import { Box, TextBody } from '@ally/metronome-ui';

interface IProps {
  start: number;
  end: number;
}

const Timeline: React.FC<IProps> = (props) => {
  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      minHeight='73px'
      {...props}
    >
      <TextBody size='sm' tag='strong'>
        {props.start}
      </TextBody>
      <Box
        bg='grey-80'
        flexGrow={1}
        height='5px'
        marginX='10px'
        paddingRight='52px'
        borderRadius='5px'
      >
        <Box display='flex' flexDirection='row' position='relative'>
          {props.children}
        </Box>
      </Box>
      <TextBody size='sm' tag='strong'>
        {props.end}
      </TextBody>
    </Box>
  );
};

export default Timeline;
