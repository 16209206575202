import { useContext, useRef } from 'react';
import { AxiosResponse } from 'axios';
import { storeContext } from '../../store/store';
import { Proposal, ProposalList, QPR, FileBlob, QPRList } from '../../models';
import { useAxios } from '../';

const useDocumentsController = () => {
  const { state, dispatch } = useContext(storeContext);
  const { investInstance } = useAxios('');
  // Blobs don't want to be stored in session storage so they need to be cached here
  const cachedDocumentsRef = useRef(new Map<string, FileBlob>());

  const getProposals = async (): Promise<ProposalList> => {
    const proposals: ProposalList = Object.assign(
      new ProposalList(),
      state.appCache.proposalList
    );
    if (!proposals.shouldReload()) {
      return proposals;
    }
    proposals.setIsLoading();
    dispatch({
      type: 'SET_PROPOSALS',
      payload: proposals,
    });
    const { data }: AxiosResponse<Proposal[]> =
      ((await investInstance(
        'GET',
        `/wealth-service/${'v1'}/households/${
          state.session.householdIds.allyHouseholdId
        }/proposals`
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    if (data) {
      proposals.setData(data);
    } else {
      proposals.setError();
    }
    dispatch({
      type: 'SET_PROPOSALS',
      payload: proposals,
    });
    return proposals;
  };

  const getLatestProposal = async (): Promise<FileBlob> => {
    const key = `proposal-latest`;
    const cachedDocument = cachedDocumentsRef.current.get(key);
    if (!cachedDocument) {
      const newBlob = new FileBlob(true);
      cachedDocumentsRef.current.set(key, newBlob);
      const { data }: AxiosResponse<Blob> =
        ((await investInstance(
          'GET',
          `/wealth-service/${'v1'}/households/${
            state.session.householdIds.allyHouseholdId
          }/proposals/latest`,
          {
            responseType: 'blob',
          }
        ).catch((err: Error) => {})) as AxiosResponse) || {};
      if (data) {
        newBlob.setData(data);
      } else {
        newBlob.setError();
      }
      cachedDocumentsRef.current.set(key, newBlob);
      return newBlob;
    }
    return cachedDocument;
  };

  // TODO: Implement when needed
  /*
  const getProposalById = async (proposalId: string): Promise<Blob> => {
    const { data }: AxiosResponse<Blob> =
      ((await investInstance(
        'GET',
        `/wealth-service/${'v1'}/households/${
          state.session.householdIds.allyHouseholdId
        }/proposals/${proposalId}`,
        {
          responseType: 'blob',
        }
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    return data;
  };
  */

  const getQPRS = async (): Promise<QPRList> => {
    const QPRS: QPRList = Object.assign(new QPRList(), state.appCache.QPRList);
    if (!QPRS.shouldReload()) {
      return QPRS;
    }
    QPRS.setIsLoading();
    dispatch({
      type: 'SET_QPRS',
      payload: QPRS,
    });
    const { data }: AxiosResponse<QPR[]> =
      ((await investInstance(
        'GET',
        `/wealth-service/${'v1'}/households/${
          state.session.householdIds.allyHouseholdId
        }/quarterly-reports`
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    if (data) {
      QPRS.setData(data);
    } else {
      QPRS.setError();
    }
    dispatch({
      type: 'SET_QPRS',
      payload: QPRS,
    });
    return QPRS;
  };

  const getQPRById = async (id: string): Promise<FileBlob> => {
    const key = `qpr-${id}`;
    const cachedDocument = cachedDocumentsRef.current.get(key);
    if (!cachedDocument) {
      const newBlob = new FileBlob(true);
      cachedDocumentsRef.current.set(key, newBlob);
      const { data }: AxiosResponse<Blob> =
        ((await investInstance(
          'GET',
          `/wealth-service/${'v1'}/households/${
            state.session.householdIds.allyHouseholdId
          }/quarterly-reports/${id}`,
          {
            responseType: 'blob',
          }
        ).catch((err: Error) => {})) as AxiosResponse) || {};
      if (data) {
        newBlob.setData(data);
      } else {
        newBlob.setError();
      }
      cachedDocumentsRef.current.set(key, newBlob);
      return newBlob;
    }
    return cachedDocument;
  };

  return {
    getProposals,
    getLatestProposal,
    proposalList: state.appCache.proposalList,
    getQPRS,
    getQPRById,
    QPRList: state.appCache.QPRList,
    cachedDocuments: cachedDocumentsRef.current,
  };
};

export default useDocumentsController;
