import { TextBody } from '@ally/metronome-ui';
import { LeavingAllyModal } from '..';

type IProps = {
  link: string;
};

const ZoomLink: React.FC<IProps> = (props: IProps) => {
  const modalBodyJSX = (
    <TextBody tag='p' size='sm'>
      Selecting{' '}
      <TextBody tag='span' weight='bold' size='sm'>
        Continue
      </TextBody>{' '}
      will launch your meeting via Zoom, a trusted third party. Since we don’t
      own or control Zoom, we aren’t responsible for its products, services, or
      information. Zoom also has a different privacy policy, level of security,
      and terms and conditions.
    </TextBody>
  );

  return (
    <LeavingAllyModal
      variant='link'
      to={props.link}
      text='Launch Zoom Meeting'
      title='Before you zoom off'
      modalBody={modalBodyJSX}
    />
  );
};

export default ZoomLink;
