import { LogLevel } from '../../types';
import useConfig from '../useConfig';
import { getSessionStorage } from '../../utils/StorageUtil';
import axios, { AxiosInstance } from 'axios';

const useAthenaLogger = () => {
  const { wealthConfig, environment } = useConfig();
  const rawSessionStorage = getSessionStorage('WEALTH-TS');
  const sessionStorage = rawSessionStorage
    ? JSON.parse(rawSessionStorage)
    : { session: { guid: '' } };

  const proxyInstance: AxiosInstance = axios.create({
    baseURL: wealthConfig.proxyUrl,
  });

  const sendLog = async (message: string, level: LogLevel = 'error') => {
    if (['qa', 'prod'].includes(environment)) {
      proxyInstance.post('/log', {
        message,
        level,
        timeStamp: new Date(),
        user: sessionStorage.guid,
      });
    }
  };

  return {
    sendLog,
  };
};

export default useAthenaLogger;
