import React, { useEffect, useState } from 'react';
import { Box } from '@ally/metronome-ui';

const TabSelector: React.FC<IProps> = (
  props: React.PropsWithChildren<IProps>
) => {
  const [cleanedProps, setCleanedProps] = useState<React.ReactNode>();
  const [active, setActive] = useState<string>('');

  useEffect(() => {
    const children = React.Children.toArray(props.children);
    if (React.isValidElement(children[0])) {
      const value = children[0].props.value;
      setActive(value);
    }
    if (props.defaultValue) {
      props.onChange(props.defaultValue);
      setActive(props.defaultValue);
    }
  }, []);

  // Remove the onChange prop since Box doesn't like it
  useEffect(() => {
    const { onChange, ...cleanProps } = props;
    setCleanedProps(cleanProps);
  }, [props]);

  const selectTab = (value: string) => {
    if (value !== active) {
      setActive(value);
      props.onChange(value);
    }
  };

  return (
    <Box {...cleanedProps} role='tablist'>
      {React.Children.map(props.children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              onClick: () => {
                selectTab(child.props.value);
              },
              active: child.props.value === active,
            })
          : child
      )}
    </Box>
  );
};

interface IProps {
  onChange: (value: string) => void;
  defaultValue?: string;
}

export default TabSelector;
