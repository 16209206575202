import { useAthenaLogger, useConfig } from '..';
import { useTransmitRef } from '../../../src/contexts/transmitContext';
import axios, { AxiosInstance } from 'axios';
import TransmitToken from '../../models/TransmitToken';
import {
  Destination,
  RefreshAccessTokenOptions,
  SessionPayload,
  TicketPayload,
} from '@ally/transmitigator';
import { clearCookies } from '../../utils/CookieUtil';
import {
  deleteLocalStorage,
  deleteSessionStorage,
} from '../../utils/StorageUtil';
import { makeGuid } from '../../utils';

const useTransmit = () => {
  const {
    wealthConfig,
    investConfig: { bankUrl },
  } = useConfig();
  const { transmitRef, transmitToken, setTransmitToken } = useTransmitRef();
  const { ticketID } = transmitRef.useTransmit();
  const journeyVersion = 'v2';

  const proxyInstance: AxiosInstance = axios.create({
    baseURL: wealthConfig.proxyUrl,
    withCredentials: true,
    headers: {
      correlationid: makeGuid(),
    },
  });
  const athenaLogger = useAthenaLogger();

  const getSessionCookies = (token: string) => {
    try {
      return proxyInstance.post('/apigee/getCookies', { token });
    } catch (error: any) {
      athenaLogger.sendLog(
        `*TRANSMIT* getSessionCookies Error, ${
          token ? 'token exists' : 'no token'
        }`
      );
    }
  };

  const initTransmit = async (haveCookies: boolean = false) => {
    try {
      const response = (await transmitRef.rehydrate()) as SessionPayload;
      if (!response) {
        throw new Error('rehydrade - no response');
      }
      const { access_token, userNamePvtEncrypt } = response.session;
      setTransmitToken(new TransmitToken(access_token, userNamePvtEncrypt));
      if (!access_token) {
        throw new Error('no access token');
      }
      if (!haveCookies) {
        await getSessionCookies(access_token);
      }
    } catch (error: any) {
      athenaLogger.sendLog(
        `*TRANSMIT* initTransmit Error: Have cookies: ${haveCookies} - ${
          error.message || 'Unknown error'
        }`
      );
      window.location.href = bankUrl;
    }
  };

  const getTransferTicket = (journey_destination: Destination) => {
    try {
      return transmitRef.transferTicket({
        destination: journey_destination,
        access_token: transmitToken.access_token,
        username: transmitToken.userNamePvtEncrypt,
        journey_version: journeyVersion,
      });
    } catch (error: any) {
      athenaLogger.sendLog(
        `*TRANSMIT* getTransferTicket Error: ${
          error.message || 'Unknown error'
        }`
      );
    }
  };

  const appendTicketID = (ticketID: any, destination: string) => {
    let parsedUrl = new URL(destination);

    if (ticketID) {
      if (parsedUrl.searchParams.has('relayState')) {
        parsedUrl = new URL(parsedUrl.searchParams.get('relayState') || '');
      }
      parsedUrl.searchParams.append('ticketID', ticketID);
    }

    return parsedUrl.toString();
  };

  const leaveWithTicket = async (
    e: React.MouseEvent<Element, MouseEvent>,
    destination: string,
    journey_destination: Destination = 'wealth'
  ) => {
    try {
      e.preventDefault();
      let url = destination;
      if (transmitToken?.access_token) {
        const { ticketID } = (await getTransferTicket(
          journey_destination
        )) as TicketPayload;
        url = appendTicketID(ticketID, destination);
        clearCookies();
        deleteLocalStorage('sessionExpiry');
        deleteSessionStorage('WEALTH-TS');
      }
      window.location.href = url;
    } catch (error: any) {
      athenaLogger.sendLog(
        `*TRANSMIT* leaveWithTicket Error: ${error.message || 'Unknown error'}`
      );
    }
  };

  const refreshTransmitToken = async () => {
    try {
      if (!transmitToken?.access_token) {
        throw new Error('no transmit token');
      }

      if (!transmitRef) {
        throw new Error('no transmit ref');
      }

      const { expirationDate } = transmitToken;

      const expiry = new Date(expirationDate || 0);
      const currentDate = new Date();
      if (!expirationDate || expiry > currentDate) {
        return;
      }

      const options: RefreshAccessTokenOptions = {
        access_token: transmitToken.access_token || '',
        username: transmitToken.userNamePvtEncrypt || '',
        journey_version: journeyVersion,
      };

      const response = (await transmitRef.refreshAccessToken(
        options
      )) as SessionPayload;

      const { access_token } = response?.session || undefined;

      if (!access_token) {
        throw new Error('no access token');
      }

      setTransmitToken(
        new TransmitToken(access_token, transmitToken.userNamePvtEncrypt)
      );
    } catch (error: any) {
      athenaLogger.sendLog(
        `*TRANSMIT* refreshTransmitToken Error: ${error.message || 'Unknown'}`
      );
    }
  };

  const logoutTransmit = async () => {
    await transmitRef.logout();
  };

  return {
    ticketID,
    initTransmit,
    leaveWithTicket,
    logoutTransmit,
    refreshTransmitToken,
  };
};

export default useTransmit;
