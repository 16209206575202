import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { InvestAccount } from '.';

interface IInvestAccountList extends IAPIData {
  Accounts: InvestAccount[];
}

class InvestAccountList extends APIData implements IInvestAccountList {
  Accounts: InvestAccount[] = [];
  dataName = 'Invest Accounts';

  setData(accounts: InvestAccount[]) {
    this.Accounts = accounts || [];
    this.setLoaded();
  }
}

export { InvestAccountList };
export type { IInvestAccountList };
