import { useEffect } from 'react';
import SideNavLink from '../../molecules/SideNavLink';
import { Box, Button } from '@ally/metronome-ui';
import { useSession, useNav, useViewport, useKeyEvents } from '../../../hooks';
import styled from 'styled-components';
import { INavLinkDetail } from '../../../models';
import FocusLock from 'react-focus-lock';
import { muiAllyTheme } from '../../../utils';

const ScrollBox = styled(Box)`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

interface IProps {
  toggleSideNav: React.MouseEventHandler<HTMLDivElement>;
  setShowSideNav: Function;
  hideSubNav?: boolean;
}

const SideNav: React.FC<IProps> = (props) => {
  const { logout } = useSession();
  const {
    navLinkList: { sideNav },
  } = useNav();
  const { isLessThanLarge } = useViewport();
  const { createEscKeyListener } = useKeyEvents();

  useEffect(() => {
    if (!isLessThanLarge) {
      props.setShowSideNav(false);
    }
  }, [isLessThanLarge]);

  useEffect(() => {
    createEscKeyListener(() => props.setShowSideNav(false));
  }, []);

  return (
    <FocusLock>
      <Box id='sidenav'>
        <Box display='flex' position='fixed' height='100%' zIndex={3}>
          <Box
            position='fixed'
            width='100%'
            height='100%'
            backgroundColor={muiAllyTheme.colors['slate-5']}
            opacity={0.8}
            onClick={props.toggleSideNav}
          ></Box>
          <ScrollBox
            position='relative'
            width='100%'
            minHeight='500px'
            backgroundColor={muiAllyTheme.colors['plum']}
            overflowY='scroll'
            overflowX='hidden'
          >
            {sideNav
              .filter(
                (list: INavLinkDetail) =>
                  (props.hideSubNav && list.isMainNav) || !props.hideSubNav
              )
              .map((navLinkDetail: INavLinkDetail) => (
                <Box key={navLinkDetail.title} width='224px'>
                  <SideNavLink navLinkDetail={navLinkDetail} />
                </Box>
              ))}
            <Button
              size='sm'
              margin='22px'
              variant='login'
              text='Log Out'
              onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                e.preventDefault();
                logout();
              }}
            />
            {/* <Box width='75%' padding='0 22px 22px 22px'>
              <TextBody fontSize='0.875rem' tag='p' size='md' color={colors['white']}>
                Last Log In
              </TextBody>
              <TextBody fontSize='0.875rem' tag='p' size='md' color={colors['white']}>
                Month NN, NNNN
              </TextBody>
              <TextBody fontSize='0.875rem' tag='p' size='md' color={colors['white']}>
                NN.NN PM ET
              </TextBody>
            </Box> */}
          </ScrollBox>
        </Box>
      </Box>
    </FocusLock>
  );
};

export default SideNav;
