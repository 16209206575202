import React from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const AllocationIcon: React.FC<IProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='80px'
      viewBox='0 0 80 80'
      version='1.1'
      aria-hidden={true}
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          transform='translate(-757.000000, -643.000000)'
          stroke='#FFFFFF'
          strokeWidth='0.25'
        >
          <g transform='translate(757.000000, 643.000000)'>
            <g transform='translate(0.001309, 0.000000)'>
              <path
                d='M40.1236914,0.125194978 C54.3209844,0.169487304 67.4236424,7.75730673 74.5304473,20.0625158 C78.0942726,26.2331671 79.8761914,33.1165825 79.8761914,40 C79.8761914,46.8834175 78.0942726,53.7668329 74.5304473,59.9374842 C67.4027898,72.2787989 54.244042,79.875 39.9986914,79.875 C31.616775,79.875 23.4519962,77.2396305 16.6633204,72.3322386 L16.6633204,72.3322386 L23.5665268,62.8244181 C27.6068272,65.7337339 32.2680027,67.466745 37.0588038,67.9707682 C41.8884118,68.4788742 46.8497517,67.7380019 51.4395398,65.6941904 C60.6203261,61.6060284 66.9183152,52.9375226 67.9730011,42.9431181 C68.50014,37.9478494 67.6723184,33.0027632 65.6977051,28.568118 C63.7215549,24.1300213 60.5968252,20.2031627 56.5322631,17.2489446 C51.7646034,13.7779287 46.0164379,11.9014523 40.1236914,11.8752776 L40.1236914,11.8752776 Z'
                fill='#008486'
              ></path>
              <path
                d='M2.03929126,27.7976491 L13.2114535,31.4303154 C11.6943365,36.1708701 11.4878016,41.1395262 12.4889922,45.8513152 C13.4985139,50.6023115 15.7359424,55.0921422 19.0959388,58.8237971 C20.3838213,60.2493159 21.8160372,61.5401253 23.3643452,62.6773718 L23.3643452,62.6773718 L16.4611247,72.1852116 C5.00217346,63.8038328 -1.13798758,49.9571782 0.34301673,35.8329692 C0.627783885,33.1031323 1.20275719,30.4119932 2.03929126,27.7976491 L2.03929126,27.7976491 Z'
                fill='#00A17D'
              ></path>
              <path
                d='M39.8736914,0.125193271 L39.8736914,11.8752769 C29.8770164,11.9195714 20.6419197,17.274934 15.6405569,25.9372948 C14.6770409,27.5988684 13.886728,29.3662893 13.2884633,31.1924718 L13.2884633,31.1924718 L2.11641183,27.5598415 C6.54980105,14.0716402 17.8091126,3.94433113 31.7046683,0.992270995 C34.3855174,0.422713317 37.1244075,0.133695478 39.8736914,0.125193271 L39.8736914,0.125193271 Z'
                fill='#3FC57A'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

interface IProps {}

export default AllocationIcon;
