import React from 'react';
import styled from 'styled-components';
import { Box } from '@ally/metronome-ui';
import { LoadingSpinner } from '../';

const Blur = styled(Box)`
  background-color: white;
  opacity: 0.65;
  height: 100%;
  width: 100%;
`;

const CenteredSpinner = styled(LoadingSpinner)`
  margin-top: -50vh;
`;

interface IProps {}

const LoadingOverlay: React.FC<IProps> = (props) => {
  return (
    <Box
      zIndex={1000}
      position='fixed'
      left={0}
      top={0}
      width='100%'
      height='100vh'
    >
      <Blur></Blur>
      <Box marginTop='-50vh'>
        <CenteredSpinner isLoading color='white' />
      </Box>
    </Box>
  );
};

export default LoadingOverlay;
