interface IJWT {
  nbf: Date;
  exp: Date;
  iss: string;
  aud: string[];
  client_id: string;
  sub: string;
  auth_time: Date;
  idp: string;
  name: string;
  unique_name: string;
  sviuserid: string;
  quote_bucket: string;
  first_name: string;
  guid: string;
  scope: string[];
  amr: string[];
}

class JWT implements IJWT {
  nbf: Date;
  exp: Date;
  iss: string;
  aud: string[];
  client_id: string;
  sub: string;
  auth_time: Date;
  idp: string;
  name: string;
  unique_name: string;
  sviuserid: string;
  quote_bucket: string;
  first_name: string;
  guid: string;
  scope: string[];
  amr: string[];

  constructor(
    nbf = new Date(),
    exp = new Date(),
    iss = '',
    aud = [],
    client_id = '',
    sub = '',
    auth_time = new Date(),
    idp = '',
    name = '',
    unique_name = '',
    sviuserid = '',
    quote_bucket = '',
    first_name = '',
    guid = '',
    scope = [],
    amr = []
  ) {
    this.nbf = nbf;
    this.exp = exp;
    this.iss = iss;
    this.aud = aud;
    this.client_id = client_id;
    this.sub = sub;
    this.auth_time = auth_time;
    this.idp = idp;
    this.name = name;
    this.unique_name = unique_name;
    this.sviuserid = sviuserid;
    this.quote_bucket = quote_bucket;
    this.first_name = first_name;
    this.guid = guid;
    this.scope = scope;
    this.amr = amr;
  }
}

export { JWT };
export type { IJWT };
