import { createRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Media, TextBody } from '@ally/metronome-ui';
import { useAllyTm, useWealthController } from '../../../hooks';
import { Goal } from '../../../models';
import { LI, UL } from '../../atoms';
import {
  Drawer,
  GoalTile,
  GoalTimelineItem,
  Timeline,
} from '../../molecules/index';
import { muiAllyTheme } from '../../../utils';

const GoalList = styled(UL)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px 0;
  ${({ theme: { media } }): string => `
    ${media.down('md')} {
      padding-top: 0;
    }
  `}
`;

const GoalListItem = styled(LI)`
  width: 100%;
  > div {
    padding: 10px 0;
    border-bottom: 1px solid ${muiAllyTheme.colors['grey-80']};
  }
  ${({ theme: { media } }): string => `
    ${media.up('lg')} {
      width: 50%;
      padding-right: 20px;
      &:nth-child(2n) {
        padding-right: 0;
      }
    }
    ${media.up('xl')} {
      width: 33%;
      padding-right: 20px;
      &:nth-child(2n) {
        padding-right: 20px;
      }
      &:nth-child(3n) {
        padding-right: 0;
      }
    }
  `}
`;

interface IProps {}

const UpcomingGoals: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const focusRef = createRef<HTMLLIElement>();
  const { goalsSummary } = useWealthController();
  const [timelineYears, setTimelineYears] = useState<{
    start: number;
    end: number;
  }>({ start: new Date().getFullYear(), end: new Date().getFullYear() + 1 });

  useEffect(() => {
    const end =
      goalsSummary.spouse &&
      goalsSummary.spouse.lifeExpectancyEndYear >
        goalsSummary.principal.lifeExpectancyEndYear
        ? goalsSummary.spouse.lifeExpectancyEndYear
        : goalsSummary.principal.lifeExpectancyEndYear;

    setTimelineYears({ start: new Date().getFullYear(), end });
  }, [goalsSummary]);

  const onOpen = () => {
    allyTmEvent('clickEvent', {
      tagName: 'button',
      allytmln: 'upcomingGoalsDrawer',
      href: '',
    });
  };

  return (
    <Drawer
      title={`Upcoming Goals (${goalsSummary.goals.length})`}
      focusRef={focusRef}
      onOpen={onOpen}
      contents={
        <>
          <Media.MdUp>
            <Timeline
              start={timelineYears.start}
              end={timelineYears.end}
              aria-hidden={true}
            >
              {[...goalsSummary.goals]
                .reverse()
                .map((goal: Goal, index: number) => (
                  <GoalTimelineItem
                    goal={goal}
                    start={timelineYears.start}
                    end={timelineYears.end}
                    key={index}
                  />
                ))}
            </Timeline>
          </Media.MdUp>
          <GoalList>
            {goalsSummary.goals.map((goal: Goal, index: number) => (
              <GoalListItem
                key={index}
                focusRef={index === 0 ? focusRef : undefined}
              >
                <GoalTile goal={goal} />
              </GoalListItem>
            ))}
          </GoalList>
          <TextBody tag='p' size='md'>
            We determine the status of your goals based on personal benchmarks
            you set with your advisor. Any projected investments outcomes are
            hypothetical and don't guarantee future results. To manage your
            goals or make changes, talk with your advisor.
          </TextBody>
        </>
      }
    />
  );
};

export default UpcomingGoals;
