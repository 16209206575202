import { JWT } from '../../models/index';

const useJWT = () => {
  const decodeJWT = (jwt: string): JWT => {
    try {
      return JSON.parse(atob(jwt.split('.')[1]));
    } catch (error: any) {
      console.error('Error decoding JWT', error);
      return new JWT();
    }
  };

  return {
    decodeJWT,
  };
};

export default useJWT;
