import { useContext } from 'react';
import { AxiosResponse } from 'axios';
import { storeContext } from '../../store/store';
import { PendingAccount } from '../../models';
import { useAxios } from '../index';

const useNotifications = () => {
  const { state, dispatch } = useContext(storeContext);
  const { investInstance } = useAxios('');

  const getPendingAccounts = async () => {
    const { data }: AxiosResponse<PendingAccount[]> =
      ((await investInstance(
        'GET',
        `/customers-service/v1/customers/accounts/pending`
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    dispatch({ type: 'SET_PENDING_ACCOUNTS', payload: data || [] });
  };

  const suppressNotification = (notificationID: string) => {
    dispatch({
      type: 'SUPPRESS_NOTIFICATION',
      payload: notificationID,
    });
  };

  const submitDocumentAgreements = async () => {
    const response: AxiosResponse<{
      AccountId: string;
      AccountNumber: string;
      IsCancel: boolean;
      NewHouseholdId: string;
    }> =
      ((await investInstance(
        'POST',
        `/wealth-service/v1/accounts/households/${state.session.householdIds.allyHouseholdId}`
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    if (response.data) {
      return response.data;
    }
    return null;
  };

  return {
    getPendingAccounts,
    pendingAccounts: state.appCache.pendingAccounts || [],
    suppressNotification,
    suppressedNotifications: state.appCache.suppressedNotifications || {},
    submitDocumentAgreements,
  };
};

export default useNotifications;
