import { ILabeledValue } from '../interfaces';

enum AssetTypes {
  'Asset Type' = 0,
  'Asset Class' = 1,
  'Sector' = 2,
  'Geography' = 3,
}

const length = Object.keys(AssetTypes).length / 2;
const AssetTypesArray: ILabeledValue<number>[] = Object.entries(AssetTypes)
  .slice(length)
  .map((x) => ({ label: x[0], value: +x[1] }));

export { AssetTypes, AssetTypesArray };
