import { useMemo } from 'react';
import { useAppCache, useConfig, useAxios, useSession } from '..';
import { AccountType } from '../../types';
import { AxiosResponse } from 'axios';
import { muiAllyTheme } from '../../utils';
import { DepositAccountList, DepositAccount } from '../../models';
import { useStore } from '../../store/store';

const useAccounts = () => {
  const { appCache } = useAppCache();
  const { investInstance } = useAxios('');
  const { wealthConfig, environment } = useConfig();
  const { investInstance: proxyInstance } = useAxios(
    wealthConfig.proxyUrl,
    true
  );
  const { session } = useSession();
  const { dispatch } = useStore();

  const accountColors: { [key: string]: string } = {
    INVW: muiAllyTheme.colors.invw,
    INVS: muiAllyTheme.colors.inv,
    INVA: muiAllyTheme.colors.inva,
    SDA: muiAllyTheme.colors.sda,
    MMA: muiAllyTheme.colors.mma,
    DDA: muiAllyTheme.colors.dda,
    CMG: muiAllyTheme.colors.cmg,
    CDA: muiAllyTheme.colors.cda,
  };

  const accountMapping: { [key: string]: string } = {
    INVW: 'PERSONAL ADVICE',
    INVS: 'SELF-DIRECTED',
    INVA: 'ROBO-PORTFOLIO',
    SDA: 'SAVINGS',
    MMA: 'SAVINGS',
    DDA: 'CHECKING',
    CMG: 'CHECKING',
    CDA: 'CD ACCOUNT',
  };

  const getAccountType = (accountType: AccountType): string => {
    return accountMapping[accountType];
  };

  // merges account details from different sources and places bank accounts at the end of the list
  const mergedAccounts = useMemo(() => {
    const newInvestAccounts: DepositAccount[] = appCache.investAccountList.Accounts.map(
      (account) => ({
        number: account.AccountNumber,
        name: account.AccountNickName,
        value: account.AccountValueTotal,
        status: account.AccountStatus,
        balance: account.Balance,
        type: account.AccountType as AccountType,
        accountId: account.WebId,
      })
    );
    return [...newInvestAccounts, ...appCache.depositAccountList.accounts];
  }, [
    appCache.depositAccountList.lastUpdated,
    appCache.investAccountList.lastUpdated,
  ]);

  const getOrionAccountNumber = async (WebId: string): Promise<string> => {
    const result: AxiosResponse<{
      accountNumber: string;
      orionAccountId: string;
    }> =
      ((await investInstance(
        'GET',
        `/wealth-service/v1/users/${session.username}/accounts/${WebId}/xref`
      ).catch((err: Error) => {})) as AxiosResponse) || {};

    return result.data?.accountNumber || '';
  };

  const getDepositAccounts = async (attempt: number = 0) => {
    const depositAccountsList = new DepositAccountList(true);
    dispatch({
      type: 'SET_DEPOSIT_ACCOUNTS',
      payload: depositAccountsList,
    });

    // Unable to fetch from redis in local and dev. Leave as initial empty state.
    if (!['qa', 'prod'].includes(environment)) {
      return;
    }

    if (attempt > 5) {
      return depositAccountsList.setError();
    }

    setTimeout(async () => {
      try {
        const { data } = await proxyInstance<DepositAccountList>(
          'GET',
          '/redis/depositAccounts'
        );

        if (data.accounts) {
          depositAccountsList.setData(data.accounts);
        } else {
          getDepositAccounts(attempt + 1);
          depositAccountsList.setError();
        }
      } catch (err) {
        getDepositAccounts(attempt + 1);
        depositAccountsList.setError();
      }
      dispatch({
        type: 'SET_DEPOSIT_ACCOUNTS',
        payload: depositAccountsList,
      });
    }, attempt * 200);
  };

  return {
    accountColors,
    getAccountType,
    mergedAccounts,
    accountMapping,
    getOrionAccountNumber,
    getDepositAccounts,
  };
};

export default useAccounts;
