import { Button } from '@ally/metronome-ui';
import { useState } from 'react';
import styled from 'styled-components';
import { PlaidInterstitial } from '../../organisms';
import { useConfig } from '../../../hooks';
import { Link } from '../../atoms';

interface IProps {
  buttonText: string;
  hideManageAccount?: boolean;
  onSuccess: Function;
}

const StyledSpan = styled.span`
  padding: 0 10px;
  ${({ theme: { colors } }): string => `
  color: ${colors['grey-80']}
`}
`;

const Plaid = (props: IProps) => {
  const { investConfig } = useConfig();
  const [
    shouldShowPlaidInterstitialModal,
    setShouldShowPlaidInterstitialModal,
  ] = useState<boolean>(false);
  const [focusEl, setFocusEl] = useState<HTMLElement | undefined>();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setFocusEl(e.target as HTMLElement);
    setShouldShowPlaidInterstitialModal(true);
  };

  return (
    <>
      {!props.hideManageAccount && (
        <>
          <Link
            to={`${investConfig.bankUrl}/payments/manage-non-ally-accounts`}
            text='Manage accounts'
            allytmln='plaidManageAccountRedirect'
            transmitdestination='bank_react'
          />
          <StyledSpan>|</StyledSpan>
        </>
      )}
      <Button
        marginLeft='auto'
        variant='link'
        text={props.buttonText}
        allytmln='plaidAddAccount'
        onClick={handleClick}
      ></Button>
      <PlaidInterstitial
        isShown={shouldShowPlaidInterstitialModal}
        setModalShow={(isShown: boolean) =>
          setShouldShowPlaidInterstitialModal(isShown)
        }
        focusEl={focusEl}
        onSuccess={props.onSuccess}
      />
    </>
  );
};

export default Plaid;
