import styled from 'styled-components';
import { TextBody, Box, TextHeading } from '@ally/metronome-ui';
import { Text } from '../../atoms';
import { muiAllyTheme } from '../../../utils';

const StyledTextHeading = styled(TextHeading)`
  color: ${muiAllyTheme.colors.plum};
  font-size: 1.6rem;
`;

const SubHeader = styled(Text)`
  padding-top: 0px;
  display: block;
`;

interface IProps {
  content?: string;
  subHeader?: string;
}

const WealthHeading: React.FC<IProps> = (props) => {
  return (
    <Box paddingBottom='24px'>
      <TextBody tag='p' size='sm'>
        Ally Invest Personal Advice
      </TextBody>
      {!!props.content && (
        <StyledTextHeading tag='h1' size='lg' tabIndex={-1}>
          {props.content}
        </StyledTextHeading>
      )}
      {!!props.subHeader && (
        <SubHeader tag='b' size='md' fontSize='1rem'>
          {props.subHeader}
        </SubHeader>
      )}
    </Box>
  );
};

export default WealthHeading;
