import { useState } from 'react';
import devConfig from '../../config/devConfig';
import { qa1Config, qa2Config } from '../../config/qaConfig';
import capConfig from '../../config/capConfig';
import localConfig from '../../config/localConfig';
import prodConfig from '../../config/prodConfig';
import { Config } from '../../models';

const url = window.location.hostname.toLowerCase();

const useConfig = () => {
  const [config] = useState<Config>(() => {
    if (url.includes('qabi1')) {
      return qa1Config;
    } else if (url.includes('qabi2')) {
      return qa2Config;
    } else if (url.includes('cap')) {
      return capConfig;
    } else if (url.includes('dev')) {
      return devConfig;
    } else if (url.includes('wealth.invest') || url.includes('10.25.199.26')) {
      return prodConfig;
    } else {
      return localConfig;
    }
  });

  return {
    environment: config.environment,
    orionConfig: config.orionConfig,
    investConfig: config.investConfig,
    wealthConfig: config.wealthConfig,
    allyDocsRoot: config.allyDocsRoot,
    investDocsRoot: config.investDocsRoot,
    logoutUrl: config.logoutUrl,
    logRocketId: config.logRocketId,
    allyTmUrl: config.allyTmUrl,
    transmitConfig: config.transmitConfig,
  };
};

export default useConfig;
