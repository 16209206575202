import { useEffect, useState } from 'react';
import DefaultTemplate from '../../templates/DefaultTemplate';
import {
  // AccountActivityWidget,
  AccountDetailsWidget,
  AccountHoldingsWidget,
  AccountPerformanceWidget,
} from '../../organisms';
import {
  useAccounts,
  useAllyTm,
  useAppCache,
  useURL,
  useWealthController,
} from '../../../hooks';
import { InvestAccount, WealthAccount } from '../../../models';
import { useParams } from 'react-router-dom';
import { LoadingOverlay } from '../../atoms';

interface IProps {}

const AccountDetailsPage: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const { getQueryParam } = useURL();
  const { getWealthOverview } = useWealthController();
  const { getOrionAccountNumber } = useAccounts();
  const { paramId } = useParams<{ paramId: string }>();
  const [account, setAccount] = useState<WealthAccount | undefined>(undefined);

  useEffect(() => {
    if (paramId) {
      allyTmEvent(
        'pageview',
        `Wealth:Wealth Overview:Account Details:${paramId}`
      );
    }
  }, [paramId]);

  useEffect(() => {
    const orionAccountIdLookup = async () => {
      const wealthOverview = await getWealthOverview();
      let tmpAccountNumber: string = '';
      if (!getQueryParam('noLookup')) {
        tmpAccountNumber = await getOrionAccountNumber(paramId);
        setAccount(
          wealthOverview.wealthAccounts.find(
            (account: WealthAccount) =>
              account.accountNumber === tmpAccountNumber
          )
        );
      }
      if (!tmpAccountNumber) {
        setAccount(
          wealthOverview.wealthAccounts.find(
            (account: WealthAccount) => account.accountId === paramId
          )
        );
      }
    };
    if (!account) {
      orionAccountIdLookup();
    }
  }, []);

  return (
    <DefaultTemplate>
      {account?.accountId ? (
        <>
          <AccountDetailsWidget account={account} />
          <AccountPerformanceWidget account={account} />
          {/* commented out for cash-1188, to be re-added after Orion has made some fixes */}
          {/* <AccountActivityWidget accountId={account.accountId} /> */}
          <AccountHoldingsWidget accountId={account.accountId} />
        </>
      ) : (
        <LoadingOverlay />
      )}
    </DefaultTemplate>
  );
};

export default AccountDetailsPage;
