import { Box, TextBody } from '@ally/metronome-ui';
import styled from 'styled-components';
import { Link } from '../../atoms';
import { LinkAccountIcon, FinancialGuideIcon } from '../../icons';
import { FullPictureModal } from '..';
import { useDownloadURL } from '../../../hooks';

const StyledBox = styled(Box)`
  align-self: center;
  flex: 0 0 60px;
`;

interface IProps {}

const NextSteps: React.FC<IProps> = (props) => {
  const { downloadURL } = useDownloadURL();
  return (
    <Box display='flex' flexDirection='column'>
      <TextBody tag='strong' size='md' weight='bold'>
        Next Steps
      </TextBody>
      <Box display='flex' flexDirection='row' paddingY='20px'>
        <StyledBox>
          <LinkAccountIcon />
        </StyledBox>
        <Box>
          <TextBody tag='p' size='md'>
            Link accounts you hold at other institutions to see your net worth
            and investment allocation.
          </TextBody>
          <FullPictureModal />
        </Box>
      </Box>
      <Box display='flex' flexDirection='row'>
        <StyledBox>
          <FinancialGuideIcon />
        </StyledBox>
        <Box>
          <TextBody tag='p' size='md'>
            Pull your financial info together to guide talks with your advisor.
          </TextBody>
          <Link
            text='Check out the cheat sheet'
            size='sm'
            onClick={() =>
              downloadURL(
                'https://www.ally.com/content/dam/pdf/invest/goal-planning-checklist.pdf',
                'goal-planning-checklist.pdf'
              )
            }
            to='https://www.ally.com/content/dam/pdf/invest/goal-planning-checklist.pdf'
            target='_blank'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NextSteps;
