import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references

interface IFileBlob extends IAPIData {
  blob: Blob | undefined;
}

class FileBlob extends APIData implements IFileBlob {
  blob: Blob | undefined = undefined;

  setData(blob: Blob) {
    this.blob = blob;
    this.setLoaded();
  }
}

export { FileBlob };
export type { IFileBlob };
