import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../../../interfaces';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const HealthCareIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ? props.width : '36px'}
      height={props.height ? props.height : '36px'}
      viewBox='0 0 36 36'
      version='1.1'
      aria-hidden={true}
    >
      <g
        xmlns='http://www.w3.org/2000/svg'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(-211.000000, -372.000000)'>
          <g transform='translate(211.000000, 372.000000)'>
            <rect x='0' y='0' width='36' height='36' />
            <rect
              stroke='#371447'
              strokeWidth='1.38461538'
              x='2.76923077'
              y='8.30769231'
              width='30.4615385'
              height='22.1538462'
              rx='5.53846154'
            />
            <path
              d='M23.5384615,8.30769231 C23.5384615,5.24888446 21.0588078,4.15384615 18,4.15384615 C14.9411922,4.15384615 12.4615385,5.24888446 12.4615385,8.30769231'
              stroke='#371447'
              strokeWidth='1.38461538'
            />
            <path
              d='M18,11.0769231 C22.5882118,11.0769231 26.3076923,14.7964036 26.3076923,19.3846154 C26.3076923,23.9728272 22.5882118,27.6923077 18,27.6923077 C13.4117882,27.6923077 9.69230769,23.9728272 9.69230769,19.3846154 C9.69230769,14.7964036 13.4117882,11.0769231 18,11.0769231 Z M18.2161445,15.2307692 L17.7838555,15.2307692 C17.1121578,15.2307692 16.5676393,15.7752878 16.5676393,16.4469854 L16.5676393,16.4469854 L16.5671538,17.9517692 L15.0623701,17.9522546 C14.3906724,17.9522546 13.8461538,18.4967732 13.8461538,19.1684709 L13.8461538,19.1684709 L13.8461538,19.6007599 C13.8461538,20.2724576 14.3906724,20.8169761 15.0623701,20.8169761 L15.0623701,20.8169761 L16.5671538,20.8167692 L16.5676393,22.3222453 C16.5676393,22.993943 17.1121578,23.5384615 17.7838555,23.5384615 L17.7838555,23.5384615 L18.2161445,23.5384615 C18.8878422,23.5384615 19.4323607,22.993943 19.4323607,22.3222453 L19.4323607,22.3222453 L19.4321538,20.8167692 L20.9376299,20.8169761 C21.6093276,20.8169761 22.1538462,20.2724576 22.1538462,19.6007599 L22.1538462,19.6007599 L22.1538462,19.1684709 C22.1538462,18.4967732 21.6093276,17.9522546 20.9376299,17.9522546 L20.9376299,17.9522546 L19.4321538,17.9517692 L19.4323607,16.4469854 C19.4323607,15.7752878 18.8878422,15.2307692 18.2161445,15.2307692 L18.2161445,15.2307692 Z'
              fill='#371447'
            />
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

export default HealthCareIcon;
