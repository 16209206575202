import React from 'react';
import styled from 'styled-components';

const StyledUL = styled.ul`
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  outline: none;
`;

const UL: React.FC<IProps> = (props) => {
  return (
    <StyledUL
      ref={props.focusRef}
      tabIndex={props.focusRef ? -1 : undefined}
      {...props}
    >
      {props.children}
    </StyledUL>
  );
};

interface IProps {
  focusRef?: React.RefObject<HTMLUListElement>;
}

export default UL;
