import styled from 'styled-components';
import { Box, TextBody } from '@ally/metronome-ui';
import { useFormatter, useAccountNumberMask } from '../../../hooks';
import { WealthAccount } from '../../../models';
import { WidgetLabel, Loader, DL, DD } from '../../atoms';
import { RouteValue } from '../../../types';
import { allyTmEvent, muiAllyTheme } from '../../../utils';
import { Link } from 'react-router-dom';

const StyledDD = styled(DD)`
  font-size: 1.0666rem;
`;

const StyledDL = styled(DL)`
  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

const StyledLink = styled(Link)`
  white-space: pre-wrap;
  text-decoration: none;
  font-weight: bold;
  color: ${muiAllyTheme.colors.bluesuedeshoes};
  font-size: 1.0666rem;
`;

const PortfolioDL = styled(StyledDL)`
  display: flex;
  flex-direction: row;
  dt {
    padding-top: 10px;
  }
  > div {
    flex-grow: 1;
    flex-shrink: 0;
  }
  > div:nth-child(odd) {
    flex-basis: 65%;
  }
  > div:nth-child(even) {
    padding-left: 10px;
  }
  ${({ theme: { media } }): string => `
    ${media.up('xl')} {
      flex-direction: column;
      flex-basis: 50%;
      > div:nth-child(even) {
        padding-left: 0;
      }
    }
  `}
  ${({ theme: { media } }): string => `
    ${media.down('md')} {
      flex-direction: column;
      flex-basis: 100%;
      > div:nth-child(even) {
        padding-left: 0;
      }
    }
  `}
`;

interface IProps {
  account?: WealthAccount;
}

const WealthOverviewDetailsProfile: React.FC<IProps> = (props) => {
  const { arrayToSentence, scrubAccountNumber } = useFormatter();
  const { maskAccountNumber } = useAccountNumberMask();

  return (
    <Box key={props.account && props.account.accountId}>
      <TextBody tag='b' size='md'>
        {!props.account ? (
          <Loader width='s' height='s' />
        ) : (
          <Box data-private fontSize='1.0666rem'>
            <StyledLink
              onClick={() => {
                allyTmEvent('clickEvent', {
                  tagName: 'button',
                  allytmln: `accountDetailLink`,
                  href: '',
                });
              }}
              to={
                `/account-details/${props.account.accountId}?noLookup=true` as RouteValue
              }
            >
              {scrubAccountNumber(props.account.name)}
            </StyledLink>{' '}
            {maskAccountNumber(props.account.accountNumber)}
          </Box>
        )}
      </TextBody>
      <PortfolioDL>
        <Box>
          <WidgetLabel tag='dt'>Portfolio Type</WidgetLabel>
          <StyledDD>
            {!props.account ? (
              <Loader width='s' height='s' />
            ) : (
              <>
                {props.account.model.length > 0
                  ? arrayToSentence(props.account.model)
                  : 'Water'}
              </>
            )}
          </StyledDD>
        </Box>
        <Box>
          <WidgetLabel tag='dt'>Management Style</WidgetLabel>
          <StyledDD>
            {!props.account ? (
              <Loader width='s' height='s' />
            ) : (
              <>
                {props.account.managementStyle.length > 0
                  ? arrayToSentence(props.account.managementStyle)
                  : 'Impact Portfolio'}
              </>
            )}
          </StyledDD>
        </Box>
      </PortfolioDL>
    </Box>
  );
};

export default WealthOverviewDetailsProfile;
