import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../../../interfaces';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const InsuranceIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ? props.width : '36px'}
      height={props.height ? props.height : '36px'}
      viewBox='0 0 36 36'
      version='1.1'
      aria-hidden={true}
    >
      <g
        xmlns='http://www.w3.org/2000/svg'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          transform='translate(-213.000000, -483.000000)'
          stroke='#371447'
          strokeWidth='1.64'
        >
          <g transform='translate(213.036458, 483.987954)'>
            <g transform='translate(0.963542, 15.012046)'>
              <path
                d='M16.1457003,8.53454654 L23.856763,8.53454654 C25.28925,8.49086092 26.00227,7.8064819 25.9958229,6.48140948 C25.9893758,5.15633706 25.2537528,4.49106647 23.788954,4.4855977 C14.9087949,4.4855977 9.94303535,4.4855977 8.89167538,4.4855977 C7.8403154,4.4855977 6.85304712,4.89746777 5.92987051,5.72120791 L0.127778608,11.3284508 L8.04002509,18.868741 L13.2927231,13.9634577 C14.3862127,13.0408753 15.4073214,12.5795841 16.3560492,12.5795841 C17.304777,12.5795841 19.8078136,12.5795841 23.865159,12.5795841 C25.3478132,12.6357278 26.6027284,12.0961287 27.6299044,10.9607868 C28.6570805,9.82544487 30.9197733,7.33919032 34.4179828,3.50202313 C35.2579684,2.48948034 35.1222009,1.54218733 34.0106802,0.66014409 C32.8991595,-0.221899152 31.9255,-0.204237101 31.0897017,0.713130244 L26.4587139,5.78441921 L26.2008682,6.068941'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <line
                x1='4.48184559'
                y1='7.34493149'
                x2='12.1014628'
                y2='14.6782648'
              />
            </g>
            <g transform='translate(12.963542, 0.012046)'>
              <path d='M7.928067,0.224173997 C9.01127955,0.899214744 10.7204725,1.91630101 14.2225742,2.06987628 C15.0718083,2.10711717 14.9805935,2.69612501 14.9866079,2.84216268 C15.0501938,4.38611976 15.1884685,12.9882386 7.47653924,15.9991718 C7.32415157,16.0586678 -0.422668594,12.9245315 0.0181180403,2.7383299 C0.0198193158,2.69901488 0.00892226649,1.97620097 0.689676624,1.98008234 C1.37043098,1.9839637 4.27913652,2.08435002 6.88568216,0.20390878 C7.22731851,-0.0425580669 7.42605788,-0.099546994 7.928067,0.224173997 Z' />
              <polyline
                strokeLinecap='round'
                strokeLinejoin='round'
                points='4.5 7.76801608 7.43277933 10.1818182 11.0562744 5.08522727'
              />
            </g>
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

export default InsuranceIcon;
