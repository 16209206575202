import styled from 'styled-components';
import { Box, Select, SingleDatePicker } from '@ally/metronome-ui';
import { Text, Toggle } from '../../atoms';
import { useFormatter } from '../../../hooks';
import { ChangeEvent, useEffect, useState } from 'react';
import { Timeslot, Timeslots } from '../../../models';
// Moment is imported from Metronome and only being imported because a required prop
// requires () => moment() to work. Metronome team is going to removed moment so do
// not import moment elsewhere
import moment from 'moment';

const Wrapper = styled(Box)`
  ${({ theme: { media } }): string => `
    ${media.up('lg')} {
      max-width: 560px;
  }`}
  > b, p {
    padding-bottom: 15px;
    display: block;
  }
  p {
    padding-bottom: 24px;
  }
  ${({ theme: { media } }): string => `
  ${media.up('md')} {
      input {
        max-width: 520px
      }
  }`}
`;

const StyledText = styled(Text)`
  flex-grow: 1;
  padding-right: 24px;
`;

const StyledSelect = styled(Select)`
  max-width: 250px;
  display: block;
  ${({ theme: { media } }): string => `
    ${media.down('md')} {
      max-width: 100%;
  }`}
`;

const separator = '---';

interface IProps {
  onUpdateAppointment: Function;
  timeslots: Timeslots;
  timeslotError?: string;
}

const AppointmentSelection: React.FC<IProps> = (props) => {
  const { formatDateForInput } = useFormatter();
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [timeOptions, setTimeOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [availableDays, setAvailableDays] = useState<Date[]>([]);

  useEffect(() => {
    const defaultDate = getAvailableDays();
    setSelectedDate(formatDateForInput(defaultDate));
    updateTimeslotOptions(defaultDate);
  }, [props.timeslots.timeslots.length]);

  /**
   * Gets all availables days from the timeslots and then returns the first date.
   * @returns The initial day
   */
  const getAvailableDays = (): Date => {
    const availableDays = props.timeslots.timeslots.reduce(
      (slots: Date[], currSlot: Timeslot) => {
        const startTime = new Date(currSlot.startTime);
        startTime.setHours(0, 0, 0, 0);
        if (!slots.some((slot) => slot.toString() === startTime.toString())) {
          slots.push(startTime);
        }
        return slots;
      },
      []
    );
    setAvailableDays(availableDays);
    return availableDays[0];
  };

  const getFirstDate = (timeslots: Timeslot[]) => {
    return timeslots.reduce((smallest, currSlot) => {
      if (currSlot.startTime < smallest) {
        return currSlot.startTime;
      }
      return smallest;
    }, timeslots[0].startTime);
  };

  const updateTimeslotOptions = (inputDate?: Date) => {
    if (props.timeslots.timeslots.length) {
      const selectedDate = inputDate
        ? inputDate
        : getFirstDate(props.timeslots.timeslots);
      const initial: Timeslot[] = [];

      // Get all the time slots for the specified day
      const selectedDateTimeslots = props.timeslots.timeslots.reduce(
        (slots: Timeslot[], currSlot: Timeslot) => {
          if (currSlot.startTime.getDate() === selectedDate.getDate()) {
            return [...slots, currSlot];
          }
          return slots;
        },
        initial
      );
      // Format the time slots
      const tmpOptions = selectedDateTimeslots.map((slot: Timeslot) => {
        const valueLabel = `${slot.startTime.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
        })} - ${slot.endTime.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
        })}`;
        return {
          value: `${slot.startTime}${separator}${slot.endTime}${separator}${slot.resources[0].id}`,
          label: valueLabel,
        };
      });
      tmpOptions.unshift({ value: '', label: '' });
      setTimeOptions(tmpOptions);
    }
  };

  const onDateSelect = (inputDate?: Date | string) => {
    if (inputDate) {
      const date = new Date(inputDate);
      setSelectedDate(date.toString());
      updateTimeslotOptions(date);
    }
  };

  const onAppointmentSelection = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const [selectedStart, selectedEnd, id] = value.split(separator);
    const [selectedTimeslot] = props.timeslots.timeslots.filter(
      (slot) =>
        slot.startTime.toString() === selectedStart &&
        slot.endTime.toString() === selectedEnd &&
        slot.resources[0].id.toString() === id
    );
    if (selectedTimeslot) {
      selectedTimeslot.workTypeId = props.timeslots.workTypeId;
      props.onUpdateAppointment('selectedAppointment', selectedTimeslot);
    }
  };

  const isDayBlocked = (day: moment.Moment) => {
    return !availableDays.reduce((res, availableDay) => {
      if (!res) {
        return moment(availableDay).isSame(day, 'day');
      }
      return res;
    }, false);
  };

  return (
    <>
      {!!props.timeslots.timeslots.length && selectedDate && (
        <Wrapper>
          <Box maxWidth='250px'>
            <SingleDatePicker
              disabled={false}
              id='dateSelect'
              initialVisibleMonth={() => moment()}
              initializeDate={moment(selectedDate)}
              keepOpenOnDateSelect={false}
              ariaLabel='Select Appointment Date'
              onDateChange={(date) => (date) => onDateSelect(date?.toDate())}
              isDayBlocked={(day: moment.Moment) => isDayBlocked(day)}
            />
            <Text tag='p' size='sm' fontSize='0.7333rem'>
              Pick an available date
            </Text>
          </Box>
          <StyledSelect
            id='appointment'
            options={timeOptions}
            onChange={(e) => onAppointmentSelection(e)}
            error={props.timeslotError}
          />
          <Text tag='p' size='sm' fontSize='0.7333rem'>
            Available options in your time zone (Pick one.)
          </Text>
          <Text size='md' tag='b' weight='bold'>
            Let's Zoom
          </Text>
          <Box display='flex' alignItems='center'>
            <StyledText size='sm' tag='b'>
              We use Zoom for our calls and will have our cameras on (but you
              don’t have to). Let us know if you want closed captioning on for
              your chat
            </StyledText>
            <Toggle
              name='closedCaptioning'
              onChange={(value: boolean) =>
                props.onUpdateAppointment('closedCaptioning', value)
              }
            />
          </Box>
        </Wrapper>
      )}
    </>
  );
};

export default AppointmentSelection;
