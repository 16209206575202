import { useState, ChangeEvent } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import {
  Box,
  Button,
  ButtonGroup,
  TextBody,
  Space,
  FormText,
} from '@ally/metronome-ui';
import CustomInputError from '../../atoms/CustomInputError';
import { useCookie, useSession } from '../../../hooks';
import { ErrorNotificationProps } from '@ally/metronome-ui/dist/cjs/FormFieldHelper';

const StyledButtonGroup = styled(ButtonGroup)`
  padding-top: 10px;
`;

const intialErrorNotification: ErrorNotificationProps = { message: '' };

interface IProps {}

const LoginFC: React.FC<IProps> = (props) => {
  const { getIDPToken, isLoggedIn, login } = useSession();
  const { setCookie } = useCookie();
  const [username, setUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<ErrorNotificationProps>(
    intialErrorNotification
  );
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [loginError, setLoginError] = useState<string>('');
  const [attemptLogin, setAttemptLogin] = useState<boolean>(false);

  const PASSWORD_ERROR_ID = 'password-error';

  const changeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value.trim());
    setUsernameError({
      message: e.currentTarget.value ? '' : 'Username Required',
    });
  };

  const changePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
    setPasswordError(e.currentTarget.value ? '' : 'Password Required');
  };

  const onLogin = async () => {
    setUsernameError({ message: username ? '' : 'Username Required' });
    setPasswordError(password ? '' : 'Password Required');

    if (username && password) {
      setAttemptLogin(true);
      setLoginError('');
      const token = await getIDPToken(username, password)
        .catch(() => {
          setLoginError('Error getting tokens');
        })
        .finally(() => {
          setAttemptLogin(false);
        });
      let validLogin;
      if (token) {
        setCookie('jwt', token);
        // IDPToken call doesn't give you a refresh token
        setCookie('refreshToken', token);
        validLogin = await login(token, token);
      }
      if (!token || !validLogin) {
        setLoginError('Error getting tokens');
        setAttemptLogin(false);
      }
    } else {
      setLoginError('Login Error');
      setAttemptLogin(false);
    }
  };

  const onClear = () => {
    setUsername('');
    setPassword('');
    setUsernameError(intialErrorNotification);
    setPasswordError('');
    setLoginError('');
  };

  return (
    <>
      <Box marginBottom='10px'>
        <FormText
          id='userName'
          variant='input'
          labelContent='Username'
          inputType='text'
          value={username}
          onChange={changeUsername}
          errorNotification={usernameError}
          data-private
        />
      </Box>
      <Box>
        <FormText
          id='password'
          variant='input'
          labelContent='Password'
          inputType='password'
          value={password}
          onChange={changePassword}
          errorNotification={
            passwordError ? { customId: PASSWORD_ERROR_ID } : undefined
          }
          data-private
        />
      </Box>
      <CustomInputError
        errorMessage={passwordError}
        errorId={PASSWORD_ERROR_ID}
      ></CustomInputError>
      <StyledButtonGroup>
        <Button
          variant='login'
          text='Login'
          onClick={onLogin}
          size='sm'
          isLoading={attemptLogin}
        />
        <Button variant='secondary' text='Clear' size='sm' onClick={onClear} />
      </StyledButtonGroup>
      {loginError ? (
        <TextBody type='error' tag='p' size='sm'>
          {loginError}
        </TextBody>
      ) : null}
      {isLoggedIn ? <Redirect to='/' /> : null}

      <Space paddingTop='20px'>
        <TextBody tag='p' size='sm'>
          Enter valid username and password to login (ex: WBtkp2PH7Y/Welcome1)
        </TextBody>
      </Space>
    </>
  );
};

export default LoginFC;
