import React, { useState } from 'react';
import { Box } from '@ally/metronome-ui';
import { Card, OrionWidget, TabSelector } from '../../molecules';
import { Tab } from '../../atoms';
import { AssetTypes } from '../../../enums';
import { ILabeledValue } from '../../../interfaces';
import { useAllyTm, useFormatter } from '../../../hooks';

interface Options {
  groupingType: number;
  accountId: string;
}

interface IProps {
  accountId: string;
}

const AccountHoldingsWidget: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const { camelCase } = useFormatter();
  const initialState: Options = {
    groupingType: AssetTypes.AssetTypes['Asset Type'],
    accountId: props.accountId,
  };

  const [options, setOptions] = useState<Options>(initialState);

  const changeType = (value: number) => {
    const newValues = { ...options, groupingType: value };
    setOptions(newValues);
    allyTmEvent('clickEvent', {
      tagName: 'button',
      allytmln: `${camelCase(AssetTypes.AssetTypes[value].toString())}-equity`,
      href: '',
    });
  };

  // Remove .filter(asset => asset.value < 2) when adding back inv-4197
  return (
    <Card title='Holdings'>
      <Box display='flex' flexDirection='column'>
        <Box
          display='flex'
          flexDirection='row'
          alignItems='flex-end'
          paddingBottom='20px'
        >
          <TabSelector onChange={(value) => changeType(+value)}>
            {AssetTypes.AssetTypesArray.filter(
              (asset: ILabeledValue<number>) => asset.value < 2
            ).map((accountType: ILabeledValue<number>) => (
              <Tab
                value={accountType.value.toString()}
                key={accountType.label}
                text={accountType.label}
              />
            ))}
          </TabSelector>
        </Box>
        <OrionWidget type='positions' widgetId='positions' options={options} />
      </Box>
    </Card>
  );
};

export default AccountHoldingsWidget;
