import { Config } from '../models';

export default {
  environment: 'local',
  orionConfig: {
    baseUrl: 'https://resources.uat.advizr.com',
    host: 'webapi.uat.advizr.com',
  },
  allyDocsRoot: 'https://staging.int.ally.com/resources/pdf/corporate/',
  investDocsRoot: 'https://www.ally.com',
  investConfig: {
    baseUrl: 'https://api-qa.invest.int.ally.com',
    bankUrl: 'https://secure-qa2.ally.com',
    investUrl: 'http://live-dev.tk.local',
    svcproviderUrl: 'https://svcprovider-qabi2.invest.int.ally.com',
  },
  wealthConfig: {
    baseUrl: 'http://localhost:3000',
    proxyUrl: 'http://10.17.20.30:8080', // 'http://localhost:3001', //
  },
  logoutUrl: '/login',
  logRocketId: 'mvv2ld/wealthfe-dev',
  allyTmUrl:
    'https://assets.adobedtm.com/cd392cd56789/2a1d94722ac8/launch-73cf935890bc-development.min.js',
  transmitConfig: {
    useTransmit: false,
  },
} as Config;
