import { Box } from '@ally/metronome-ui';
import { useState } from 'react';
import { Card, TabSelector, BreakDownDetails } from '../../molecules';
import { Tab } from '../../atoms';
import { IWidgetProps } from '../../../interfaces';

type TabsType = 'Asset' | 'Liability';

interface IProps extends IWidgetProps {
  onSuccess: Function;
}

const BreakDownWidget: React.FC<IProps> = (props: IProps) => {
  const [selectedTab, setSelectedTab] = useState<TabsType>('Asset');
  const selectTabHandler = (value: string): void =>
    setSelectedTab(value as TabsType);

  return (
    <Card title='Breakdown'>
      <Box
        display={['flex', 'block', 'block', 'flex']}
        flexDirection='row'
        justifyContent='space-between'
      >
        <TabSelector
          onChange={selectTabHandler}
          aria-label='change listed assets'
          defaultValue={selectedTab}
        >
          <Tab value='Asset' text='Assets' />
          <Tab value='Liability' text='Liabilities' />
        </TabSelector>
      </Box>
      <BreakDownDetails
        type={selectedTab}
        onSuccess={props.onSuccess}
        refresh={props.refresh}
      />
    </Card>
  );
};

export default BreakDownWidget;
