import React, { useState } from 'react';
import { IKeyComponentMap } from '../../../models';
import {
  EducationIcon,
  EmergenciesIcon,
  HomeIcon,
  RetirementIcon,
  LargePurchaseIcon,
  DebtManagementIcon,
  HealthCareIcon,
  InsuranceIcon,
} from '../index';

const GoalIconSelector: React.FC<IProps> = (props) => {
  const [icons] = useState<IKeyComponentMap>({
    emergencyfund: EmergenciesIcon,
    education: EducationIcon,
    retirement: RetirementIcon,
    debtmanagement: DebtManagementIcon,
    homepurchase: HomeIcon,
    insurance: InsuranceIcon,
    largepurchase: LargePurchaseIcon,
    healthcare: HealthCareIcon,
  });
  // Use large Purchase as default icon if it doeesn't exist
  const Icon = icons[props.type.toLowerCase()] || LargePurchaseIcon;
  return <Icon {...props} />;
};

// 'EmergencyFund', 'Education', 'Retirement', 'DebtManagement', 'HomePurchase', 'Insurance', 'LargePurchase', 'HealthCare'
interface IProps {
  type: string;
  height?: string;
  width?: string;
}

export default GoalIconSelector;
