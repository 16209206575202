import axios, { AxiosResponse } from 'axios';

const useDownloadURL = () => {
  const downloadURL = async (url: string, filename: string) => {
    const { data: blob } =
      ((await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).catch((err: Error) => {
        console.error(`Error getting ${filename}`);
        return;
      })) as AxiosResponse) || {};
    if (blob) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    }
  };

  const downloadBlob = (blob: Blob | undefined, fileName: string) => {
    if (blob) {
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = fileName;
      link.style.display = 'none';
      link.click();
      window.open(fileURL, '_blank', 'noopener noreferrer');
    }
  };

  return {
    downloadURL,
    downloadBlob,
  };
};

export default useDownloadURL;
