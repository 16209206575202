import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { LinkProps } from '@ally/metronome-ui';
import { RouteValue } from '../../../types';
import { LI, Link } from '..';
import { muiAllyTheme } from '../../../utils';
import { Destination } from '@ally/transmitigator';

const StyledLI = styled(LI)`
  ${(props: IProps): string => `
    margin-left: ${props.pushRight ? 'auto' : 0};
  `}
`;

const StyledNavLink = styled(Link)`
  font-weight: 400;
  ${(props: IProps): string => `
    color: ${
      props.subNav ? muiAllyTheme.colors['sky-3'] : muiAllyTheme.colors.white
    };
    padding: ${props.subNav ? '0' : '0 15px'};
    font-size: ${props.subNav ? '0.9333rem' : '1.2rem'};
  `}
`;

interface IProps {
  subNav?: boolean;
  pushRight?: boolean;
  to: RouteValue | string;
  transmitdestination?: Destination;
}

const NavLink: React.FC<IProps & LinkProps> = (
  props: React.PropsWithChildren<IProps & LinkProps>
) => {
  const { pathname } = useLocation();

  return (
    <>
      {pathname !== props.to &&
        !(pathname === '/' && props.to === '/wealth-overview') && (
          <StyledLI {...props} onClick={undefined}>
            <StyledNavLink {...props}></StyledNavLink>
          </StyledLI>
        )}
    </>
  );
};

export default NavLink;
