import { TextBody } from '@ally/metronome-ui';

interface IProps {}

const AccordionFooterComponent: React.FC<IProps> = (
  content: React.ReactNode
) => {
  return (
    <TextBody tag='p' size='md'>
      {content}
    </TextBody>
  );
};

export default AccordionFooterComponent;
