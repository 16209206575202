const makeSeg = (count: number) => {
  const segments = [];
  for (let i = 0; i < count; i++) {
    const segment = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    segments.push(segment);
  }
  return segments.join('');
};

export const makeGuid = () => {
  return [
    makeSeg(2), // e190 6fb2
    makeSeg(1), // 593f
    makeSeg(1), // 6a31
    makeSeg(1), // 96e8
    makeSeg(3), // 9256 cf6f a663
  ].join('-'); // e1906fb2-593f-6a31-96e8-9256cf6fa663
};
