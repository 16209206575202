import styled from 'styled-components';
import { ResponsiveValue, Theme, TLengthStyledSystem } from 'styled-system';
import { Box, ToggleSwitchStateful } from '@ally/metronome-ui';

const StyledToggleSwitch = styled(ToggleSwitchStateful)`
  flex-direction: row-reverse;
  > label {
    width: 0;
  }
`;

interface IProps {
  name: string;
  valueOff?: string;
  valueOn?: string;
  onChange: Function;
  labelId?: string;
  buttonId?: string;
  paddingLeft?: ResponsiveValue<
    string | number | symbol,
    Required<Theme<TLengthStyledSystem>>
  >;
}

/**
 * Toggle component that is used when a toggle need a custom label
 */
const Toggle: React.FC<IProps> = (props) => {
  return (
    <Box paddingLeft={props.paddingLeft}>
      <StyledToggleSwitch
        label=''
        name={props.name}
        valueOff={props.valueOff || 'No'}
        valueOn={props.valueOn || 'Yes'}
        onChange={(e) => props.onChange(e)}
        labelId={props.labelId || `${props.name}Label`}
        buttonId={props.buttonId || props.name}
      />
    </Box>
  );
};

export default Toggle;
