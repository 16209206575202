import { AnalyticsEventPath } from '../interfaces';
import { AnalyticsConversionEventStep, AnalyticsEventType } from '../types';

declare global {
  interface Window {
    allytm: any;
  }
}

const allyTmEvent = (
  event: AnalyticsEventType,
  eventPath: string | AnalyticsEventPath,
  conversionEventStep: AnalyticsConversionEventStep | null = null,
  appID?: number
) => {
  const allytm = window.allytm;
  if (allytm) {
    if (event === 'conversionEvent') {
      allytm.event(event, conversionEventStep, eventPath, null, { appID });
    } else {
      allytm.event(event, eventPath);
    }
  }
};

export default allyTmEvent;
