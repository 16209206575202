import { allyTmEvent } from '../utils';

interface IAPIData {
  isLoading: boolean;
  hasLoaded: Boolean;
  lastUpdated: Date | null;
  error: string;
}

/**
 * Generic class for data about api calls.
 *
 * Values:
 *  isLoading: Boolean, If the API is currently loading data.
 *  hasLoaded: Boolean, If the data has ever been loaded.
 *  lastUpdated: Date, Date/Time of last update.
 *  error: string, If the last api call resulted in an error.
 */
class APIData implements IAPIData {
  // If the API is currently loading data
  isLoading: boolean = false;
  // If the data has ever been loaded
  hasLoaded: Boolean = false;
  // Date/Time of last update
  lastUpdated: Date | null = null;
  // If the last api call resulted in an error
  error: string = '';
  // Name to log if there is an error
  dataName: string = '';
  // allyTm event function to trigger adobe tagmanager event
  allyTmEvent: Function = allyTmEvent;

  /**
   * Constuctor for class extended by APIData, contains generic data and functions for an api's calls result
   * @param isLoading Optional Boolean: Sets the isLoading state, if not present sets it to default of false
   */
  constructor(isLoading?: boolean) {
    this.isLoading = isLoading || false;
  }

  /**
   * To be used right before calling the api endpoint.
   * Sets isLoading to true and clears out any error.
   */
  setIsLoading(isLoading = true) {
    this.isLoading = isLoading;
    this.error = '';
  }

  /**
   * To be used when setting the data with the api's results.
   * Sets hasLoaded to true, isLoading to false, lastUpdated to current Date, and clears out any error.
   */
  setLoaded() {
    this.isLoading = false;
    this.hasLoaded = true;
    this.error = '';
    this.lastUpdated = new Date();
  }

  /**
   * To be used when the api results in an error.
   * Sets error to error param or default error message if not provided.
   * Also sets hasLoaded to true, isLoading to false, lastUpdated to current Date.
   * @param error Optional String: Sets the error message, if not provided sets error message to default 'Error loading'
   * that can be displayed in the UI.
   */
  setError(error?: string) {
    this.isLoading = false;
    this.hasLoaded = true;
    this.error = error || 'Error loading'; // TODO: Update to generic error message we can display on UI
    this.lastUpdated = new Date();
    if (this.dataName) {
      this.allyTmEvent(
        'customError',
        `Error found while loading ${this.dataName}`
      );
    }
  }

  /**
   * To be used to check if the data has not been loaded yet and you don't want to force another api call if data
   * already exists or is currently being fetched.
   * Checks to see if the data has never been loading and is not in the process of loading.
   * @returns Boolean True if has never been loaded and not currently in a loading state
   */
  shouldInitialize(): boolean {
    return !this.hasLoaded && !this.isLoading;
  }

  /**
   * Checks to see if the last time a value was reloaded was greater than 15 seconds ago.
   * This should be used in place of isLoading to determine if a value should be refreshed due
   * to an issue encountered where isLoading was stuck on true after loadings a value.
   * @returns Boolean True if the last time this value was loaded was more than 15 seconds ago
   */
  shouldReload(): boolean {
    if (!this.hasLoaded || !this.lastUpdated) return true;
    // Have to set lastUpdated to a new Date since it's possible it's only a string
    const lastUpdatedDate = new Date(this.lastUpdated);
    return (
      new Date() >
      new Date(lastUpdatedDate.setSeconds(lastUpdatedDate.getSeconds() + 15))
    );
  }
}

export { APIData };
export type { IAPIData };
