import { ChangeEvent, useState, createRef, RefObject, useEffect } from 'react';
import {
  Box,
  TextBody,
  ButtonGroup,
  Button,
  CardSelectionGroup,
  CardSelectionCard,
  Select,
  FormText,
} from '@ally/metronome-ui';
import styled from 'styled-components';
import { LabeledInput, WealthHeading, Alert } from '../../molecules';
import { useInputWithValidation, useConfig, useAllyTm } from '../../../hooks';
import { Link, Loader, Text } from '../../atoms';
import { ProspectAssessment, Timeslot, Timeslots } from '../../../models';
import { KeyValuePair } from '../../../interfaces';
import { AppointmentSelection } from '..';
import { muiAllyTheme } from '../../../utils';
import {
  Key,
  SingleSelectSelectionCallback,
} from '@ally/metronome-ui/dist/cjs/CardSelectionGroup/types';

const Wrapper = styled(Box)`
  width: 100%;
  > p {
    padding-top: 15px;
  }
  > b {
    padding-bottom: 15px;
    display: block;
  }
  > b:not(:first-child) {
    margin-top: 25px;
    padding-top: 25px;
    border-top: 1px solid ${muiAllyTheme.colors['grey-80']};
  }
  ${({ theme: { media } }): string => `
    ${media.up('lg')} {
      max-width: 760px;
  }`}
  ${({ theme: { media } }): string => `
    ${media.up('md')} {
      input {
        max-width: 520px
      }
  }`}
`;

const StyledCardSelectionGroup = styled(CardSelectionGroup)`
  max-width: 367px;
  ${({ theme: { media } }): string => `
    ${media.down('md')} {
      max-width: 100%;
  }`}
`;

const StyledSelect = styled(Select)`
  max-width: 250px;
  display: block;
  ${({ theme: { media } }): string => `
    ${media.down('md')} {
      max-width: 100%;
  }`}
`;

const StyledButtonGroup = styled(ButtonGroup)`
  padding: 20px 0;
  align-items: baseline;
  ${({ theme: { media } }): string => `
    ${media.down('md')} {
      align-items: center;
      > span {
        margin-top: 12px;
      }
    }
  `}
`;

const investableAssetsOptions = [
  { label: '$100k - $249k', value: '$100k - $249k' },
  { label: '$250k - $499k', value: '$250k - $499k' },
  { label: '$500k - $999k', value: '$500k - $999k' },
  { label: '$1M - $5M', value: '$1M - $5M' },
  { label: 'More than $5M', value: 'More than $5M' },
  { label: 'Need help deciding', value: "I don't know" },
];

const cardSelectOptions: string[] = [
  'Prioritize competing goals',
  'Increase your net worth',
  'Simplify your overall financial picture',
  'Help you in another way',
];

interface IProps {
  setInputValues: Function;
  inputValues: ProspectAssessment;
  nextStep: (shouldSubmit: boolean, saveEsign: boolean) => void;
  isLoading: boolean;
  subHeader?: string;
  timeslots: Timeslots;
}

const ProspectAssessmentForm: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();

  useEffect(() => {
    allyTmEvent('pageview', 'Wealth:Prospect:Lead Form:Home');
    allyTmEvent(
      'conversionEvent',
      'Wealth:Prospect:Lead Form:Home',
      'initiated',
      props.inputValues.assessmentId
    );
  }, []);

  const [errors, setErrors] = useState<KeyValuePair<string>[]>([]);
  const { validate } = useInputWithValidation();
  const { investConfig } = useConfig();

  const phoneRef: RefObject<HTMLDivElement> = createRef();
  const emailRef: RefObject<HTMLDivElement> = createRef();
  const focusTopicsRef: RefObject<HTMLDivElement> = createRef();
  const investableAssetsRef: RefObject<HTMLSelectElement> = createRef();
  const emailJoinRef: RefObject<HTMLDivElement> = createRef();
  const nameJoinRef: RefObject<HTMLDivElement> = createRef();
  const appointmentRef: RefObject<HTMLDivElement> = createRef();

  const changeSelectValue = (
    e: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>
  ) => {
    const { id, value } = e.target;
    props.setInputValues({ ...props.inputValues, [id]: value });
    allyTmEvent('clickEvent', { tagName: 'button', allytmln: id, href: value });
  };

  const cardSelect: SingleSelectSelectionCallback = (cardId?: Key): void => {
    if (cardId) {
      props.setInputValues({
        ...props.inputValues,
        advisorFocusTopics: cardId,
      });
      allyTmEvent('clickEvent', {
        tagName: 'button',
        allytmln: 'advisorMostImportant',
        href: cardId,
      });
    }
  };

  const passValue = (id: string, value: string | boolean) => {
    props.setInputValues({ ...props.inputValues, [id]: value });
  };

  const onUpdateAppointment = (
    property: 'selectedAppointment' | 'closedCaptioning',
    value: Timeslot | boolean
  ) => {
    props.setInputValues({
      ...props.inputValues,
      [property]: value,
    });
  };

  const isFormInvalid = () => {
    const validationErrors: KeyValuePair<string>[] = [];
    let scrollLocationFound: boolean = false;
    const {
      email,
      phoneNumber,
      investableAssets,
      advisorFocusTopics,
      emailJoin,
      nameJoin,
      selectedAppointment,
    } = props.inputValues;
    if (
      appointmentRef &&
      appointmentRef.current &&
      (!selectedAppointment ||
        new Date(selectedAppointment.startTime).getFullYear() ===
          new Timeslot().defaultYear)
    ) {
      validationErrors.push({
        id: 'appointmentSelector',
        message: 'Pick a time to continue.',
      });
      if (!scrollLocationFound) {
        appointmentRef.current.scrollIntoView();
        scrollLocationFound = true;
      }
    }

    const phoneValidateMessage = validate(phoneNumber, 'phoneNumber', true);
    if (phoneValidateMessage) {
      validationErrors.push({
        id: 'phoneNumber',
        message: phoneValidateMessage,
      });
      if (phoneRef && phoneRef.current && !scrollLocationFound) {
        phoneRef.current.scrollIntoView();
        scrollLocationFound = true;
      }
    }

    const emailValidatedMessage = validate(email, 'yourEmail', true);
    if (emailValidatedMessage) {
      validationErrors.push({ id: 'email', message: emailValidatedMessage });
      if (emailRef && emailRef.current && !scrollLocationFound) {
        emailRef.current.scrollIntoView();
        scrollLocationFound = true;
      }
    }

    if (focusTopicsRef && focusTopicsRef.current && !advisorFocusTopics) {
      validationErrors.push({
        id: 'advisorFocusTopics',
        message: 'Pick one of the options above to continue.',
      });
      if (!scrollLocationFound) {
        focusTopicsRef.current.scrollIntoView();
        scrollLocationFound = true;
      }
    }

    if (!investableAssets) {
      validationErrors.push({
        id: 'investableAssets',
        message:
          'Pick your preferred investment amount to continue. If you’re not sure, choose “Need help deciding”.',
      });

      if (
        investableAssetsRef &&
        investableAssetsRef.current &&
        !scrollLocationFound
      ) {
        investableAssetsRef.current.scrollIntoView();
        scrollLocationFound = true;
      }
    }

    // Only validate if one or the other exists
    const emailJoinValidatedMessage = validate(emailJoin, 'email', !!nameJoin);
    if (emailJoinValidatedMessage) {
      validationErrors.push({
        id: 'emailJoin',
        message: emailJoinValidatedMessage,
      });
      if (emailJoinRef && emailJoinRef.current && !scrollLocationFound) {
        emailJoinRef.current.scrollIntoView();
        scrollLocationFound = true;
      }
    }
    if (nameJoinRef && nameJoinRef.current && !nameJoin && emailJoin) {
      validationErrors.push({
        id: 'nameJoin',
        message: 'Please fill out a name.',
      });
      if (!scrollLocationFound) {
        nameJoinRef.current.scrollIntoView();
        scrollLocationFound = true;
      }
    }
    return validationErrors;
  };

  const submit = () => {
    setErrors([]);
    const validationErrors = isFormInvalid();
    setErrors(validationErrors);
    allyTmEvent(
      'customError',
      validationErrors
        .map((error: KeyValuePair<string>) => error.message)
        .join(', ')
    );
    validationErrors.length === 0 && props.nextStep(false, false);
  };

  return (
    <>
      <WealthHeading
        content={
          props.timeslots.error
            ? 'A little info about you'
            : 'When should we connect?'
        }
        subHeader={props.subHeader}
      />
      <Loader isLoading={!!props.isLoading}>
        <Wrapper>
          <Alert
            show={errors.length > 2}
            variant='error'
            focusOnMount
            cancellable={false}
            heading={`Oops! We need a few more details from you. Keep filling out the form to continue.`}
          />
          {!props.timeslots.error && (
            <Box ref={appointmentRef}>
              <AppointmentSelection
                onUpdateAppointment={onUpdateAppointment}
                timeslots={props.timeslots}
                timeslotError={
                  errors.find((e) => e.id === 'appointmentSelector')?.message
                }
              />
            </Box>
          )}
          <TextBody tag='b' size='md' weight='bold'>
            What's the most important thing our advisors can help you do?
          </TextBody>
          <StyledCardSelectionGroup
            align='stacked'
            variant='single-select'
            ariaLabelledby='group-label'
            onSelection={cardSelect}
            defaultSelection={props.inputValues.advisorFocusTopics}
            errorNotification={{
              message: errors.find((e) => e.id === 'advisorFocusTopics')
                ?.message,
            }}
            ref={focusTopicsRef}
          >
            {cardSelectOptions.map((option: string) => (
              <CardSelectionCard
                key={option}
                cardId={option}
                title={option}
                ariaLabel={option}
              />
            ))}
          </StyledCardSelectionGroup>
          <Text tag='p' size='sm' fontSize='0.7333rem'>
            (Spoiler: Our advisors can help with all these things)
          </Text>
          <TextBody tag='b' size='md' weight='bold'>
            How much are you interested in investing?
          </TextBody>
          <StyledSelect
            ref={investableAssetsRef}
            id='investableAssets'
            options={investableAssetsOptions}
            onChange={changeSelectValue}
            placeholder=' '
            value={props.inputValues.investableAssets}
            error={errors.find((e) => e.id === 'investableAssets')?.message}
          />
          <TextBody tag='p' size='md'>
            You’ll need at least $100,000 in investable assets to work with an
            advisor. Investable assets are things like cash or any investments
            you control. They don’t include things like current 401ks or
            property. Our advisors can help you figure out what counts, too.
          </TextBody>
          <TextBody tag='b' size='md' weight='bold'>
            And what's the best contact info for you?
          </TextBody>
          <Box display='flex' flexDirection={['column', 'column', 'row']}>
            <Box
              flexBasis={['auto', 'auto', '250px']}
              marginRight={['0', '0', '20px']}
              marginBottom={['10px', '10px', '0']}
              ref={phoneRef}
            >
              <LabeledInput
                dataPrivateField='true'
                passValue={passValue}
                require={true}
                id='phoneNumber'
                label='Number'
                validationType='phoneNumber'
                value={props.inputValues.phoneNumber}
                autoComplete='off'
                errorMessage={
                  errors.find((e) => e.id === 'phoneNumber')?.message
                }
              />
            </Box>
            <Box
              flexBasis={['auto', 'auto', '250px']}
              marginRight={['0', '0', '20px']}
              ref={emailRef}
            >
              <LabeledInput
                dataPrivateField='true'
                passValue={passValue}
                require={true}
                id='email'
                label='Email'
                validationType='email'
                value={props.inputValues.email}
                errorMessage={errors.find((e) => e.id === 'email')?.message}
              />
            </Box>
          </Box>
          <TextBody tag='p' size='md'>
            We'll only use this number to talk about Personal Advice.
          </TextBody>
          {/* Commented out untill phase 2 when text alerts are set up
          <Box
            display='flex'
            alignItems='center'
            borderTop={`1px solid ${styledTheme.colors['grey-80']}`}
            marginTop='25px'
            paddingTop='25px'
          >
            <TextBody tag='b' size='md' fontSize='1rem' fontWeight='700'>
              Do you want to get text messages?
            </TextBody>
            <Toggle
              paddingLeft='45px'
              name='textMessage'
              onChange={(e: string) => passValue('textMessage', e)}
            />

          </Box>
          */}
          <TextBody tag='b' size='md' weight='bold'>
            Do you want someone to join you for this meeting?
          </TextBody>
          <Box display='flex' flexDirection={['column', 'column', 'row']}>
            <Box
              flexBasis={['auto', 'auto', '250px']}
              marginRight={['0', '0', '20px']}
              marginBottom={['10px', '10px', '0']}
              ref={nameJoinRef}
            >
              <LabeledInput
                dataPrivateField='true'
                passValue={passValue}
                id='nameJoin'
                label='Name'
                value={props.inputValues.nameJoin}
                errorMessage={errors.find((e) => e.id === 'nameJoin')?.message}
              />
            </Box>
            <Box
              flexBasis={['auto', 'auto', '250px']}
              marginRight={['0', '0', '20px']}
              ref={emailJoinRef}
            >
              <LabeledInput
                dataPrivateField='true'
                passValue={passValue}
                id='emailJoin'
                label='Email'
                validationType='email'
                value={props.inputValues.emailJoin}
                errorMessage={errors.find((e) => e.id === 'emailJoin')?.message}
                contextualHelp={{
                  variant: 'stacked',
                  content: 'This is where we’ll send your meeting info.',
                }}
              />
            </Box>
          </Box>
          <TextBody tag='b' size='md' weight='bold'>
            Is there anything you want our team to know?
          </TextBody>
          <Box maxWidth={['100%', '100%', '520px']}>
            <FormText
              variant='textarea'
              rows={3}
              maxLength={1000}
              characterCounter={{ show: true }}
              placeholder='Optional comments'
              allytmfn='comments'
              value={props.inputValues.comments || ''}
              labelContent=''
              onChange={(e) => passValue(e.target.id, e.target.value)}
              id='comments'
            />
          </Box>
          <StyledButtonGroup>
            <Button
              allytmln='prospectFormSubmit'
              variant='primary'
              text='Next'
              onClick={submit}
            />
            <Link
              allytmln='prospectFormCancel'
              to={`${investConfig.bankUrl}/dashboard`}
              text='Cancel'
            />
          </StyledButtonGroup>
        </Wrapper>
      </Loader>
    </>
  );
};

export default ProspectAssessmentForm;
