import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../../../interfaces';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const LargePurchaseIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ? props.width : '37px'}
      height={props.height ? props.height : '39px'}
      viewBox='0 0 37 39'
      version='1.1'
      aria-hidden={true}
    >
      <g
        xmlns='http://www.w3.org/2000/svg'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(1.000000, 1.000000)'>
          <path
            d='M28.3418182,9.04909091 L35.0672727,29.6181818 C35.1716271,29.9613885 35.1286803,30.3325252 34.9487036,30.6428299 C34.7687268,30.9531346 34.4679045,31.1747035 34.1181818,31.2545455 L19.0145455,36.1636364 C18.3689995,36.3935289 17.655401,36.0866815 17.3781818,35.46 L10.6036364,14.8909091 C10.3909091,14.2527273 16.5763636,6.31636364 16.5763636,6.31636364 C17.0146759,5.73986186 17.7626755,5.49269677 18.4581818,5.69454545 C18.4581818,5.69454545 28.1290909,8.41090909 28.2763636,8.96727273 L28.3418182,9.04909091 Z'
            stroke='#371447'
            strokeWidth='1.63636364'
          />
          <ellipse
            stroke='#371447'
            strokeWidth='1.63636364'
            transform='translate(19.121002, 10.885040) rotate(-18.030000) translate(-19.121002, -10.885040) '
            cx='19.1210023'
            cy='10.8850395'
            rx='1.27636364'
            ry='2.06181818'
          />
          <path
            d='M18.9654545,10.6036364 L18.4745455,10.7509091 C18.4745455,10.7509091 18.2781818,9.81818182 18.6545455,9.49090909 C19.5381818,9.19636364 20.0127273,7.47818182 19.3581818,5.44909091 C18.7036364,3.42 17.2309091,2.30727273 16.3636364,2.60181818 C15.4963636,2.89636364 14.9727273,4.58181818 15.5945455,6.54545455 C15.5945455,6.54545455 15.12,7.13454545 14.6454545,7.77272727 C14.5472727,7.54363636 14.4490909,7.29818182 14.3672727,7.05272727 C13.5327273,4.5 14.2527273,2.14363636 16.0036364,1.47272727 C17.7545455,0.801818182 19.7672727,2.45454545 20.6018182,4.99090909 C21.4363636,7.52727273 20.7163636,10.0309091 18.9654545,10.6036364 Z'
            fill='#371447'
            fillRule='nonzero'
          />
          <text
            fillRule='nonzero'
            transform='translate(24.176150, 22.810086) rotate(-18.170000) translate(-24.176150, -22.810086) '
            fontFamily='Lato-Black, Lato'
            fontSize='20.6018182'
            fontWeight='700'
            fill='#371447'
          >
            <tspan x='16.8125136' y='30.810086'>
              $
            </tspan>
          </text>
          <path
            d='M0.0231711648,3.83535229 C3.16804118,-1.27654943 6.45245653,0.492035793 7.86376308,1.36343211 C8.93008358,2.09255965 10.1218535,3.51524752 11.2352176,4.31550944 C11.8938273,4.7956666 15.3468063,6.70505885 16.3868075,3.14107951'
            id='Path'
            stroke='#371447'
            strokeWidth='1.63636364'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default LargePurchaseIcon;
