import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, ButtonGroup } from '@ally/metronome-ui';
import { ButtonProps } from '@ally/metronome-ui/dist/cjs/Button';
import { AccordionFooterComponent } from '../../molecules';
import { useNotifications, useConfig } from '../../../hooks';
import { Link } from '../../atoms/index';

const submitButtonProps: ButtonProps = {
  variant: 'primary',
  text: 'Submit',
  size: 'sm',
};

const isSubmittingButtonProps: ButtonProps = {
  ...submitButtonProps,
  variant: 'disabled',
  isLoading: true,
};

const StyledButtonGroup = styled(ButtonGroup)`
  align-items: center;
`;

interface IProps {
  setIsSubmitted: Function;
}

const DocumentSubmitComponent: React.FC<IProps> = (props: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submissionError, hasSubmissionError] = useState<boolean>(false);
  const { investConfig } = useConfig();
  const { submitDocumentAgreements, suppressNotification } = useNotifications();

  const submit = async () => {
    setIsSubmitting(true);
    const submitted = await submitDocumentAgreements().catch((err: Error) => {
      hasSubmissionError(true);
    });
    if (submitted) {
      props.setIsSubmitted(true);
      suppressNotification('PendingAccounts');
    }
    setIsSubmitting(false);
  };

  const [buttonConfig, setButtonConfig] = useState<ButtonProps>(
    Object.assign(submitButtonProps, { onClick: submit })
  );

  useEffect(() => {
    isSubmitting
      ? setButtonConfig(isSubmittingButtonProps)
      : setButtonConfig(submitButtonProps);
  }, [isSubmitting]);

  return (
    <Box paddingTop='20px'>
      {AccordionFooterComponent(
        <>
          {submissionError
            ? `Sorry! there was an error processing your request. Please try again in a moment`
            : `Once you select Submit we'll begin to open your Personal Advice
          accounts unless we find we need additional information.`}
        </>
      )}
      <Box paddingTop='20px'>
        <StyledButtonGroup>
          <Button {...buttonConfig} />
          <Link
            to={isSubmitting ? '#' : `${investConfig.bankUrl}/dashboard`}
            text='Cancel'
            size='sm'
            disabled={isSubmitting}
          />
        </StyledButtonGroup>
      </Box>
    </Box>
  );
};

export default DocumentSubmitComponent;
