import { useEffect, useState, forwardRef, RefObject, Ref } from 'react';
import styled from 'styled-components';
import { Box, Button } from '@ally/metronome-ui';
import {
  muiCaretDown,
  muiCaretRight,
} from '@ally/metronome-icons/dist/cjs/glyphs';
import { useSession } from '../../../hooks';

const DrawerWrapper = styled(Box)`
  ${({ theme: { colors } }): string => `
    border-top: 1px ${colors['grey-80']} solid;
  `}
`;

interface IProps<T> {
  title: string;
  focusRef: React.RefObject<T>;
  contents: React.ReactNode;
  defaultOpen?: boolean;
  noIndent?: boolean;
  onOpen?: Function;
  onClose?: Function;
}

const Drawer: React.FC<IProps<HTMLElement>> = forwardRef(
  (props, focusRef: Ref<HTMLElement>) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { refreshSessionTimer } = useSession();

    useEffect(() => {
      if (props.defaultOpen) {
        setIsOpen(props.defaultOpen);
      }
    }, [props.defaultOpen]);

    useEffect(() => {
      if (isOpen) {
        const propRef: RefObject<HTMLElement> = props.focusRef as RefObject<HTMLElement>;
        if (propRef) {
          propRef.current?.focus();
        }
      }
    }, [isOpen, focusRef]);

    const toggleDrawer = () => {
      !isOpen && props.onOpen && props.onOpen();
      isOpen && props.onClose && props.onClose();
      setIsOpen(!isOpen);
      refreshSessionTimer();
    };

    return (
      <DrawerWrapper>
        <Box
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          height='35px'
          paddingLeft={['10px', '10px', '20px']}
        >
          <Button
            onClick={toggleDrawer}
            aria-expanded={isOpen}
            icon={{ map: isOpen ? muiCaretDown : muiCaretRight }}
            text={props.title}
            variant='link'
          />
        </Box>
        {isOpen && (
          <Box
            padding={['10px', '10px', '20px']}
            paddingLeft={
              props.noIndent
                ? ['10px', '10px', '20px']
                : ['31px', '31px', '41px']
            }
          >
            {props.contents}
          </Box>
        )}
      </DrawerWrapper>
    );
  }
);

export default Drawer;
