import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';
import { useSession, useConfig, useTransmit } from '../../../hooks';
import { RouteValue } from '../../../types';

interface IProps extends RouteProps {
  path: RouteValue;
}

const AuthRoute: React.FC<IProps> = (props) => {
  const { isLoggedIn } = useSession();
  const { environment } = useConfig();
  const { ticketID } = useTransmit();
  const location = useLocation();

  return (
    <>
      {isLoggedIn && !ticketID ? (
        <Route {...props}>{props.children}</Route>
      ) : (
        <Redirect
          push
          to={{
            pathname:
              environment === 'prod' || environment === 'qa'
                ? '/authenticating'
                : '/login',
            state: { from: window.location.pathname },
            search: location.search,
          }}
        />
      )}
    </>
  );
};

export default AuthRoute;
