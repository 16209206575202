import { Token, TokenJWT, HouseholdIds } from './';

class Session {
  username: string = '';
  clientID: string = '';
  sviuserid: string = '';
  guid: string = '';
  IDPToken: TokenJWT;
  orionToken: Token;
  householdIds: HouseholdIds;

  constructor(
    username: string = '',
    clientID: string = '',
    sviuserid: string = '',
    guid: string = '',
    IDPToken: TokenJWT = new TokenJWT(),
    orionToken: Token = new Token(),
    householdIds: HouseholdIds = new HouseholdIds()
  ) {
    this.username = username;
    this.clientID = clientID;
    this.sviuserid = sviuserid;
    this.guid = guid;
    this.IDPToken = IDPToken;
    this.orionToken = orionToken;
    this.householdIds = householdIds;
  }
}

export default Session;
