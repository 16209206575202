import React from 'react';
import styled from 'styled-components';
import { IconProps } from '../../../interfaces';

const StyledSVG = styled.svg`
  flex-shrink: 0;
`;

const EducationIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSVG
      xmlns='http://www.w3.org/2000/svg'
      width={props.width ? props.width : '36px'}
      height={props.height ? props.height : '38px'}
      viewBox='0 0 36 38'
      version='1.1'
      aria-hidden={true}
    >
      <g
        xmlns='http://www.w3.org/2000/svg'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g transform='translate(-211.000000, -141.000000)'>
          <g transform='translate(211.000000, 141.000000)'>
            <g transform='translate(0.000000, 0.230769)'>
              <path
                d='M6.92307692,20.8698167 L6.92307692,25.5345014 C13.8277437,29.9320323 21.2058556,29.9320323 29.0574128,25.5345014 C29.0834265,25.5163302 29.0834265,24.6881876 29.0574128,20.6212816'
                stroke='#371447'
              />
              <path
                d='M29.8137838,6.14570517 L22.997116,23.4670512 L6.17685787,30.528473 L13.5371016,13.7373367 L29.8137838,6.14570517 Z'
                stroke='#371447'
                transform='translate(18.000000, 18.329170) rotate(-315.000000) translate(-18.000000, -18.329170) '
              />
              <path
                d='M27.219003,29.3857365 L26.1009182,29.3857365 L26.1009182,23.1644046 C26.1184194,23.0613543 26.1204903,22.992918 26.107131,22.9590955 C26.0814271,22.9214617 25.9751219,22.8298943 25.7962969,22.7139338 L18.3426873,18.7648549 L18.8549723,17.743368 L26.3495255,21.7141397 L26.3687377,21.7264341 C26.6920919,21.9333576 26.9163491,22.1272781 27.0504001,22.3419961 C27.2036075,22.5873982 27.2510628,22.8844299 27.219003,23.2258948 L27.219003,29.3857365 Z'
                fill='#371447'
                fillRule='nonzero'
              />
              <g transform='translate(15.440724, 17.185141)' fill='#371447'>
                <g transform='translate(9.084439, 10.308228)'>
                  <path
                    d='M1.13983426,1.29911717 L1.7075556,1.71791357 C0.638660811,3.24871033 0.470895419,4.98171638 1.20967551,6.96030729 L0.557268721,7.21765953 C-0.263214056,5.02025358 -0.0708309025,3.03294759 1.13983426,1.29911717 Z'
                    fillRule='nonzero'
                  />
                  <path
                    d='M3.28566533,1.05038309 L3.95237258,1.26556046 C3.26944929,3.50098913 3.64986512,4.84652413 5.06273899,5.45538422 L4.79254227,6.11777802 C2.99752406,5.34423768 2.50552544,3.60403311 3.28566533,1.05038309 Z'
                    fillRule='nonzero'
                    transform='translate(3.995237, 3.584081) rotate(-13.000000) translate(-3.995237, -3.584081) '
                  />
                  <path
                    d='M1.70411107,1.85022046 L2.33476171,2.15960763 C1.61513666,3.70929146 1.72413276,5.17680551 2.6662137,6.61227446 L2.08689314,7.01393916 C1.00668973,5.36801005 0.877607814,3.63006225 1.70411107,1.85022046 Z'
                    fillRule='nonzero'
                  />
                  <path
                    d='M2.71307965,1.37842478 L3.36022951,1.6494368 C2.51172712,3.78995873 2.64748695,5.19308388 3.69701299,5.95770892 L3.29291158,6.54369238 C1.92574777,5.5476545 1.75596913,3.79293308 2.71307965,1.37842478 Z'
                    fillRule='nonzero'
                    transform='translate(2.902852, 3.961059) rotate(-9.000000) translate(-2.902852, -3.961059) '
                  />
                  <ellipse
                    stroke='#371447'
                    strokeWidth='0.5616'
                    cx='2.22296551'
                    cy='1.26630178'
                    rx='1.1766018'
                    ry='1.23102045'
                  />
                </g>
                <ellipse
                  cx='3.10906723'
                  cy='1.12236452'
                  rx='3.10729769'
                  ry='1'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </StyledSVG>
  );
};

export default EducationIcon;
