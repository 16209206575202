import React, { useState } from 'react';

interface IProps {
  SubmitComponent?: React.ReactNode;
}

const ConsentAccordion: React.FC<IProps> = (
  props: React.PropsWithChildren<IProps>
) => {
  const [shownCard, setShownCard] = useState<number>(0);

  const submitCard = (nextCard: number) => {
    setShownCard(nextCard);
  };

  return (
    <>
      {React.Children.map(props.children, (child, index) => {
        return React.isValidElement(child)
          ? React.cloneElement(child, {
              submitCard: () =>
                child.props.submitCard
                  ? child.props.submitCard()
                  : submitCard(index + 1),
              shown: index === shownCard,
              isSubmitted: shownCard > index,
            })
          : child;
      })}
      {props.SubmitComponent &&
        shownCard === React.Children.count(props.children) &&
        props.SubmitComponent}
    </>
  );
};

export default ConsentAccordion;
