import { useEffect } from 'react';
import { TextHeading, TextBody, Box, Space } from '@ally/metronome-ui';
import { ConsentAccordion, AccordionFooterComponent } from '../../molecules';
import { AccordionCard, UL, LI, Link, BlobLink } from '../../atoms';
import { PendingAccount } from '../../../models';
import {
  useNotifications,
  useConfig,
  useDocumentsController,
} from '../../../hooks';
import { DocumentSubmitComponent } from '../index';
import { FileTypesValues } from '../../../types/index';

import styled from 'styled-components';

const StyledLI = styled(LI)`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
  font-size: 1.067rem;
  a {
    font-size: 1.067rem;
  }
`;
const DiscLI = styled(StyledLI)`
  list-style: disc;
  font-size: 1.067rem;
`;

const StyledUL = styled(UL)`
  padding-inline-start: 40px;
  list-style-type: disc;
`;

const StyledTextBody = styled(TextBody)`
  padding-bottom: 10px;
`;

const StyledLink = styled(Link)`
  margin: 0 5px;
`;

interface IProps {
  setIsSubmitted: Function;
}

const DocumentReviewAccordion: React.FC<IProps> = (props) => {
  const { investDocsRoot } = useConfig();
  const { pendingAccounts, getPendingAccounts } = useNotifications();
  const { getLatestProposal, cachedDocuments } = useDocumentsController();

  useEffect(() => {
    getLatestProposal();
    getPendingAccounts();
  }, []);

  return (
    <Box>
      <TextHeading tag='h1' size='lg'>
        Let's open your Personal Advice accounts
      </TextHeading>
      <Box marginTop='20px' width={['100%', '100%', 2 / 3]}>
        <TextBody tag='p' size='md'>
          Before your advisor can open your new accounts, we need you to review
          your information and a few account documents. Once you've reviewed and
          submitted, we'll finalize your account opening.
        </TextBody>
      </Box>
      <Box width={['100%', '100%', 2 / 3]} marginTop='22px'>
        <ConsentAccordion SubmitComponent={DocumentSubmitComponent(props)}>
          <AccordionCard title='Your New Accounts'>
            <Space paddingBottom='20px'>
              <TextBody tag='p' size='md'>
                Based on the
                <BlobLink
                  blob={
                    cachedDocuments.get(`${FileTypesValues.proposal}-latest`)
                      ?.blob
                  }
                  isLoading={
                    cachedDocuments.get(`${FileTypesValues.proposal}-latest`)
                      ?.isLoading
                  }
                  fileName='Proposal'
                  text={` Investment Proposal `}
                />
                you and your advisor reviewed, we're opening the following
                Personal Advice accounts:
              </TextBody>
              <StyledUL>
                {pendingAccounts.length > 0 &&
                  pendingAccounts.map((account: PendingAccount, i: number) => (
                    <DiscLI key={i}>
                      <b>
                        {account.accountType},{' '}
                        {(account.accountHolders || []).join(', ')}
                      </b>
                    </DiscLI>
                  ))}
              </StyledUL>
              <StyledTextBody tag='p' size='md'>
                If you have questions about the accounts listed, call your
                advisor.
              </StyledTextBody>
            </Space>
          </AccordionCard>
          <AccordionCard
            title='Ally Invest Account Information'
            footerComponent={AccordionFooterComponent(
              <>
                If you have trouble viewing, printing, or downloading the Ally
                Invest Account information, call us at
                <StyledLink
                  to='tel:+1-855-880-2559'
                  text='1-855-880-2559'
                  aria-label='Call us at 1-855-880-2559'
                />
              </>
            )}
          >
            <Space paddingBottom='20px'>
              <TextBody tag='p' size='md'>
                Download and review the following:
              </TextBody>
              <Box paddingLeft='20px'>
                <UL>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/resources/pdf/invest/aia-form-crs.pdf`}
                      text='Form CRS - Ally Invest Advisors'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/resources/pdf/invest/ais-form-crs.pdf`}
                      text='Form CRS - Ally Invest Securities'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/resources/pdf/invest/customer-agreement.pdf`}
                      text='Ally Invest Securities Customer Agreement'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/resources/pdf/invest/wealth-advisory-agreement.pdf`}
                      text='Ally Invest Personal Advice and Guided Advice Advisory Agreement'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/resources/pdf/invest/wrap-fee-pgrm-brochure.pdf`}
                      text='Ally Invest Advisors Firm Brochure'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/resources/pdf/invest/ira-disclosure.pdf`}
                      text='Traditional IRA Agreement'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/content/dam/pdf/invest/disclosures-and-agreements/roth-ira-disclosure.pdf`}
                      text='Roth IRA Agreement'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/content/dam/pdf/corporate/ally.privacy-notice.2014-01-01.v1.pdf`}
                      text='Ally Privacy Notice'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/resources/pdf/invest/california_opt_out_form.pdf`}
                      text='Ally Privacy Notices for California Residents'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/content/dam/pdf/invest/apex-consumer-privacy-notice.pdf`}
                      text='Apex Consumer Privacy Notice'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/content/dam/pdf/invest/disclosures-and-agreements/invest-online-agreement.pdf`}
                      text='Ally Invest Online Services Agreement'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                  <StyledLI>
                    <Link
                      to={`${investDocsRoot}/content/dam/pdf/invest/invest-business-continuity-plan.pdf`}
                      text='Ally Invest Business Continuity Plan'
                      target='_blank'
                    />{' '}
                    (PDF)
                  </StyledLI>
                </UL>
                <Box marginTop='12px'>
                  <StyledTextBody tag='p' size='md'>
                    By selecting <b>Agree and Continue</b>, you acknowledge
                    that:
                  </StyledTextBody>
                </Box>
                <StyledUL>
                  <DiscLI>
                    You’ve received and are able to view the Form CRS – Ally
                    Invest Securities, the Ally Invest Securities Customer
                    Agreement, the Traditional IRA Agreement, the Roth IRA
                    Agreement, the Privacy Notices, the Ally Invest Online
                    Services Agreement and the Ally Invest Business Continuity
                    Plan, and you understand that their terms will govern your
                    accounts once they’re opened
                  </DiscLI>
                  <DiscLI>
                    You’ll be an advisory client of Ally Invest Advisors, Inc.
                    (“AIA”) and you’ve received and are able to view the Form
                    CRS – Ally Invest Advisors, the Ally Invest Personal Advice
                    and Guided Advice Advisory Agreement and the Ally Invest
                    Advisors Firm Brochure and understand that their terms will
                    govern the advisory relationship between you and AIA
                  </DiscLI>
                  <DiscLI>
                    You’ve printed or downloaded copies of these documents for
                    your records
                  </DiscLI>
                  <DiscLI>
                    You agree in advance to arbitrate any disputes with Ally
                    Invest Securities (as outlined in the pre-dispute
                    arbitration clause – item 37 on page 19 of the Ally Invest
                    Securities Customer Agreement), and you waive the right to
                    seek judicial relief, unless waiving that right would
                    violate any applicable law, regulation or SRO rule
                  </DiscLI>
                </StyledUL>
              </Box>
            </Space>
          </AccordionCard>
        </ConsentAccordion>
      </Box>
    </Box>
  );
};

export default DocumentReviewAccordion;
