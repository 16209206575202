import { IAPIData, APIData } from './APIData'; // Don't import from index or you'll get issues with APIData methods being referenced as properties and circular references
import { DepositAccount } from './DepositAccount';

interface IDepositAccountList extends IAPIData {
  accounts: DepositAccount[];
}

class DepositAccountList extends APIData implements IDepositAccountList {
  accounts: DepositAccount[] = [];

  setData(accounts: DepositAccount[]) {
    this.accounts = accounts || [];
    this.setLoaded();
  }
}

export { DepositAccountList };
export type { IDepositAccountList };
