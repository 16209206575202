import styled from 'styled-components';
import { Space } from '@ally/metronome-ui';
import { muiMailClosed, muiPhoneOutline } from '@ally/metronome-icons/dist/cjs';
import { Text } from '../../atoms';
import { IUpcominAppointmentResource } from '../../../models';
import { IconRow } from '../../molecules';

const StyledDL = styled.dl`
  margin: 0;
  padding-top: 5px;
  padding-bottom: 15px;
`;

const StyledText = styled(Text)`
  display: flex;
  align-items: center;
`;

interface IProps {
  advisor: IUpcominAppointmentResource;
}

const AdvisorSummary: React.FC<IProps> = ({ advisor }) => {
  return (
    <>
      <Text tag='b' size='md'>
        <b>Your Advisor:</b> {' ' + advisor.name}
      </Text>
      <StyledDL>
        {!!advisor.email && (
          <StyledText tag='dd' size='sm'>
            <IconRow icon={muiMailClosed}>{advisor.email}</IconRow>
          </StyledText>
        )}
        {!!advisor.phone && (
          <StyledText tag='dd' size='sm'>
            <IconRow icon={muiPhoneOutline}>{advisor.phone}</IconRow>
          </StyledText>
        )}
      </StyledDL>
    </>
  );
};

export default AdvisorSummary;
