import { Label, Select } from '@ally/metronome-ui';
import styled from 'styled-components';

const StyledLabel = styled(Label)`
  text-transform: uppercase;
`;

interface IProps {
  label: string;
  placeholder?: string;
  error?: string;
  isPrivate?: boolean;
  ref?: React.Ref<HTMLSelectElement>;
  maxWidth?: string;
  // All below are included in props spread
  id: string;
  options: { label: string; value: string }[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  value: string;
}

const LabeledSelect: React.FC<IProps> = (props) => {
  return (
    <>
      <StyledLabel size='md' htmlFor={props.id}>
        {props.label}
      </StyledLabel>
      <Select
        allytmfn={props.id}
        data-private={props.isPrivate}
        id={props.id}
        options={props.options}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        error={props.error}
      />
    </>
  );
};

export default LabeledSelect;
