import { useEffect, createRef, RefObject } from 'react';
import styled from 'styled-components';
import { Button } from '@ally/metronome-ui';
import { useSkipToMainManager } from '../../../hooks';

const StyledButton = styled(Button)`
  ${({ theme: { colors } }): string => `
    background-color: ${colors.white};
  `}
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  right: 0;
`;

interface IProps {}

const SkipToMainButton: React.FC<IProps> = (props) => {
  const { skipToMainShown, skipToMain } = useSkipToMainManager();
  const skipToMainRef: RefObject<HTMLButtonElement> = createRef();

  useEffect(() => {
    if (skipToMainShown && skipToMainRef && skipToMainRef.current) {
      skipToMainRef.current.focus();
    }
  }, [skipToMainShown, skipToMainRef]);

  return skipToMainShown ? (
    <StyledButton
      text='Skip to main content'
      ref={skipToMainRef}
      onClick={skipToMain}
      variant='outline'
    ></StyledButton>
  ) : null;
};

export default SkipToMainButton;
