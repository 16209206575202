import styled from 'styled-components';
import { Box } from '@ally/metronome-ui';
import {
  AssetsIcon,
  NetWorthIcon,
  AllocationIcon,
  GoalTrackingIcon,
} from '../../icons';
import { InfoGraphic } from '../../molecules/index';
import { muiAllyTheme } from '../../../utils';

const BorderBox = styled(Box)`
  flex-wrap: none;
  > div {
    border-right: 1px solid ${muiAllyTheme.colors['grey-80']};
    :last-child {
      border: none;
    }
  }

  ${({ theme: { media } }): string => `
    ${media.down('lg')} {
      flex-wrap: wrap;
      > div {
          border: none;
          width: 50%;
          &:nth-child(-n + 2) {
            padding-bottom: 60px;
          }
        }
      }
    ${media.down('md')} {
      > div {
          width: 100%;
          padding-bottom: 60px;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      > div p {
        max-width: 300px;
      }
  `}
`;

interface IProps {}

const WealthFeatures: React.FC<IProps> = (props) => {
  return (
    <BorderBox
      display='flex'
      flexDirection={['column', 'column', 'row', 'row', 'row']}
    >
      <InfoGraphic icon={<NetWorthIcon />} title='Net Worth'>
        Get the full story behind your assets and liabilities.
      </InfoGraphic>
      <InfoGraphic icon={<AssetsIcon />} title='Assets Under Care'>
        Track performance against a personalized benchmark.
      </InfoGraphic>
      <InfoGraphic icon={<AllocationIcon />} title='Allocation'>
        Get a breakdown of your total investment exposure.
      </InfoGraphic>
      <InfoGraphic icon={<GoalTrackingIcon />} title='Goal Tracking'>
        Compare your current plan to the goals you're working towards.
      </InfoGraphic>
    </BorderBox>
  );
};

export default WealthFeatures;
