import { Config } from '../models';

export default {
  environment: 'cap',
  orionConfig: {
    baseUrl: 'https://resources.uat.advizr.com',
    host: 'webapi.uat.advizr.com',
  },
  allyDocsRoot: 'https://staging.int.ally.com/resources/pdf/corporate/',
  investDocsRoot: 'https://secure-qa1-ci.int.ally.com',
  investConfig: {
    baseUrl: 'https://api-qa.invest.int.ally.com',
    bankUrl: 'https://secure-qa3.ally.com',
    investUrl: 'https://secure-cap.ally.com',
    svcproviderUrl: 'https://svcprovider-qabi2.invest.int.ally.com',
  },
  wealthConfig: {
    baseUrl: 'http://localhost:3000',
    proxyUrl: 'http://10.17.20.30:8080',
  },
  logoutUrl: 'https://www.ally.com/logged-off',
  logRocketId: 'mvv2ld/wealthfe-qa',
  allyTmUrl:
    'https://assets.adobedtm.com/cd392cd56789/2a1d94722ac8/launch-73cf935890bc-development.min.js',
} as Config;
