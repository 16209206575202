import { Config } from '../models';

export const qa2Config = {
  environment: 'qa',
  orionConfig: {
    baseUrl: 'https://resources.uat.advizr.com',
    host: 'webapi.uat.advizr.com',
  },
  allyDocsRoot: 'https://staging.int.ally.com/resources/pdf/corporate/',
  investDocsRoot: 'https://www.ally.com',
  logoutUrl: 'https://www.ally.com/logged-off',
  logRocketId: 'mvv2ld/wealthfe-qa',
  allyTmUrl:
    'https://assets.adobedtm.com/cd392cd56789/2a1d94722ac8/launch-73cf935890bc-development.min.js',
  investConfig: {
    baseUrl: 'https://api-qa.invest.int.ally.com',
    bankUrl: 'https://secure-qa3.ally.com',
    investUrl: 'https://live-qabi2.invest.int.ally.com',
    svcproviderUrl: 'https://live-svcprovider-qabi2.invest.int.ally.com',
  },
  wealthConfig: {
    baseUrl: 'https://wealth-qabi2.invest.int.ally.com',
    proxyUrl: 'https://node-proxy-qa.invest.int.ally.com',
  },
  transmitConfig: {
    baseUrl: 'https://secure-qa3.ally.com/acs/customers/authenticate',
    apiKey: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
    useTransmit: true,
  },
} as Config;

export const qa1Config = {
  ...qa2Config,
  investConfig: {
    ...qa2Config.investConfig,
    bankUrl: 'https://secure-qa1.ally.com',
    investUrl: 'https://live-qabi1.invest.int.ally.com',
    svcproviderUrl: 'https://live-svcprovider-qabi1.invest.int.ally.com',
  },
  wealthConfig: {
    ...qa2Config.wealthConfig,
    baseUrl: 'https://wealth-qabi1.invest.int.ally.com',
  },
  transmitConfig: {
    ...qa2Config.transmitConfig,
    baseUrl: 'https://secure-qa3.ally.com/acs/customers/authenticate',
  },
} as Config;
