import { Action } from './actions';
import State, { initialState } from './store';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...initialState,
        session: action.payload,
      };

    case 'LOGOUT':
      return initialState;

    case 'BEGIN_LOGOUT':
      return {
        ...state,
        appCache: { ...state.appCache, processingLogout: action.payload },
      };

    case 'SET_ACCOUNTS':
      return {
        ...state,
        appCache: { ...state.appCache, accountList: action.payload },
      };

    case 'SET_DEPOSIT_ACCOUNTS':
      return {
        ...state,
        appCache: { ...state.appCache, depositAccountList: action.payload },
      };

    case 'SET_INVEST_ACCOUNTS':
      return {
        ...state,
        appCache: { ...state.appCache, investAccountList: action.payload },
      };

    case 'SET_PENDING_ACCOUNTS':
      return {
        ...state,
        appCache: { ...state.appCache, pendingAccounts: action.payload },
      };

    case 'SUPPRESS_NOTIFICATION':
      return {
        ...state,
        appCache: {
          ...state.appCache,
          suppressedNotifications: {
            ...state.appCache.suppressedNotifications,
            [action.payload]: true,
          },
        },
      };

    case 'SYNC_REQUEST':
      return {
        ...state,
        ...action.payload,
      };

    case 'SET_ORION_TOKEN':
      return {
        ...state,
        session: { ...state.session, orionToken: action.payload },
      };

    case 'SET_ORION_TOKEN_IS_LOADING':
      return {
        ...state,
        session: {
          ...state.session,
          orionToken: {
            ...state.session.orionToken,
            isLoading: action.payload,
          },
        },
      };

    case 'SET_IDP_TOKEN':
      const updatedState = {
        ...state,
        session: { ...state.session, IDPToken: action.payload },
      };
      return updatedState;

    case 'SET_IDP_TOKEN_IS_LOADING':
      return {
        ...state,
        session: {
          ...state.session,
          IDPToken: { ...state.session.IDPToken, isLoading: action.payload },
        },
      };

    case 'SET_GOALS_SUMMARY':
      return {
        ...state,
        appCache: { ...state.appCache, goalsSummary: action.payload },
      };

    case 'SET_WEALTH_OVERVIEW':
      return {
        ...state,
        appCache: { ...state.appCache, wealthOverview: action.payload },
      };

    case 'SET_NET_WORTH_DETAILS':
      return {
        ...state,
        appCache: { ...state.appCache, netWorthDetails: action.payload },
      };

    case 'SET_HOUSEHOLD_IDS':
      return {
        ...state,
        session: { ...state.session, householdIds: action.payload },
      };

    case 'SET_INSTITUTIONS':
      return {
        ...state,
        appCache: { ...state.appCache, institutions: action.payload },
      };

    case 'SET_RELATIONSHIPS':
      return {
        ...state,
        appCache: { ...state.appCache, relationshipList: action.payload },
      };

    case 'SET_NET_WORTH_PERFORMANCE':
      return {
        ...state,
        appCache: { ...state.appCache, netWorthPerformance: action.payload },
      };

    case 'SET_PLAID':
      return {
        ...state,
        appCache: { ...state.appCache, plaid: action.payload },
      };

    case 'SET_PROPOSALS':
      return {
        ...state,
        appCache: { ...state.appCache, proposalList: action.payload },
      };

    case 'SET_QPRS':
      return {
        ...state,
        appCache: { ...state.appCache, QPRList: action.payload },
      };

    case 'SET_UPCOMING_APPOINTMENTS':
      return {
        ...state,
        appCache: {
          ...state.appCache,
          upcomingAppointmentList: action.payload,
        },
      };

    case 'SET_IS_SECONDARY':
      return {
        ...state,
        appCache: {
          ...state.appCache,
          isSecondary: action.payload,
        },
      };

    case 'SET_ESIGN_STATUS':
      return {
        ...state,
        appCache: {
          ...state.appCache,
          esignStatus: action.payload,
        },
      };

    default:
      return assertNever(action);
  }
};

function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}

export { reducer };
