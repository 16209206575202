import { Button, TextBody, useModalContext } from '@ally/metronome-ui';
import { ButtonProps } from '@ally/metronome-ui/dist/cjs/Button';
import { Modal } from '../../molecules';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  ${({ theme: { colors } }): string => `
    color: ${colors['sky-3']};
  `}
`;

type IProps = {
  to: string;
  title?: string;
  modalBody?: React.FunctionComponentElement<any>;
} & Pick<ButtonProps, 'variant' | 'text'>;

const LeavingAllyModal: React.FC<IProps> = ({
  title = 'You’re leaving Ally Invest.',
  to,
  variant,
  text,
  modalBody,
}) => {
  const context = useModalContext();
  const { showModal, closeModal } = context;

  const handleRedirect = (url: string) => {
    closeModal();
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const defaultModalBody = (
    <TextBody tag='p' size='sm'>
      Selecting{' '}
      <TextBody tag='span' weight='bold' size='sm'>
        Continue
      </TextBody>{' '}
      will take you to a website that Ally does not own or control. We are not
      responsible for the products, services, or information you may find or
      provide there. Also, you should read and understand how that site’s
      privacy policy, level of security, and terms and conditions may impact
      you.
    </TextBody>
  );

  return (
    <Modal
      context={context}
      heading={title}
      content={modalBody ?? defaultModalBody}
      continue={() => handleRedirect(to)}
      cancel={closeModal}
      trigger={
        <StyledButton variant={variant} text={text} onClick={showModal} />
      }
      toggleCallback={(isOpen: boolean) => (isOpen ? showModal : closeModal)}
      width='md'
    />
  );
};

export default LeavingAllyModal;
