import { APIData } from './APIData';

class Esign extends APIData {
  status: string = '';
  version: string = '';

  setData({ status, version }: Esign) {
    this.status = status;
    this.version = version;
    this.setLoaded();
  }
}

export { Esign };
