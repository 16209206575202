import { createRef, forwardRef, Ref, useEffect } from 'react';
import styled from 'styled-components';
import { Box, TextBody, TextHeading } from '@ally/metronome-ui';
import { Drawer, LabeledBlobLink } from '../../molecules';
import {
  useWealthController,
  useAllyTm,
  useDocumentsController,
} from '../../../hooks';
import { Member } from '../../../models';
import { Loader, WidgetLabel } from '../../atoms';
import { WealthOverviewDetailsProfile } from '../';
import { FileTypesValues } from '../../../types/index';
import { muiAllyTheme } from '../../../utils';

const StyledDL = styled.dl`
  margin: 0;
  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

const InformationDL = styled(StyledDL)`
  dt:not(:first-child) {
    padding-top: 20px;
  }
`;

interface HeaderProps<T> {
  ref?: React.Ref<T>;
}
const Header: React.FC<HeaderProps<HTMLHeadingElement>> = forwardRef(
  (props, ref: Ref<HTMLHeadingElement>) => (
    <Box paddingBottom='20px'>
      <TextHeading size='xs' tag='h4' tabIndex={-1} ref={ref}>
        {props.children}
      </TextHeading>
    </Box>
  )
);

const TwoColumnBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 100%;
    flex-grow: 0;
  }
  > div:not(:first-child) {
    padding-top: 20px;
  }
  ${({ theme: { media } }): string => `
    ${media.up('xl')} {
      > div {
        flex: 50%;
      }
      > div:nth-child(n+2) {
        padding-top: 0;
      }
      > div:nth-child(n+3) {
        padding-top: 10px;
      }
      > div:nth-child(even) {
        padding-left: 10px;
      }
    }
  `}
`;

interface IProps {}

const WealthViewDetailsDrawer: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const focusRef = createRef<HTMLHeadingElement>();
  const { wealthOverview } = useWealthController();
  const {
    getLatestProposal,
    proposalList,
    QPRList,
    getQPRById,
    cachedDocuments,
  } = useDocumentsController();

  const onOpen = async () => {
    getLatestProposal();
    allyTmEvent('clickEvent', {
      tagName: 'button',
      allytmln: 'wealthManagementDetailsDrawer',
      href: '',
    });
  };

  useEffect(() => {
    getLatestProposal();
  }, []);

  useEffect(() => {
    if (QPRList.hasLoaded && QPRList.qprs.length) {
      getQPRById(QPRList.qprs[0].id);
    }
  }, [QPRList.hasLoaded]);

  return (
    <Drawer
      title='Personal Advice Details'
      focusRef={focusRef}
      onOpen={onOpen}
      contents={
        <Box display='flex' flexDirection={['column', 'column', 'row']}>
          <Box
            flexGrow={0}
            flexShrink={0}
            flexBasis={['100%', '100%', '180px', '290px']}
            paddingBottom={['20px', '20px', '0']}
            borderRight={[0, 0, `1px solid ${muiAllyTheme.colors['grey-80']}`]}
          >
            <Header ref={focusRef}>Your Information</Header>
            <InformationDL>
              <WidgetLabel tag='dt'>Account Holders</WidgetLabel>
              {!wealthOverview.isLoading ? (
                wealthOverview.members.map((member: Member) => {
                  return (
                    <TextBody
                      tag='dd'
                      size='md'
                      key={`${member.firstName}-${member.lastName}`}
                      data-private
                    >{`${member.firstName} ${member.lastName}`}</TextBody>
                  );
                })
              ) : (
                <Loader width='s' height='s' />
              )}
              {!!proposalList?.proposals.length && (
                <LabeledBlobLink
                  label='Latest Proposal'
                  allytmln='viewProposalLink'
                  file={cachedDocuments.get(
                    `${FileTypesValues.proposal}-latest`
                  )}
                  displayName='Investment Proposal'
                  date={proposalList.proposals[0].createdDate}
                />
              )}
              {!!QPRList.qprs.length && (
                <LabeledBlobLink
                  label='Latest Performance Report'
                  allytmln='viewQPRLink'
                  file={cachedDocuments.get(
                    `${FileTypesValues.qpr}-${QPRList.qprs[0].id}`
                  )}
                  displayName='Quarterly Performance Report'
                  quarterText={QPRList.qprs[0].blobText}
                />
              )}
            </InformationDL>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            flexGrow={1}
            paddingLeft={['0', '0', '30px']}
          >
            <Header>Your Portfolios</Header>
            <TwoColumnBox>
              {wealthOverview.isLoading && <WealthOverviewDetailsProfile />}
              {wealthOverview.wealthAccounts &&
                !wealthOverview.isLoading &&
                wealthOverview.wealthAccounts.map((account: any) => (
                  <WealthOverviewDetailsProfile
                    key={account.accountId}
                    account={account}
                  />
                ))}
            </TwoColumnBox>
          </Box>
        </Box>
      }
    ></Drawer>
  );
};

export default WealthViewDetailsDrawer;
