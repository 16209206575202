import { useContext } from 'react';
import { AxiosResponse } from 'axios';
import { storeContext } from '../../store/store';
import {
  Account,
  GoalsSummary,
  WealthOverview,
  NetWorthDetails,
  AccountList,
  NetWorthPerformance,
  InvestAccountList,
  DepositAccountList,
} from '../../models';
import { useAxios, useSession, useAccounts } from '../';

const version: String = 'v2';

const useWealthController = () => {
  const { state, dispatch } = useContext(storeContext);
  const { session } = useSession();
  const { getDepositAccounts } = useAccounts();
  const { investInstance } = useAxios('');

  const getAccounts = async () => {
    const accountList: AccountList = Object.assign(
      new AccountList(),
      state.appCache.accountList
    );
    if (!accountList.shouldReload()) {
      return accountList;
    }
    accountList.setIsLoading();
    dispatch({
      type: 'SET_ACCOUNTS',
      payload: accountList,
    });
    const { data }: AxiosResponse<Account[]> =
      ((await investInstance(
        'GET',
        `/wealth-service/${version}/users/wealth-accounts`,
        { headers: { allyHouseholdId: session.householdIds.allyHouseholdId } }
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    if (data) {
      accountList.setData(data);
    } else {
      accountList.setError();
    }
    dispatch({
      type: 'SET_ACCOUNTS',
      payload: accountList,
    });
  };

  // Gets all of the user's invest accounts, including their web ID for linking
  const getInvestAccounts = async () => {
    const investAccountList: InvestAccountList = Object.assign(
      new InvestAccountList(),
      state.appCache.investAccountList
    );
    if (!investAccountList.shouldReload()) {
      return investAccountList;
    }
    investAccountList.setIsLoading();
    dispatch({
      type: 'SET_INVEST_ACCOUNTS',
      payload: investAccountList,
    });

    try {
      const { data } = await investInstance<InvestAccountList>(
        'POST',
        `/dashboard/v2/api/Phoenix/getUserAccountDetails`,
        {
          data: {
            UserName: session.username,
            CustomerType: ['INVEST', 'WEALTH'],
          },
        }
      );
      if (data) {
        investAccountList.setData(data.Accounts);
      } else {
        investAccountList.setError();
      }
    } catch (err) {
      investAccountList.setError();
    }

    dispatch({
      type: 'SET_INVEST_ACCOUNTS',
      payload: investAccountList,
    });
  };

  const getNetWorthDetails = async (): Promise<NetWorthDetails> => {
    const netWorthDetails: NetWorthDetails = Object.assign(
      new NetWorthDetails(),
      state.appCache.netWorthDetails
    );
    if (!netWorthDetails.shouldReload()) {
      return netWorthDetails;
    }
    netWorthDetails.setIsLoading();
    dispatch({
      type: 'SET_NET_WORTH_DETAILS',
      payload: netWorthDetails,
    });
    const { data }: AxiosResponse<NetWorthDetails> =
      ((await investInstance(
        'GET',
        `/wealth-service/${'v2'}/users/${
          state.session.username
        }/networth-details`,
        {
          headers: { allyHouseholdId: session.householdIds.allyHouseholdId },
        }
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    if (data) {
      netWorthDetails.setData(data);
    } else {
      netWorthDetails.setError();
    }
    dispatch({
      type: 'SET_NET_WORTH_DETAILS',
      payload: netWorthDetails,
    });
    return netWorthDetails;
  };

  const getGoalsSummary = async (
    planType: 'current' | 'proposed' = 'current'
  ) => {
    const goalsSummary: GoalsSummary = Object.assign(
      new GoalsSummary(),
      state.appCache.goalsSummary
    );
    if (!goalsSummary.shouldReload()) {
      return goalsSummary;
    }
    goalsSummary.setIsLoading();
    dispatch({
      type: 'SET_GOALS_SUMMARY',
      payload: goalsSummary,
    });
    const { data }: AxiosResponse<GoalsSummary> =
      ((await investInstance(
        'GET',
        `/wealth-service/${version}/users/financial-plan/${planType}/goals-summary`,
        { headers: { allyHouseholdId: session.householdIds.allyHouseholdId } }
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    if (data) {
      data.goals = data.goals.sort((next, prev) => next.year - prev.year);
      goalsSummary.setData(data);
    } else {
      goalsSummary.setError();
    }
    dispatch({
      type: 'SET_GOALS_SUMMARY',
      payload: goalsSummary,
    });
  };

  const getWealthOverview = async (): Promise<WealthOverview> => {
    const wealthOverview: WealthOverview = Object.assign(
      new WealthOverview(),
      state.appCache.wealthOverview
    );
    if (!wealthOverview.shouldReload()) {
      return wealthOverview;
    }
    wealthOverview.setIsLoading();
    dispatch({
      type: 'SET_WEALTH_OVERVIEW',
      payload: wealthOverview,
    });
    const { data }: AxiosResponse<WealthOverview> =
      ((await investInstance(
        'GET',
        `/wealth-service/${'v1'}/invest-accounts/users/${
          state.session.username
        }/wealth`
      ).catch((err: Error) => {})) as AxiosResponse) || {};

    if (data) {
      wealthOverview.setData(data);
    } else {
      wealthOverview.setError();
    }
    dispatch({
      type: 'SET_WEALTH_OVERVIEW',
      payload: wealthOverview,
    });
    return wealthOverview;
  };

  const getNetWorthPerformance = async (): Promise<NetWorthPerformance> => {
    const netWorthPerformance: NetWorthPerformance = Object.assign(
      new NetWorthPerformance(),
      state.appCache.netWorthPerformance
    );
    if (!netWorthPerformance.shouldReload()) {
      return netWorthPerformance;
    }
    netWorthPerformance.setIsLoading();
    dispatch({
      type: 'SET_NET_WORTH_PERFORMANCE',
      payload: netWorthPerformance,
    });
    const { data }: AxiosResponse<NetWorthPerformance> =
      ((await investInstance(
        'GET',
        `/wealth-service/${'v2'}/users/${
          state.session.username
        }/networth-performance`,
        {
          headers: { allyHouseholdId: session.householdIds.allyHouseholdId },
        }
      ).catch((err: Error) => {})) as AxiosResponse) || {};
    if (data) {
      netWorthPerformance.setData(data);
    } else {
      netWorthPerformance.setError();
    }
    dispatch({
      type: 'SET_NET_WORTH_PERFORMANCE',
      payload: netWorthPerformance,
    });
    return netWorthPerformance;
  };

  return {
    hydrateClient: () => {
      Object.assign(
        new DepositAccountList(),
        state.appCache.depositAccountList
      ).shouldInitialize() && getDepositAccounts();
      Object.assign(
        new InvestAccountList(),
        state.appCache.investAccountList
      ).shouldInitialize() && getInvestAccounts();
    },
    getAccounts,
    userAccounts: state.appCache.accountList,
    getGoalsSummary,
    goalsSummary: state.appCache.goalsSummary,
    getWealthOverview,
    getNetWorthDetails,
    getNetWorthPerformance,
    netWorthDetails: state.appCache.netWorthDetails,
    wealthOverview: state.appCache.wealthOverview,
    netWorthPerformance: state.appCache.netWorthPerformance,
    setWealthOverviewIsLoading: (isLoading = true) => {
      const wealthOverview: WealthOverview = Object.assign(
        new WealthOverview(),
        state.appCache.wealthOverview
      );
      wealthOverview.setIsLoading(isLoading);
      dispatch({
        type: 'SET_WEALTH_OVERVIEW',
        payload: wealthOverview,
      });
    },
  };
};

export default useWealthController;
