import { muiAllyTheme } from '../utils';

export type CookieType =
  | 'jwt'
  | 'refreshToken'
  | 'allycapi-gwsid'
  | 'tksid'
  | 'pspObjKey';
export type RelationshipType = 'SELF_DIRECTED' | 'ROBO_PORTFOLIOS' | 'WEALTH';
export type RelationshipStatusType =
  | 'ACTIVE'
  | 'CLOSED'
  | 'RESTRICTED'
  | 'PROSPECT'
  | 'CLIENT_UNFUNDED'
  | 'CLIENT_FUNDED'
  | 'NOT_PROSPECT';
export type SessionStorageType = 'jwtToken' | 'WEALTH-TS';
export type LocalStorageType = 'logout' | 'sessionExpiry';
export type YesNoType = 'YES' | 'NO';
export type AccountType =
  | 'INVW'
  | 'INVS'
  | 'INVA'
  | 'SDA'
  | 'MMA'
  | 'DDA'
  | 'CMG'
  | 'CDA';
export const RouteValues = {
  home: '/',
  wealthOverview: '/wealth-overview',
  login: '/login',
  logout: '/logout',
  prospectAssessment: '/prospect-assessment',
  prospectAssessmentInterstitial: '/prospect-assessment-interstitial',
  netWorthDetails: '/net-worth-details',
  documentReview: '/document-review',
  authenticating: '/authenticating',
  accountDetails: '/account-details/:paramId',
} as const;
export type RouteValue = typeof RouteValues[keyof typeof RouteValues];

export const EquityAssetAccountClasses = [
  'Brokerage',
  'E529',
  'IRA',
  'Roth',
  'E401K',
  'E401A',
  'E403B',
  'E457',
  'SepIra',
  'EmployerPlan',
  'SimpleIra',
  'Roth401K',
  'TaxFree',
  'Annuity',
  'HSA',
  'Insurance',
  'EquityCompensation',
  'InheritedIra',
  'InheritedRoth',
] as const;
export type EquityAssetAccountClass = typeof EquityAssetAccountClasses[number];
export const ColorsList: { [key: string]: string } = {
  savingsAndInvestments: '#00A17D',
  retirementAccounts: muiAllyTheme.colors.toaster,
  unknown: '#3FC57A',
  creditCard: '#5166B2',
  mortgage: '#A83E7C',
  studentLoan: '#8753A0',
  installment: '#7E76F4',
  autoLoan: '#9A4890',
  lineOfCredit: '#6F5DAB',
  title: '#4A4A4A',
  assets: '#9D73D9',
  liabilities: '#C0C8D6',
  netWorth: '#690968',
  subLink: '#480348',
};
export type AnalyticsEventType =
  | 'pageview'
  | 'customError'
  | 'clickEvent'
  | 'conversionEvent';
export type AnalyticsConversionEventStep =
  | 'initiated'
  | 'intermediate'
  | 'completed';
export const AccountTypesName: { [key: string]: string } = {
  savings: 'Savings & Investments',
};
export type EventType = 'storage';
export type ProspectAssessmentStatusType =
  | ''
  | 'IN_PROGRESS'
  | 'SUBMIT_FAILED'
  | 'PART_SUBMITTED'
  | 'SUBMITTED';
export const FileTypesValues = {
  proposal: 'proposal',
  qpr: 'qpr',
} as const;
export type FileTypes = typeof FileTypesValues[keyof typeof FileTypesValues];
export type ValidationTypes = 'phoneNumber' | 'email' | 'yourEmail';
export type WorkType =
  | 'WEALTH_INTRO'
  | 'INITIAL_CONSULTATION'
  | 'GOAL_DATA_GATHERING'
  | 'GOAL_RESULTS'
  | 'INVESTMENT_PROPOSAL'
  | 'ACCOUNT_OPENING_OR_FUNDING'
  | 'SEMI_ANNUAL_REVIEW'
  | 'ANNUAL_REVIEW'
  | 'QUESTIONS_AND_ADVICE';
export const OrionEventType = 'orion-widget-load';
export type LogLevel = 'error' | 'warn' | 'info' | 'debug';
