import styled from 'styled-components';
import { Box, Icon } from '@ally/metronome-ui';
import { WealthFeatures } from '../index';
import { Card } from '../../molecules';
import { Link } from '../../atoms';

const StyledLink = styled(Link)`
  font-size: 16px;
`;

interface IProps {}

const FinancialPictureWidget: React.FC<IProps> = (props) => {
  return (
    <Card title='See your full financial picture'>
      <Box paddingTop='15px' paddingBottom='30px'>
        <WealthFeatures />
      </Box>
      <Box display='flex' justifyContent='center'>
        {/* 
        // TODO to be added back when video is available
        <StyledLink
          $internal
          to={'/'}
          content={
            <>
              <Box paddingRight='10px' display='flex' alignItems='center'>
                <MuiIcon muiIcon={muiPlaySquareFill} ariaHidden />
              </Box>
              Video: Wealth Overview Features
            </>
          }
        /> 
        */}
      </Box>
    </Card>
  );
};

export default FinancialPictureWidget;
