import styled from 'styled-components';
import { muiSuccessOutline } from '@ally/metronome-icons';
import { Box, TextBody, Button, Icon } from '@ally/metronome-ui';
import { muiAllyTheme } from '../../../utils';

const StyledIcon = styled(Icon)`
  vertical-align: middle;
  margin-left: 10px;
`;

interface IProps {
  shown?: boolean;
  title: string;
  submitCard?: Function;
  isSubmitted?: boolean;
  footerComponent?: React.ReactNode;
  submitButtonContent?: string;
  showHelpMessage?: boolean;
  disableButton?: boolean;
}

const ConsentAccordion: React.FC<IProps> = (
  props: React.PropsWithChildren<IProps>
) => {
  return (
    <Box
      backgroundColor='white'
      borderLeft={`1px solid ${muiAllyTheme.colors['grey-80']}`}
      borderRight={`1px solid ${muiAllyTheme.colors['grey-80']}`}
      borderBottom={`1px solid ${muiAllyTheme.colors['grey-80']}`}
    >
      <Box
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        height='45px'
        backgroundColor='light-gray'
        paddingLeft='10px'
        borderTop={`1px ${muiAllyTheme.colors['grey-80']} solid`}
        borderBottom={
          props.shown ? `5px solid ${muiAllyTheme.colors['grey-80']}` : ``
        }
      >
        {props.isSubmitted && (
          <StyledIcon icon={muiSuccessOutline} size='lg' fill='success' />
        )}
        <Box paddingLeft='10px'>
          <TextBody tag='span' size='md' weight='bold'>
            {props.title}
          </TextBody>
        </Box>
      </Box>
      {props.shown && (
        <Box padding='20px'>
          {props.children}

          <Button
            variant={props.disableButton ? 'disabled' : 'primary'}
            disabled={props.disableButton}
            text={props.submitButtonContent || 'Agree and Continue'}
            size='md'
            onClick={() => {
              if (props.submitCard) {
                props.submitCard();
              }
            }}
          />

          {props.footerComponent && (
            <Box paddingTop='20px'>{props.footerComponent}</Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ConsentAccordion;
