import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  TextBody,
  TextHeading,
  ButtonGroup,
  Box,
  Space,
  Button,
  Icon,
  Link as MetLink,
} from '@ally/metronome-ui';
import { muiLink, muiCalendar, muiDownload } from '@ally/metronome-icons';
import DefaultTemplate from '../../templates/DefaultTemplate';
import { Link } from '../../atoms';
import { WealthFeatures } from '../../organisms';
import { muiProfile } from '../../muiIcons';
import {
  useConfig,
  useSession,
  useWealthController,
  useAllyTm,
  useFormatter,
} from '../../../hooks';
import { WealthHeading } from '../../molecules';
import { Timeslot } from '../../../models';

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  margin-right: 18px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  align-items: baseline;
  padding-top: 30px;
  ${({ theme: { media } }): string => `
    ${media.down('md')} {
      span {
        margin: 0 auto;
      }
      span:first-of-type {
        width: 100%;
      }
      span:not(first-of-type) {
        margin-top: 12px;
      }
      a {
        width: 100%;
      }
    }
  `}
`;

const StyledLink = styled(Link)`
  min-width: 180px;
`;

const StyledTextBody = styled(TextBody)`
  white-space: break-spaces;
`;

interface IProps {
  location: { state: { selectedAppointment: Timeslot } };
}

const ProspectAssessmentIntersititialPage: React.FC<IProps> = (props) => {
  const { allyTmEvent } = useAllyTm();
  const { investConfig } = useConfig();
  const { session } = useSession();
  const { getNetWorthDetails, hydrateClient } = useWealthController();
  const { formatTimeslot } = useFormatter();

  const [loadingState, setLoadingState] = useState<
    'none' | 'loading' | 'loaded' | 'error'
  >('none');

  useEffect(() => {
    allyTmEvent('pageview', 'Wealth:Prospect:Lead Form:On Your Way');
  }, []);

  useEffect(() => {
    let attempts = 0;
    if (session.username && session.householdIds.allyHouseholdId) {
      const interval = setInterval(async () => {
        attempts++;
        const netWorthDetails = await getNetWorthDetails();
        if (
          Object.keys({
            ...netWorthDetails.assets,
            ...netWorthDetails.liabilities,
          }).length
        ) {
          clearInterval(interval);
          hydrateClient();
          setLoadingState('loaded');
        } else if (attempts > 9) {
          clearInterval(interval);
          setLoadingState('error');
        }
      }, 3000);
    }
  }, [session.username, session.householdIds.allyHouseholdId]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (loadingState === 'none') {
      e.preventDefault();
      setLoadingState('loading');
    }
  };

  return (
    <DefaultTemplate hideSubNav>
      <Box width={['100%', '100%', '100%', 2 / 3]} paddingBottom='30px'>
        <WealthHeading content="You're all set." />
        {props.location.state?.selectedAppointment && (
          <Box
            display='flex'
            flexDirection='column'
            paddingBottom='20px'
            marginBottom='20px'
            borderBottom='1px solid'
            borderColor='grey-80'
            alignItems='flex-start'
          >
            <Box display='flex' marginBottom='10px'>
              <StyledIcon icon={muiCalendar} size='lg' fill='plum' />
              <TextBody tag='p' size='sm' weight='bold'>
                {formatTimeslot(props.location.state?.selectedAppointment)}
              </TextBody>
            </Box>
            {/*
            Not doing add to calendar until phase 2
            <Box display='flex'>
              <StyledIcon icon={muiDownload} size='lg' fill='plum' />
              <Button
                allytmln='AddToYourCalendar'
                variant='link'
                //size='sm'
                //fontSize='1.0666rem'
                text='Add to your calendar'
              />
            </Box>
          */}
          </Box>
        )}
        <TextHeading tag='h2' size='sm'>
          What comes next
        </TextHeading>
        <Box paddingTop='20px' display='flex' alignItems='flex-start'>
          <StyledIcon icon={muiLink} size='lg' fill='plum' />
          <StyledTextBody tag='p' size='sm'>
            {' '}
            <Link
              allytmln='propsectAssessmentInterstitialRedirect'
              onClick={handleClick}
              to={loadingState === 'loaded' ? '/wealth-overview' : '#'}
              text='Visit your Wealth Overview '
              isLoading={loadingState === 'loading'}
              disabled={loadingState === 'loading'}
              size='sm'
            />
            to view and edit your meeting and link accounts to see your net
            worth and combined investment allocation.
          </StyledTextBody>
        </Box>
        <Box
          paddingTop='20px'
          display='flex'
          alignItems='center'
          paddingBottom='30px'
        >
          <StyledIcon icon={muiProfile} fill='plum' size='lg' />
          <TextBody tag='p' size='sm'>
            {' '}
            You’ll meet with a member of our team via Zoom to learn about Ally
            Invest Personal Advice.
          </TextBody>
        </Box>
        <TextHeading tag='h2' size='sm'>
          Get an expert view to go with your expert advice
        </TextHeading>
        <Box paddingTop='20px'>
          <TextBody tag='p' size='sm'>
            Look at your finances like an expert with your new Wealth Overview.
            It's your entire collection of investable assets all in one place.
            Even before talking to your advisor, you can link your accounts and
            start seeing the big picture like never before.
          </TextBody>
        </Box>
      </Box>
      <WealthFeatures />
      <Box paddingTop='30px'>
        <Space paddingY='10px'>
          <StyledButtonGroup>
            <StyledLink
              allytmln='propsectAssessmentInterstitialRedirect'
              onClick={handleClick}
              to={loadingState === 'loaded' ? '/wealth-overview' : '#'}
              text='Go to Wealth Overview'
              isLoading={loadingState === 'loading'}
              variant={loadingState !== 'loading' ? 'primary' : 'disabled'}
            />
            <StyledLink
              allytmln='propsectAssessmentInterstitialCancel'
              to={`${investConfig.bankUrl}/dashboard`}
              text='Back to Snapshot'
            />
          </StyledButtonGroup>
          <Box>
            {loadingState !== 'none' && loadingState !== 'loaded' && (
              <TextBody tag='p' size='sm'>
                {loadingState === 'error'
                  ? `Looks like this is taking longer than we thought. Head back to
              Snapshot. You'll be able to select Wealth Overview there once it's
              ready to go.`
                  : `Good financial health lasts a lifetime, but this won't. We just need a minute to fire up your Wealth Overview.`}
              </TextBody>
            )}
          </Box>
        </Space>
      </Box>
    </DefaultTemplate>
  );
};

export default ProspectAssessmentIntersititialPage;
