import { useState } from 'react';
import {
  Box,
  useDropdownContext,
  Accordion,
  AccordionItem,
} from '@ally/metronome-ui';
import { useURL } from '../../../hooks';
import styled from 'styled-components';
import { AccountListNav } from '..';
import { INavLinkDetail } from '../../../models';
import { muiAllyTheme } from '../../../utils';
import { Link } from '../../atoms';

const StyledNavLink = styled(Link)`
  color: ${muiAllyTheme.colors['white']};
  padding: 12px 26px;
  width: 100%;
  font-size: 1.067rem;
  border-bottom: 1px solid ${muiAllyTheme.colors['grey-80']};
`;

const StyledAccordionItem = styled(AccordionItem)`
  border-bottom: 1px solid ${muiAllyTheme.colors['grey-80']};
  [id^='accordion__heading-'] {
    border-bottom: 1px solid ${muiAllyTheme.colors['grey-80']};
    padding: 8px 10px;
    p {
      font-size: 1.067rem;
      color: ${muiAllyTheme.colors.white};
    }
    & > div > div > div > div {
      line-height: 2;
    }
    svg {
      width: 14px;
      height: 14px;
      path {
        fill: ${muiAllyTheme.colors.white};
      }
    }
  }
  [id^='accordion__panel-'] {
    background-color: ${muiAllyTheme.colors.white};
    padding-left: 0;
    li {
      height: auto;
      a {
        height: auto;
        padding: 4px 26px;
        font-size: 1.067rem;
        line-height: 1.5;
      }
    }
  }
`;

const StyledDiv = styled.div`
  background-color: rgb(72, 3, 72);
  font-size: 1.067rem;
  color: white;
  border-bottom: 1px solid rgb(149, 149, 149);
  padding: 4px 26px;
`;

const StyledLink = styled(Link)`
  height: auto;
  font-size: 1.067rem;
  line-height: 1.5;
  color: white;
`;

interface IProps {
  navLinkDetail: INavLinkDetail;
}

const SideNavLink: React.FC<IProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const context = useDropdownContext();
  const { pathname } = useURL();

  const toggleItem = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (props.navLinkDetail.subComponent || props.navLinkDetail.subLinks) {
      e.preventDefault();
    }
    setIsOpen(!isOpen);
  };

  return (
    <Box>
      {pathname !== props.navLinkDetail.link && (
        <Box>
          {!!props.navLinkDetail.subLinks && (
            <Accordion variant='CONTAINERLESS'>
              <StyledAccordionItem
                title={props.navLinkDetail.title}
                content={{
                  variant: 'CONTAINERLESS',
                  payload: props.navLinkDetail.subLinks.map((item) => (
                    <StyledDiv>
                      <StyledLink
                        text={item.title}
                        to={item.link}
                        transmitdestination={item.transmitdestination}
                      />
                    </StyledDiv>
                  )),
                }}
              />
            </Accordion>
          )}
          {!!props.navLinkDetail.subComponent && (
            <Accordion variant='NAVIGATION'>
              <StyledAccordionItem
                title={props.navLinkDetail.title}
                content={{
                  variant: 'CONTAINERLESS',
                  payload: (
                    <AccountListNav
                      closeMenu={context.closeMenu}
                      isSideNav={true}
                    />
                  ),
                }}
              />
            </Accordion>
          )}
          {!!props.navLinkDetail.link && (
            <StyledNavLink
              to={props.navLinkDetail.link}
              text={props.navLinkDetail.title}
              onClick={(e: React.MouseEvent<Element, MouseEvent>) =>
                toggleItem(e)
              }
              transmitdestination={props.navLinkDetail.transmitdestination}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default SideNavLink;
