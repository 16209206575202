import React from 'react';
import { useWealthController } from '../../../hooks';
import { Card, OrionWidget } from '../../molecules';
import { UpcomingGoals } from '../../organisms/index';

const GoalRoadmapWidget: React.FC<IProps> = (props) => {
  const { goalsSummary } = useWealthController();
  return (
    <>
      {goalsSummary.goals.length > 0 && (
        <Card title='Goal Roadmap' drawer={<UpcomingGoals />}>
          <OrionWidget type='retirement' widgetId='retirementChart' />
        </Card>
      )}
    </>
  );
};

interface IProps {}

export default GoalRoadmapWidget;
