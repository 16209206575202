import { useContext } from 'react';
import { viewportContext } from '../../contexts';
// const DesktopBreakpoint = 1330;
// const XLargeBreakpoint = 1024;
const LargeBreakpoint = 768;
const MediumBreakpoint = 640;
const SmallBreakpoint = 320;

const useViewport = () => {
  const { width, height } = useContext(viewportContext);

  const isLessThanLarge: boolean = width < LargeBreakpoint;
  const isMobile: boolean = width < MediumBreakpoint;

  return { width, height, isLessThanLarge, isMobile };
};

export default useViewport;
