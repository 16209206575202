import { useContext, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useAxios, useCustomersService, useSession, useACMService } from '../';
import { ProspectAssessment } from '../../models';
import { useDebounce } from '@ally/metronome-ui';
import { storeContext } from '../../store/store';

const useAssessmentManager = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inputValues, setInputValues] = useState<ProspectAssessment>(
    new ProspectAssessment()
  );
  const [isError, setIsError] = useState<boolean>(false);
  const { investInstance } = useAxios(
    `/customers-service/v1/customers/prospects/wealth/assessment`
  );
  const { getACMStatus } = useCustomersService();
  const { updateProspectSession } = useSession();
  const { getACMCustomer } = useACMService();
  const { refreshSessionTimer } = useSession();
  const { handleValueChange } = useDebounce({
    debounceTimeout: 5000,
    onDebounced: () => {
      refreshSessionTimer();
    },
  });
  const { dispatch } = useContext(storeContext);

  const handleInputChange = (value: ProspectAssessment) => {
    handleValueChange(value);
    setInputValues(value);
  };

  const newAssessment = async () => {
    setIsError(false);
    const { data }: AxiosResponse<ProspectAssessment> =
      ((await investInstance('POST', `/`, {
        data: JSON.stringify({}),
      }).catch((err: Error) => setIsError(true))) as AxiosResponse) || {};
    if (data?.assessmentId && data.assessmentId !== 0) {
      const { email, phone } = await getACMCustomer();
      setInputValues({
        ...inputValues,
        assessmentId: data.assessmentId,
        assessmentStatus: data.assessmentStatus,
        contactId: data.contactId,
        phoneNumber: phone,
        email,
      });
    }
    setIsLoading(false);
  };

  const getAssessment = async (createAssessment?: boolean) => {
    setIsError(false);
    const { data }: AxiosResponse<ProspectAssessment> =
      ((await investInstance('GET', '', {
        data: {},
      }).catch((err: Error) => {
        setIsError(true);
      })) as AxiosResponse) || {};
    if (!data) {
      dispatch({
        type: 'SET_IS_SECONDARY',
        payload: true,
      });

      if (createAssessment) {
        newAssessment();
      }
      return false;
    }
    if (!data.email || !data.phoneNumber) {
      const { email, phone } = await getACMCustomer();
      setInputValues({
        ...data,
        phoneNumber: data.phoneNumber || phone,
        email: data.email || email,
      });
    } else {
      setInputValues(data);
    }
    handleValueChange(data);
    setIsLoading(false);
    return true;
  };

  const updateUserSession = async () => {
    await getACMStatus();
    await updateProspectSession();
  };

  const submitAssessment = async (
    submit: boolean,
    saveEsign: boolean
  ): Promise<ProspectAssessment | null> => {
    setIsError(false);

    const { data }: AxiosResponse<any> =
      ((await investInstance(
        'PUT',
        `/${inputValues.assessmentId}?submitAssessment=${submit}&saveEsign=${saveEsign}`,
        { data: JSON.stringify(inputValues) }
      ).catch((err) => {
        setIsError(true);
      })) as AxiosResponse) || {};
    if (data) {
      return data;
    }
    setIsError(true);
    return null;
  };

  return {
    submitAssessment,
    getAssessment,
    isLoading,
    inputValues,
    setInputValues: handleInputChange,
    isError,
    updateUserSession,
  };
};

export default useAssessmentManager;
